import { slugify } from "@/lib/utils";
import { Entity } from "@/types/db";
import { EmailIdentity } from "@/types/emails";
import {
  ListCustomFieldDefinition,
  ListCustomFieldValue,
  ListDetails,
  ListEntryComponentSpec,
  ListEntryCustomFieldSpec,
  ListEntryData,
  ListEntryDetails,
  ListEntryEntitySpec,
  ListEntrySnapshot,
  ListEntrySnapshotCustomField,
  ListEntrySpec,
  ListOverview,
  ListType,
  ListUser,
  ListUserRole,
  ListView,
  ListViewDisplayMode,
  ListViewFieldSelection,
} from "@/types/lists.typebox";
import { ListEntry } from "@prisma/client";
import { Static } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

export type ListOverview = Static<typeof ListOverview>;
export type ListDetails = Static<typeof ListDetails>;
export type ListCustomFieldDefinition = Static<typeof ListCustomFieldDefinition>;
export type ListCustomFieldValue = Static<typeof ListCustomFieldValue>;
export type ListUser = Static<typeof ListUser>;
export type ListUserRole = Static<typeof ListUserRole>;
export type ListEntrySpec = Static<typeof ListEntrySpec>;
export type ListEntryCustomFieldSpec = Static<typeof ListEntryCustomFieldSpec>;
export type ListEntryComponentSpec = Static<typeof ListEntryComponentSpec>;
export type ListEntryData = Static<typeof ListEntryData>;
export type ListEntryDetails = Static<typeof ListEntryDetails>;
export type ListEntrySnapshot = Static<typeof ListEntrySnapshot>;
export type ListEntrySnapshotCustomField = Static<typeof ListEntrySnapshotCustomField>;
export type ListViewDisplayMode = Static<typeof ListViewDisplayMode>;
export type ListViewFieldSelection = Static<typeof ListViewFieldSelection>;
export type ListView = Static<typeof ListView>;
export type ListType = Static<typeof ListType>;

export function isListDetails(u: unknown): u is ListDetails {
  return Value.Check(ListDetails, u);
}

export function isListEntryEntitySpec(
  u: ListEntry,
): u is ListEntry & Static<typeof ListEntryEntitySpec> {
  return Value.Check(ListEntryEntitySpec, u);
}

export function isListEntryCustomFieldSpec(
  u: unknown,
): u is Static<typeof ListEntryCustomFieldSpec> {
  return Value.Check(ListEntryCustomFieldSpec, u);
}

export type ListEntryResolution = {
  spec: ListEntrySpec;
  identity?: EmailIdentity;
  entity?: Entity;
  order?: number;
};

export const ListRoutes = {
  lists: (options?: { prefix?: string }) => {
    const { prefix } = options || {};
    let result = `/lists`;
    if (prefix) {
      result = `${prefix}${result}`;
    }
    return result;
  },

  list: (list: ListOverview | ListDetails, options?: { prefix?: string }) => {
    const { prefix } = options || {};
    let result = `/lists/${list.id}`;
    if (prefix) {
      result = `${prefix}${result}`;
    }
    return result;
  },

  view: (view: ListView, options?: { listName?: string; prefix?: string }) => {
    const { listName, prefix } = options || {};
    let result = `/list/${view.code}`;
    if (prefix) {
      result = `${prefix}${result}`;
    }
    if (listName) {
      result = `${result}/${slugify(listName)}`;
    }
    return result;
  },
} as const;

export type ListSyncTrigger =
  | "createList"
  | "updateList"
  | "deleteList"
  | "createListEntries"
  | "updateListEntries"
  | "deleteListEntries";

export type ListProfilesSyncRequest = {
  listId: string;
  userId: string;
  trigger: ListSyncTrigger;
};

export enum ListProfilesSyncStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
}

export const DEFAULT_LIST_ENTRY_GAP = 1000;

export class ListMaxSizeReachedError extends Error {
  constructor(
    readonly options: {
      listId: string;
      currentSize: number;
      newSize: number;
      maxSize: number;
    },
  ) {
    super(
      `Cannot add ${options.newSize - options.currentSize} entries to list ${
        options.listId
      } because the max size of ${options.maxSize} has been reached.`,
    );
    this.options = options;
    this.name = "ListMaxSizeReachedError";
  }
}

export const canAddToList = (list: { type?: ListType }) => {
  return list.type !== "linkedin";
};

export const canDeleteList = (list: { type?: ListType }) => {
  return list.type !== "linkedin";
};
