export const DetectorType = {
  GPT4oMini: "gpt-4o-mini",
  GPT4o: "gpt-4o",
  Regex: "regex",
  JSEval: "js-eval",
  None: "none",
} as const;
export type DetectorType = (typeof DetectorType)[keyof typeof DetectorType];

export const DetectorInput = {
  Profile: "profile",
  Highlights: "highlights",
  Filters: "filters",
} as const;

export type DetectorInput = (typeof DetectorInput)[keyof typeof DetectorInput];

export type ProfileProblemCore = {
  problemId?: number;
  title: string;
  description: string;
  detectorType: DetectorType | null;
  detectorInput: DetectorInput | null;
  detectorPrompt: string | null;
};

export type ProfileFeedbackFilter = {
  feedbackCategoryIds?: string[];
  problemId?: number;
};

export type DetectorOutput = {
  problemId?: number;
  output?: boolean;
  reason?: string;
  error?: string;
};

export type FullDetectorOutput = {
  entityId: string;
  snapshotId: string;
  name?: string;
  output: DetectorOutput;
};
