import { middleTruncate } from "@/lib/utils";
import { Static, Type } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

const OptionalString = Type.Optional(Type.String());
const OptionalBoolean = Type.Optional(Type.Boolean());

// https://datatracker.ietf.org/doc/html/rfc6265
const SetCookieAttributes = Type.Object({
  domain: OptionalString,
  path: OptionalString,
  expires: OptionalString,
  maxAge: OptionalString,
  secure: OptionalBoolean,
  httpOnly: OptionalBoolean,
  sameSite: Type.Optional(
    Type.Union([Type.Literal("lax"), Type.Literal("strict"), Type.Literal("none")]),
  ),
});

const BrowserCookie = Type.Object({
  domain: Type.String(),
  name: Type.String(),
  value: Type.String(),
  meta: Type.Optional(SetCookieAttributes),
});

const LinkedInAuthCookie = Type.Object({
  domain: Type.Union([Type.Literal("www.linkedin.com"), Type.Literal(".www.linkedin.com")]),
  name: Type.Literal("li_at"),
  value: Type.String(),
  meta: Type.Optional(
    Type.Intersect([
      Type.Object({ ua: OptionalString, premium: OptionalBoolean }),
      SetCookieAttributes,
    ]),
  ),
});

const Cookie = Type.Union([BrowserCookie, LinkedInAuthCookie]);

export type Cookie = Static<typeof Cookie>;
export type BrowserCookie = Static<typeof BrowserCookie>;
export type LinkedInAuthCookie = Static<typeof LinkedInAuthCookie>;
export type CookieList = Cookie[];
export type SetCookieAttributes = Static<typeof SetCookieAttributes>;

// https://developer.chrome.com/docs/extensions/reference/api/cookies#method-set
const ChromeCookieUpdate = Type.Object({
  url: Type.String(),
  name: OptionalString,
  value: OptionalString,
  domain: OptionalString,
  expirationDate: Type.Optional(Type.Number()),
  httpOnly: OptionalBoolean,
  secure: OptionalBoolean,
  path: OptionalString,
});

export type ChromeCookieUpdate = Static<typeof ChromeCookieUpdate>;

export interface CookieMonster {
  populateCookieList(urls: string[], list: CookieList): Promise<void>;
  onScrapingError(url: string, cookieList: CookieList, error: string): Promise<void>;
}

export function isCookie(u: unknown): u is Cookie {
  return Value.Check(Cookie, u);
}

export function isCookieList(u: unknown): u is CookieList {
  return Array.isArray(u) && u.every(isCookie);
}

export function isLinkedInAuthCookie(u: unknown): u is LinkedInAuthCookie {
  return Value.Check(LinkedInAuthCookie, u);
}

export function parseCookie({ cookie, domain }: { cookie: string; domain: string }): Cookie {
  const [name, value] = cookie.split("=");
  return {
    domain,
    name,
    value,
  };
}

export function parseLinkedInAuthCookie({ cookie }: { cookie: string }): LinkedInAuthCookie {
  const [name, value] = cookie.split("=");
  if (name !== "li_at") {
    throw new Error(`Invalid LinkedIn auth cookie: ${cookie}`);
  }
  return {
    domain: "www.linkedin.com",
    name,
    value,
  };
}

export function formatCookies(cookies: readonly Cookie[]): string {
  return cookies.map(({ name, value }) => `${name}=${value}`).join("; ");
}

export function getLogStringForCookies(cookies: readonly Cookie[]): string {
  return cookies.map((c) => middleTruncate(`${c.name}=${c.value}`, 20)).join(", ");
}

export function findLinkedInAuthCookie(cookies: readonly Cookie[]): LinkedInAuthCookie | null {
  return cookies.find(isLinkedInAuthCookie) ?? null;
}
