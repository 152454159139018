import Spinner from "@/components/ui/Spinner";
import { useDevTools } from "@/hooks/useUIStore";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

const LOADING_TIMEOUT = 15000;
const DELAY_SHOWING_LOADER = 250;
const DELAY_SHOWING_TIME_INFO = 1500;

export default function RouteTransition({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const initDone = useRef(false);
  const lastChangeId = useRef("");
  const lastChangeInitiated = useRef(0);
  const [currentLoadingTimeMs, setCurrentLoadingTimeMs] = useState(0);
  const [currentlyLoading, setCurrentlyLoading] = useState(false);
  const isDev = useDevTools();

  useEffect(() => {
    setCurrentLoadingTimeMs(0);
    if (!currentlyLoading) {
      lastChangeInitiated.current = 0;
      lastChangeId.current = "";
    }
    if (currentlyLoading) {
      lastChangeInitiated.current = Date.now();
      const currentRouteChangeId = crypto.randomUUID();
      lastChangeId.current = currentRouteChangeId;
      const timeout = setTimeout(() => {
        if (lastChangeId.current === currentRouteChangeId) {
          setCurrentlyLoading(false);
        }
      }, LOADING_TIMEOUT);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [currentlyLoading]);

  useEffect(() => {
    if (initDone.current) {
      return;
    }
    const handleRouteChangeStart = () => {
      setCurrentlyLoading(true);
    };
    const handleRouteChangeComplete = () => {
      setCurrentlyLoading(false);
      document.querySelector("#container")?.scrollTo(0, 0);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    const interval = setInterval(() => {
      if (lastChangeInitiated.current) {
        setCurrentLoadingTimeMs(Date.now() - lastChangeInitiated.current);
      }
    }, 100);
    initDone.current = true;
    return () => {
      clearInterval(interval);
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, []);

  return (
    <div className="w-full h-full">
      {currentlyLoading && currentLoadingTimeMs > DELAY_SHOWING_LOADER && (
        <div
          className={twMerge(
            "z-50 fixed top-0 left-0 right-0 h-full w-full bg-black opacity-30",
            "flex flex-col justify-center items-center content-center text-white",
          )}
        >
          <div>
            <Spinner size={72} />
          </div>
          {isDev && currentLoadingTimeMs > DELAY_SHOWING_TIME_INFO && (
            <div className="text-sm">
              Loading time {Math.floor(currentLoadingTimeMs / 100) / 10} s
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
}
