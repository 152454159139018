import { cleanBaseUrl } from "@/lib/utils";

interface SocialPlatform {
  service: string;
  regex: RegExp;
  companyRegex?: RegExp;
  parseUsername: (match: RegExpExecArray) => string;
  profileUrl?: (match: RegExpExecArray) => string;
  companyProfileUrl?: (match: RegExpExecArray) => string;
}

/*
  The following section is adapted from the social-profile-url-parser library, available at
  https://github.com/Salesflare/social-profile-url-parser, into this project.

  NOTE: when adding socialPlatforms to this list, please create the company profile and update
        the regex to filter out non profile pages from 'Other Profiles'
*/
const socialPlatforms: SocialPlatform[] = [
  {
    service: "aboutme",
    regex:
      /https?:\/\/(www\.)?about\.me\/(?!emailsignature|legal|side-hustlers|press|features|pricing)([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "angellist",
    regex: /https?:\/\/(www\.)?angel\.co\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "behance",
    regex: /https?:\/\/(www\.)?behance\.(com|net)\/([^ "/\n]+)/i,
    companyRegex: /(?!)/, // match nothing, behance should only work for person type profiles
    parseUsername: (match) => match[3],
  },
  {
    service: "crunchbase",
    regex: /https?:\/\/(www\.)?crunchbase\.com\/(organization|person)\/([^ "/\n]+)/i,
    parseUsername: (match) => match[3],
  },
  {
    service: "dribbble",
    regex: /https?:\/\/(www\.)?dribbble\.com\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "facebook",
    regex:
      /(https:\/\/(www\.)?facebook\.com\/(?!story\.php|Meta[A-Z]{2}|lite|login\.php|profile\.php|reg|marketplace|places|allactivity|votinginformationcenter)([^ "/\n]+))/i,
    parseUsername: (match) => match[3] || "",
  },
  {
    service: "github",
    regex: /https?:\/\/(www\.)?github\.com\/(?!features|sponsors)([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "instagram",
    regex: /https?:\/\/(www\.)?instagram\.com\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "linkedin",
    regex:
      /https?:\/\/([A-Za-z_]{0,3}\.)?linkedin\.com\/((in|pub|company|edu|school)\/)([^ "/\n]+)/i,
    companyRegex:
      /https?:\/\/([A-Za-z_]{0,3}\.)?linkedin\.com\/((company|edu|school)\/)([^ "/\n]+)/i,
    parseUsername: (match) => match[4],
    profileUrl: (match) => `https://linkedin.com/in/${match[4]}`,
    companyProfileUrl: (match) => `https://linkedin.com/company/${match[4]}`,
  },
  {
    service: "medium",
    regex: /https?:\/\/(www\.)?medium\.com\/@?([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "pinterest",
    regex: /https?:\/\/([A-Za-z_]{0,3}\.)?pinterest\.[a-zA-Z.]+\/([^ +/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "pitchbook",
    regex: /https?:\/\/(www\.)?pitchbook\.com\/profiles\/investor\/([^ "/\n]+)/i,
    companyRegex: /(?!)/, // match nothing, pitchbook should only work for person type profiles
    parseUsername: (match) => "",
  },
  {
    service: "quora",
    regex: /https?:\/\/(www\.)?quora\.com\/profile\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "reddit",
    regex: /https?:\/\/(www\.)?reddit\.com\/user\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "twitter",
    regex:
      /https?:\/\/((www|mobile)\.)?(twitter|x)\.com\/(?!i\/status\/)([^/]+)(?:\/status\/\d+)?/i,
    parseUsername: (match) => match[4],
    profileUrl: (match) => `https://x.com/${match[4]}`,
  },
  {
    service: "vk",
    regex: /https?:\/\/(www\.)?vk\.com\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "youtube",
    regex: /https?:\/\/([A-Za-z_]{0,3}\.)?youtube\.com\/(user\/|channel\/|c\/)?([^ "/\n]+)/i,
    parseUsername: (match) => match[3],
  },
];

export interface SocialInfo {
  service: string;
  username: string;
  url: string;
  profileUrl?: string;
  companyProfileUrl?: string;
}

export function isValidProfileUrl(url: string): boolean {
  const cleanedUrl = cleanBaseUrl(url);
  return socialPlatforms.some(({ regex }) => {
    const match = regex.exec(cleanedUrl);
    return match && match[0] === cleanedUrl;
  });
}

export function extractSocialInfo(url: string, type = "person"): SocialInfo | null {
  for (const platform of socialPlatforms) {
    const match =
      type === "company" && platform.companyRegex ?
        platform.companyRegex.exec(url)
      : platform.regex.exec(url);
    if (match) {
      return {
        service: platform.service,
        username: platform.parseUsername(match).split("?")[0],
        url: match.input,
        profileUrl: platform.profileUrl?.(match).split("?")[0],
        companyProfileUrl: platform.companyProfileUrl?.(match).split("?")[0],
      };
    }
  }
  return null;
}
