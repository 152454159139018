import EntityCard, { makeEntityCardFromMutualConnection } from "@/components/cards/EntityCard";
import { MutualConnectionsData } from "@/stores/entityStore";
import { usePostHog } from "posthog-js/react";

export default function ConnectionCard({
  connection,
}: {
  connection: MutualConnectionsData;
}): React.JSX.Element | null {
  const entityForCard = makeEntityCardFromMutualConnection(connection);
  const posthog = usePostHog();

  if (entityForCard.name && entityForCard.url) {
    return (
      <>
        <div className="h-[80px] flex flex-col justify-center">
          <EntityCard
            entity={entityForCard}
            contextMenu={false}
            border={false}
            entityIconClassName="h-[60px] w-[60px] rounded"
            entityDetailsClassName="mt-0"
            onClick={() => {
              posthog?.capture("show-mutual-connection", { url: entityForCard.url });
            }}
          />
        </div>
      </>
    );
  } else {
    return null;
  }
}
