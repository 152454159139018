interface Props {
  label: string;
  children: React.ReactNode;
}

export default function WithLabel(props: Props) {
  const { label, children } = props;
  return (
    <div className="flex flex-col gap-1">
      <div className="font-semibold mb-1">{label}</div>
      {children}
    </div>
  );
}
