import API from "@/client/api";
import eventTracker from "@/client/eventTracker";
import extensionScraper from "@/client/extensionScraper";
import { DomainStatus } from "@/components/gmail/types";
import { isFreeDomain } from "@/lib/freemail";
import { loggerWithPrefix } from "@/lib/logger";
import { parseEmailDomain, prettyError, shouldSkipEmail } from "@/lib/utils";
import { uiStore } from "@/stores/uiStore";
import {
  compareSources,
  EmailContact,
  EmailEntryPoint,
  EmailIdentity,
  EmailResolutionStatus,
  EntityWithAttributes,
  isEmailContact,
  isGmailContact,
  needsPolling,
  ResolvedEmailIdentity,
  UserWithMeta,
} from "@/types";
import { chunk } from "lodash";
import { atom, computed, map } from "nanostores";

const logger = loggerWithPrefix("[emailStore]");

/**
 * See which contact has a "better" source, meaning more information is available.
 */
function compareContactSources(a: EmailContact, b: EmailContact): number {
  if (isGmailContact(a) && isGmailContact(b)) {
    return compareSources(a.source, b.source);
  }
  return 0;
}

type DomainResolution = { status: DomainStatus } & { data?: EntityWithAttributes };

type TrackedEmailIdentity = EmailIdentity & {
  isInvalid?: boolean;
  polledAt?: Date;
  erroredAt?: Date;
};

type EmailAddress = string; // Alias for better readability

const BATCH_SIZE = 20;
const POLL_TIMEOUT = 1000 * 60 * 10; // 10 minutes
const ERROR_TIMEOUT = 1000 * 60 * 30; // 30 minutes

export class EmailStore {
  emailResolutions = map<Record<EmailAddress, TrackedEmailIdentity>>({});
  domainResolutions = map<Record<EmailAddress, DomainResolution>>({});
  hiddenEmails = atom<EmailAddress[]>([]);
  hiddenDomains = atom<string[]>([]);
  user = computed(uiStore.user, (user) => user);

  onUserChange(user: UserWithMeta | null) {
    const meta = user?.meta ?? {};
    this.hiddenDomains.set(meta.hidden_domains || []);
    this.hiddenEmails.set(meta.hidden_emails || []);
  }

  needsPolling(identity: TrackedEmailIdentity): boolean {
    const { status, contact, polledAt, erroredAt } = identity;
    if (isGmailContact(contact) && contact.source === "inbox") {
      return false;
    }
    if (!needsPolling(identity)) {
      return false;
    }
    const now = new Date();
    switch (status) {
      case EmailResolutionStatus.CANDIDATE:
        // Check if the email is in progress and needs re-polling
        if (
          identity.inProgress &&
          (!polledAt || now.getTime() - polledAt.getTime() > POLL_TIMEOUT)
        ) {
          if (polledAt) {
            logger.debug("Re-polling for timedout email:", contact.address, { polledAt });
          }
          return true;
        }
        return false;
      case EmailResolutionStatus.ERROR:
        // If the email has errored, we need to poll it
        // unless it was recently errored
        if (!erroredAt) {
          return true;
        }
        if (now.getTime() - erroredAt.getTime() > ERROR_TIMEOUT) {
          logger.debug("Re-polling for errored email:", contact.address, { erroredAt });
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  resolveLocally(contact: EmailContact): boolean {
    if (!isEmailContact(contact)) {
      logger.debug("Skipping invalid email:", contact);
      return true;
    }
    const localIdentity = this.emailResolutions.get()[contact.address];
    if (localIdentity) {
      if (localIdentity.isInvalid) {
        logger.debug("Stored identity is invalid, we need to re-request:", contact.address, {
          localIdentity,
        });
        return false;
      }
      if (compareContactSources(localIdentity.contact, contact) > 0) {
        logger.debug("Saving updated properties for contact:", contact.address, {
          localContact: localIdentity.contact,
          newContact: contact,
        });
        localIdentity.contact = {
          ...localIdentity.contact,
          ...contact,
        };
      }
      return true;
    }
    if (shouldSkipEmail(contact.address)) {
      this.emailResolutions.setKey(contact.address, {
        contact,
        status: EmailResolutionStatus.SKIPPED,
      });
      return true;
    }
    return false;
  }

  pushEmails = async (contacts: EmailContact[], entryPoint: EmailEntryPoint): Promise<void> => {
    const contactsToResolve = contacts.reduce<EmailContact[]>((acc, contact) => {
      if (this.resolveLocally(contact)) {
        return acc;
      }
      this.emailResolutions.setKey(contact.address, {
        contact,
        status: EmailResolutionStatus.REQUESTED,
      });
      return [...acc, contact];
    }, []);

    if (contactsToResolve.length === 0) {
      return;
    }

    for (const batch of chunk(contactsToResolve, BATCH_SIZE)) {
      await this.resolveEmails({ contacts: batch, entryPoint });
    }
  };

  pollEmails = async (entryPoint: EmailEntryPoint) => {
    if (!this.user) {
      logger.debug("No user, skipping poll");
      return;
    }

    const batch: EmailContact[] = [];
    const identities = Object.values(this.emailResolutions.get());
    const now = new Date();
    for (const identity of identities) {
      if (batch.length >= BATCH_SIZE) {
        break;
      }
      if (this.needsPolling(identity)) {
        identity.polledAt = now;
        batch.push(identity.contact);
      } else {
        if (identity.polledAt || identity.erroredAt) {
          logger.debug("Skipping polling for email:", JSON.stringify(identity));
        }
      }
    }
    if (batch.length > 0) {
      logger.debug(`Polling for ${batch.length} emails`);
      await this.resolveEmails({ contacts: batch, entryPoint });
    } else {
      logger.debug("No emails to poll");
    }
  };

  /**
   * Ensure that the given contact has the best source available.
   */
  private syncContactSource = (contact: EmailContact) => {
    const existingIdentity = this.emailResolutions.get()[contact.address];
    if (!existingIdentity) {
      return contact;
    }
    const { contact: existingContact } = existingIdentity;
    if (!isGmailContact(existingContact)) {
      return contact;
    }
    if (compareContactSources(contact, existingContact) <= 0) {
      return contact;
    }
    return {
      ...contact,
      source: existingContact.source,
    };
  };

  private resolveEmails = async ({
    contacts,
    entryPoint,
  }: {
    contacts: EmailContact[];
    entryPoint: EmailEntryPoint;
  }) => {
    try {
      const missingDomains = this.queueMissingDomains(contacts);
      const { identities, errors, domains } = await API.resolveEmailIdentities({
        entryPoint,
        contacts,
        domains: missingDomains,
      });
      if (errors.length > 0) {
        logger.debug("Errors resolving emails:", errors.join(", "));
      }
      const now = new Date();
      for (const contact of contacts) {
        const address = contact.address;
        const identity = identities[address];
        if (!identity) {
          this.emailResolutions.setKey(address, {
            contact,
            status: EmailResolutionStatus.ERROR,
            errors: ["Unexpected error"],
            erroredAt: now,
          });
          continue;
        }
        identity.contact = this.syncContactSource(identity.contact);
        logger.debug("Resolved identity: ", address, identity);
        this.emailResolutions.setKey(address, identity);
      }
      this.resolveDomains(missingDomains, domains ?? {});
    } catch (e) {
      const message = prettyError(e);
      logger.error(
        "API error for emails:",
        contacts.map((c) => c.address).join(", "),
        "Message:",
        message,
      );
      const now = new Date();
      for (const contact of contacts) {
        this.emailResolutions.setKey(contact.address, {
          contact,
          status: EmailResolutionStatus.ERROR,
          errors: [message],
          erroredAt: now,
        });
      }
    }
  };

  resolveEmail = async (contact: EmailContact, entryPoint: EmailEntryPoint) => {
    const res = await API.resolveEmailIdentities({
      entryPoint,
      contacts: [contact],
    });

    return res.identities[contact.address];
  };

  associatePerson = async (contact: EmailContact, url: string, entryPoint: EmailEntryPoint) => {
    const resolved = await extensionScraper.createEntity(url, {
      name: contact.name || contact.address,
    });
    if (!resolved) {
      throw new Error(`Unable to create entity for email: ${contact.address}, and url: ${url}`);
    }
    const updatedIdentity: ResolvedEmailIdentity = {
      contact,
      entity: resolved as EntityWithAttributes,
      status: EmailResolutionStatus.RESOLVED,
      confirmed: true,
    };
    this.emailResolutions.setKey(contact.address, updatedIdentity);
    eventTracker.capture("confirm-email", {
      email: contact.address,
      entry_point: "gmail",
    });
    await API.emailConfirm({
      email: contact.address,
      confirmed: true,
      entityId: resolved.id,
      entryPoint: entryPoint,
    });
    return updatedIdentity;
  };

  markIncorrect = async ({
    contact,
    entity,
    entryPoint,
  }: ResolvedEmailIdentity & { entryPoint: EmailEntryPoint }) => {
    const { address } = contact;
    const identity = this.emailResolutions.get()[address];
    if (!identity) {
      logger.warn(`No identity to mark incorrect for ${address}`);
      return;
    }
    this.emailResolutions.setKey(address, {
      ...identity,
      isInvalid: true,
    });
    eventTracker.capture("wrong-person-email", { email: address, entry_point: "gmail" });
    await API.emailConfirm({
      email: address,
      confirmed: false,
      entityId: entity.id,
      entryPoint,
    });
    void this.pushEmails([contact], entryPoint);
  };

  toggleHideEmail = async ({ contact: email }: EmailIdentity): Promise<void> => {
    const address = email.address;
    const user = this.user;
    if (!user) {
      logger.debug("Toggling hide email skipped because no user. Email:", address);
      return;
    }

    const currentHidden = new Set(this.hiddenEmails.get());
    if (currentHidden.has(address)) {
      logger.debug("Toggling hide email OFF:", address);
      currentHidden.delete(address);
    } else {
      logger.debug("Toggling hide email ON:", address);
      currentHidden.add(address);
    }
    const newHidden = Array.from(currentHidden);
    this.hiddenEmails.set(newHidden);
    await uiStore.updateUserMeta({ hidden_emails: newHidden });
  };

  private processHideDomain = ({
    domain,
    currentHidden,
  }: {
    domain: string;
    currentHidden: string[];
  }): { newHidden: string[]; eventName: string } => {
    let eventName: string;
    const currentHiddenSet = new Set(currentHidden);
    if (currentHiddenSet.has(domain)) {
      eventName = "hide-domain-off";
      currentHiddenSet.delete(domain);
    } else {
      eventName = "hide-domain-on";
      currentHiddenSet.add(domain);
    }
    return { newHidden: Array.from(currentHiddenSet), eventName };
  };

  toggleHideDomain = async ({ domain }: { domain: string }) => {
    const user = this.user;
    if (!user) {
      logger.debug(`toggleHideDomain [${domain}]: Skipping because no user`);
      return;
    }
    logger.debug(`Toggling hide domain: ${domain}`);

    const { newHidden, eventName } = this.processHideDomain({
      domain,
      currentHidden: this.hiddenDomains.get(),
    });
    this.hiddenDomains.set(newHidden);
    eventTracker.capture(eventName, { domain, entry_point: "gmail" });
    await uiStore.updateUserMeta({ hidden_domains: newHidden });
  };

  private queueMissingDomains = (contacts: EmailContact[]): string[] => {
    const result = new Set<string>();
    for (const contact of contacts) {
      const domain = parseEmailDomain(contact.address);
      if (isFreeDomain(domain)) {
        continue;
      }
      if (!this.domainResolutions.get()[domain]) {
        result.add(domain);
        this.domainResolutions.setKey(domain, { status: DomainStatus.Resolving });
      }
    }
    return Array.from(result);
  };

  private resolveDomains = (
    requestedDomains: string[],
    domains: Record<string, EntityWithAttributes>,
  ) => {
    for (const domain of requestedDomains) {
      this.domainResolutions.setKey(domain, {
        status: DomainStatus.Resolved,
        data: domains[domain],
      });
    }
  };
}

const emailStore = new EmailStore();

emailStore.user.listen((user) => {
  emailStore.onUserChange(user);
});

export { emailStore };
