import {
  Attribute,
  AttributeType,
  EmploymentType,
  LinkedInPost,
  LinkedInPostType,
  PositionClassification,
  RecentActivity,
  WorkExperience,
} from "@/types";

const partTimePositions = [
  EmploymentType.PartTime,
  EmploymentType.Contract,
  EmploymentType.Freelance,
];

export function findAttribute<T extends AttributeType>(
  attributes: Attribute[],
  type: T,
): Extract<Attribute, { attributeType: T }> | undefined {
  return attributes?.find(
    (attr): attr is Extract<Attribute, { attributeType: T }> => attr.attributeType === type,
  );
}

export const classifyWorkPositions = (workExperience: WorkExperience): PositionClassification[] => {
  return workExperience.positions.map(classifyWorkPosition);
};

export const classifyWorkPosition = (position: {
  title: string;
  type?: string;
}): PositionClassification => {
  return classifyPosition(
    position.title?.toLocaleLowerCase() || "",
    partTimePositions.includes(position.type as EmploymentType),
  );
};

/**
 * order of assertions:
 * 1. Comments
 * 2. Reposts - link then text
 * 3. Posts - link with text, link, text
 * 4. Unknown - catches any posts that don't match the above
 *
 * I acknowledge that this function is a tad ugly, but it's a necessary evil
 */

export const parseLinkedInPosts = (posts: RecentActivity[]): LinkedInPost[] => {
  return posts.map((post) => {
    const link = urlInString(post.text);
    if (post.title.includes("commented")) {
      return { ...post, type: LinkedInPostType.Comment };
    } else if (post.title.includes("reposted")) {
      if (link) {
        return { ...post, type: LinkedInPostType.RepostLink };
      }
      return { ...post, type: LinkedInPostType.RepostText };
    } else if (post.title.includes("posted")) {
      if (link) {
        return { ...post, type: LinkedInPostType.TextWithLink };
      }
      return { ...post, type: LinkedInPostType.Text };
    } else {
      return { ...post, type: LinkedInPostType.Unknown };
    }
  });
};

const urlInString = (str: string) => {
  const match = str.match(/(https?:\/\/[^\s]+)/g);
  return match ? match[0] : "";
};

const classifyPosition = (title: string, isPartTime: boolean): PositionClassification => {
  for (const [key, value] of Object.entries(positionTypeToWorkExp)) {
    if (value(title, isPartTime)) {
      return key as PositionClassification;
    }
  }
  return PositionClassification.Other;
};

const isBoardPosition = (title: string, _isPartTime: boolean): boolean => {
  return (
    ((title.includes("board") && title.includes("member")) ||
      title.startsWith("board member") ||
      title.startsWith("board observer") ||
      (title.startsWith("chair") && title.includes("board")) || // i.e. chair(man) of the board
      (title.startsWith("board ") && title.includes("chair"))) &&
    !title.includes("founder") &&
    !title.includes("ceo")
  );
};

const isInvestorPosition = (title: string, _isPartTime: boolean): boolean => {
  return (
    title.startsWith("investor") ||
    title.includes("angel investor") ||
    title.includes("seed investor") ||
    (title.includes("series") && title.includes("investor")) ||
    (title.includes("board member") && title.includes("investor")) ||
    (title.includes("series") && title.includes("lead"))
  );
};

const isAdvisorPosition = (title: string, isPartTime: boolean): boolean => {
  return (title.includes("advisor") && isPartTime) || (title.includes("consultant") && isPartTime);
};

const isVolunteerPosition = (title: string, _isPartTime: boolean): boolean => {
  return (
    title.startsWith("volunteer") &&
    !title.includes("manager") &&
    !title.includes("coordinator") &&
    !title.includes("management") &&
    !title.includes("director")
  ); // exclude positions that manage volunteers
};

const positionTypeToWorkExp: Record<
  PositionClassification,
  (title: string, isPartTime: boolean) => boolean
> = {
  [PositionClassification.Board]: isBoardPosition,
  [PositionClassification.Investor]: isInvestorPosition,
  [PositionClassification.Advisor]: isAdvisorPosition,
  [PositionClassification.Volunteer]: isVolunteerPosition,
  [PositionClassification.Other]: () => true,
};
