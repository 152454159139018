import { useEffect, useState } from "react";
import { classNames } from "@/lib/stringUtils";

const PROGRESS_BAR_BG = "bg-blue-200";
const PROGRESS_BAR_FG = "bg-blue-600";

interface Props {
  stepsDone: number;
  stepsLeft: number;
  // If not shown, there will be no "current step" animation
  currentStepEstimatedSecs?: number;
  barClassName?: string;
}

export default function ProgressSteps({
  stepsDone,
  stepsLeft,
  currentStepEstimatedSecs,
  barClassName,
}: Props) {
  return (
    <div className="flex items-center gap-x-1 w-full">
      {stepsDone ?
        new Array(stepsDone)
          .fill(0)
          .map((_, i) => (
            <div key={i} className={`w-full flex ${barClassName} ${PROGRESS_BAR_FG}`} />
          ))
      : null}
      {currentStepEstimatedSecs !== undefined && (
        <CurrentProgress
          step={stepsDone}
          className={`w-full flex ${barClassName} ${PROGRESS_BAR_BG}`}
          filledClass={PROGRESS_BAR_FG}
          estimatedSecs={currentStepEstimatedSecs}
        />
      )}
      {new Array(Math.max(0, stepsLeft - 1)).fill(0).map((_, i) => (
        <div key={i} className={`w-full flex ${barClassName} bg-gray-300`} />
      ))}
    </div>
  );
}

function CurrentProgress({
  step,
  className,
  filledClass,
  estimatedSecs,
}: {
  step: number;
  className: string;
  filledClass: string;
  estimatedSecs: number;
}) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(0);
    const steps = estimatedSecs * 2;
    const timeout = setInterval(() => {
      setWidth((w) => {
        const progressUnit = (100 / steps) * ((200 - w * 1.5) / 100); // ease in
        const next = w + progressUnit;
        if (next >= 100) {
          clearInterval(timeout);
          return 100;
        }
        return next;
      });
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [step, estimatedSecs]);

  return (
    <div className={classNames(className, "relative")}>
      <div
        className={classNames(filledClass, "absolute top-0 left-0 h-full transition-all ")}
        style={{ width: `${width}%`, transitionDuration: width == 0 ? "0s" : "500ms" }}
      />
    </div>
  );
}
