/* eslint-disable @typescript-eslint/no-unsafe-return */
import { SuccessResponse } from "@/types";

import { Connector, ItemResponse, ItemsResponse } from "./apiTypes";

interface GenericModel {
  id: string | number;
}

type DefaultListParams = Record<string, string | number | undefined>;

export class Resource<
  Type extends GenericModel,
  ListType = ItemsResponse<Type>,
  GetType = ItemResponse<Type>,
  ListParams = DefaultListParams,
> {
  constructor(
    public conn: Connector,
    public name: string,
  ) {}

  async list(params?: ListParams): Promise<ListType> {
    const response = await this.conn.axios.get(`/${this.name}`, { params });
    return response.data;
  }

  async create(item: Partial<Type>): Promise<ItemResponse<Type>> {
    const response = await this.conn.axios.post(`/${this.name}`, item);
    return response.data;
  }

  async get(id: string | number): Promise<GetType> {
    const response = await this.conn.axios.get(`/${this.name}/${id}`);
    return response.data;
  }

  async update(id: string | number, item: Partial<Type>): Promise<ItemResponse<Type>> {
    const response = await this.conn.axios.put(`/${this.name}/${id}`, item);
    return response.data;
  }

  async delete(id: string | number): Promise<ItemResponse<Type>> {
    const response = await this.conn.axios.delete(`/${this.name}/${id}`);
    return response.data;
  }
}

export class ResourceWithParent<
  Parent extends GenericModel,
  Type extends GenericModel,
  ListType = ItemsResponse<Type>,
  GetType = ItemResponse<Type>,
  ListParams = DefaultListParams,
> {
  constructor(
    public conn: Connector,
    public parentParam: string,
    public name: string,
  ) {}

  async list(parent: Parent, params?: ListParams): Promise<ListType> {
    const fullParams = { ...(params ?? {}), [this.parentParam]: parent.id };
    const response = await this.conn.axios.get(`/${this.name}`, {
      params: fullParams,
    });
    return response.data;
  }

  async create(parent: Parent, item: Partial<Type>): Promise<ItemResponse<Type>> {
    const response = await this.conn.axios.post(`/${this.name}`, item, {
      params: { [this.parentParam]: parent.id },
    });
    return response.data;
  }

  async get(parentId: string, id: string | number): Promise<GetType> {
    const response = await this.conn.axios.get(`/${this.name}/${id}`, {
      params: { [this.parentParam]: parentId },
    });
    return response.data;
  }

  async update(
    parentId: string,
    id: string | number,
    item: Partial<Type>,
  ): Promise<ItemResponse<Type>> {
    const response = await this.conn.axios.put(`/${this.name}/${id}`, item, {
      params: { [this.parentParam]: parentId },
    });
    return response.data;
  }

  /* delete is discouraged for soft-delete operations - use update instead */
  async delete(parentId: string, id: string | number): Promise<SuccessResponse> {
    const response = await this.conn.axios.delete(`/${this.name}/${id}`, {
      params: { [this.parentParam]: parentId },
    });
    return response.data;
  }
}

export class SingleResource<Type extends GenericModel> {
  constructor(
    public conn: Connector,
    public name: string,
  ) {}

  async get(): Promise<ItemResponse<Type>> {
    const response = await this.conn.axios.get(`/${this.name}`);
    return response.data;
  }

  async update(item: Partial<Type>): Promise<ItemResponse<Type>> {
    const response = await this.conn.axios.put(`/${this.name}`, item);
    return response.data;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function getRoute<ParamsType, Output>(conn: Connector, route: string) {
  return async function (params?: ParamsType) {
    const response = await conn.axios.get(route, { params });
    return response.data as Output;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function postRoute<Input, Output = SuccessResponse>(conn: Connector, route: string) {
  return async function (params: Input) {
    const response = await conn.axios.post(route, params);
    return response.data as Output;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function deleteRoute<ParamsType, Output = SuccessResponse>(conn: Connector, route: string) {
  return async function (params?: ParamsType) {
    const response = await conn.axios.delete(route, { params });
    return response.data as Output;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function putRoute<Input, Params, Output = SuccessResponse>(conn: Connector, route: string) {
  return async function (data?: Input, params?: Params) {
    const response = await conn.axios.put(route, data, { params });
    return response.data as Output;
  };
}
