import { AnalyticsEvent, AnalyticsFilter } from "@/types/analytics.typebox";
import { Static } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

export type AnalyticsFilter = Static<typeof AnalyticsFilter>;

export type AnalyticsEvent = Static<typeof AnalyticsEvent>;

export function isAnalyticsEvent(u: unknown): u is AnalyticsEvent {
  return Value.Check(AnalyticsEvent, u);
}
