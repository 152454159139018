import { logger } from "@/lib/logger";
import { EmailContact, EmailParts } from "@/types";
import { JsonObject } from "@prisma/client/runtime/library";
import moment from "moment";

import emailAddresses, { ParsedMailbox, ParsedResult } from "email-addresses";

export function smartTruncate(input: string, truncationPoint: number, truncateSlashes = true) {
  if (input.length < truncationPoint) return input;

  const previousWordIndex = input.lastIndexOf(" ", truncationPoint);
  if (previousWordIndex === -1) {
    // We cannot find a space to truncate at, so just truncate at the truncation point
    return input.slice(0, truncationPoint) + "...";
  }
  const truncatedString = input.slice(0, previousWordIndex).trim();

  const withoutPrepositions = truncatedString.replace(/\b(with|on|in|at|to|for|of)$/gi, "").trim();
  if (!truncateSlashes) return withoutPrepositions + "...";

  const slashesShortened = withoutPrepositions
    .split(" ")
    .map((word) => {
      if (word.includes("/")) return word.substring(word.lastIndexOf("/") + 1);
      else return word;
    })
    .join(" ");

  return slashesShortened + "...";
}

export function frontTruncate(input: string, truncationPoint: number) {
  if (input.length < truncationPoint) return input;
  return "..." + input.slice(-truncationPoint);
}

export function middleTruncate(input: string, truncationPoint: number) {
  if (input.length < truncationPoint * 2) return input;
  return input.slice(0, truncationPoint) + "..." + input.slice(-truncationPoint);
}

export function truncate(input: string, truncationPoint: number) {
  if (input.length < truncationPoint) return input;
  return input.slice(0, truncationPoint) + "...";
}

export function truncateJson<T>(obj: T, truncationPoint: number, seen: Set<object> = new Set()): T {
  if (typeof obj === "string") {
    return middleTruncate(obj, truncationPoint) as T;
  }
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (seen.has(obj)) {
    return obj;
  }

  seen.add(obj);

  const objRecord = { ...obj } as Record<string, unknown>;

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "string") {
      objRecord[key] = middleTruncate(value, truncationPoint);
    } else if (Array.isArray(value)) {
      objRecord[key] = value.map((s) => truncateJson<unknown>(s, truncationPoint, seen));
    } else if (typeof value === "object" && value !== null) {
      objRecord[key] = truncateJson(value as Record<string, unknown>, truncationPoint, seen);
    }
  }
  return obj;
}

export function fuzzyParseJSON(input: string): JsonObject | null {
  if (!input) return null;
  const firstBracket = input.indexOf("[");
  const firstBrace = input.indexOf("{");

  if (firstBracket == -1 && firstBrace == -1) return null;
  const isArray = firstBracket > -1 && firstBracket < firstBrace;

  const jsonStart = input.indexOf(isArray ? "[" : "{");
  const jsonEnd = input.lastIndexOf(isArray ? "]" : "}");

  if (jsonStart == -1 || jsonEnd == -1) return null;

  const json = input.substring(jsonStart, jsonEnd + 1);
  try {
    return JSON.parse(json) as JsonObject;
  } catch (e) {
    // fix newline issues
    const sloppyJson = cleanSloppyJson(json);
    try {
      return JSON.parse(sloppyJson) as JsonObject;
    } catch (e) {}

    // sometimes trailing commas are generated. sometimes no commas are generated,
    const fixedJsonString = json.replace(/"\n"/g, '",').replace(/,\s*([\]}])/g, "$1");
    try {
      return JSON.parse(fixedJsonString) as JsonObject;
    } catch (e) {}

    // give up
    throw e;
  }
}
// safely parse html codes
export function decodeHtml(str: string) {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return doc.documentElement.textContent;
}

export function decodeUnicodeEscapes(str: string): string {
  // This regex matches \u followed by exactly four hexadecimal digits
  return str.replace(/\\u([0-9a-fA-F]{4})/g, (match, grp) =>
    String.fromCharCode(parseInt(grp as string, 16)),
  );
}

export function classNames(...classes: (string | undefined | null | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export function pluralize(count: number, word: string, pluralWord?: string) {
  return `${count} ${count === 1 ? word : pluralWord || `${word}s`}`;
}

export function splitOnce(s: string, on: string) {
  const index = s.indexOf(on);
  if (index == -1) return [s];
  return [s.slice(0, index), s.slice(index + 1)];
}

const notToUppercase = [
  "a",
  "an",
  "the",
  "and",
  "but",
  "or",
  "for",
  "nor",
  "on",
  "at",
  "to",
  "from",
  "by",
  "of",
];

export function titleCase(input: string) {
  return input
    .split(" ")
    .map((word) =>
      !word || notToUppercase.includes(word) ?
        word
      : `${word[0].toUpperCase()}${word.substring(1)}`,
    )
    .join(" ");
}

export function snakeToTitleCase(name: string) {
  return titleCase(name.replace(/_/g, " "));
}

export function generateRandomString(length: number, options?: { lowerCaseOnly: boolean }) {
  const characters =
    options?.lowerCaseOnly ?
      "abcdefghijklmnopqrstuvwxyz0123456789"
    : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";

  // Create an array of 32-bit unsigned integers
  const randomValues = new Uint32Array(length);

  // Generate random values
  if (typeof crypto == "undefined") {
    for (let i = 0; i < length; i++) {
      randomValues[i] = Math.floor(Math.random() * charactersLength);
    }
  } else {
    crypto.getRandomValues(randomValues);
  }
  randomValues.forEach((value) => {
    result += characters.charAt(value % charactersLength);
  });
  return result;
}

export async function generateUniqueRandomString({
  checker,
  length = 6,
  lowerCaseOnly = false,
}: {
  checker: (value: string) => Promise<boolean>;
  length?: number;
  lowerCaseOnly?: boolean;
}) {
  for (let i = 0; i < 10; i++) {
    const slug = generateRandomString(length, { lowerCaseOnly });
    try {
      const result = await checker(slug);
      if (result) return slug;
    } catch (e) {
      logger.error(e);
    }
  }
  throw new Error("Failed to generate unique random string");
}

// this piece of shit was generated by gpt-4
export function cleanSloppyJson(sloppyJson: string) {
  let isInsideString = false;
  let escapedJson = "";

  for (let i = 0; i < sloppyJson.length; i++) {
    const currentChar = sloppyJson[i];

    if (isInsideString && currentChar === "\n") {
      // If inside a string, replace newline with \n
      escapedJson += "\\n";
      continue;
    }

    // Check if current character is a quote that is not being escaped
    if (currentChar === '"' && (i === 0 || sloppyJson[i - 1] !== "\\")) {
      isInsideString = !isInsideString;
    }
    escapedJson += currentChar;
  }

  return escapedJson;
}

export function cleanBaseUrl(url: string) {
  if (!url) return ""; // this is a degenerate condition but prevents a crash

  if (!url.startsWith("http")) {
    url = "https://" + url;
  }
  if (!url.includes(".")) {
    url += ".com";
  }
  url = url.replace("/www.", "/");
  if (url.endsWith("/")) {
    url = url.slice(0, url.length - 1);
  }
  try {
    const parsed = new URL(url);
    parsed.protocol = "https:";
    if (parsed.hostname.includes(".linkedin.com")) parsed.hostname = "linkedin.com";

    if (
      parsed.pathname == "/jobs" ||
      parsed.pathname == "/careers" ||
      parsed.pathname == "/about"
    ) {
      parsed.pathname = "/";
    }

    if (
      parsed.hostname == "linkedin.com" &&
      (parsed.pathname.startsWith("/in/") || parsed.pathname.startsWith("/company/"))
    ) {
      const path = parsed.pathname.split("/");
      if (path.length > 3) {
        // re-write query to only contain first two parts
        parsed.pathname = path.slice(0, 3).join("/");
      }
    }
    // only return hostname and path
    if (parsed.pathname == "/") return parsed.origin;

    return parsed.origin + parsed.pathname;
  } catch (e) {
    return url;
  }
}

const personOrCompanyLinkedInUrlRegex = /^https:\/\/linkedin\.com\/(in|company)\/([^/?#]+)/;

export function isPersonOrCompanyLinkedInUrl(url: string) {
  if (!url) return false;
  const cleanedUrl = cleanBaseUrl(url);
  return personOrCompanyLinkedInUrlRegex.test(cleanedUrl);
}

export type PersonOrCompanyLinkedInUrl = {
  inputUrl: string;
  cleanedUrl: string;
  type: "person" | "company";
  profile: string;
};

export function parsePersonOrCompanyLinkedInUrl(url: string): PersonOrCompanyLinkedInUrl | null {
  if (!url) return null;

  const cleanedUrl = cleanBaseUrl(url);
  const match = cleanedUrl.match(personOrCompanyLinkedInUrlRegex);

  if (!match) return null;

  const [_, typeSegment, profile] = match;
  const type = typeSegment === "in" ? "person" : "company";

  return { inputUrl: url, cleanedUrl, type, profile };
}

export function parsePersonOrCompanyLinkedInProfile(url: string): string | null {
  return parsePersonOrCompanyLinkedInUrl(url)?.profile ?? null;
}

export function prettyUrl(url: string, skipPath?: boolean) {
  try {
    const parsed = new URL(url);
    return (
      parsed.hostname.replace("www.", "") + (skipPath ? "" : parsed.pathname.replace(/\/$/, ""))
    );
  } catch (e) {
    return url.replace(/https?:\/\//, "").replace("www.", "");
  }
}

export function safeHostname(url: string) {
  return url.replace(/.*:\/\//, "").replace(/\/.*/, "");
}

// parse 1d, 1w, 1mo, 1y into a date
const compactMatcher = /(\d+)(\w+)/;
export const parseCompactDate = (dateStr: string) => {
  const parts = compactMatcher.exec(dateStr);
  const date = new Date();
  if (!parts) return date;
  const num = parseInt(parts[1]);
  const unit = parts[2];

  if (unit == "h") date.setHours(date.getHours() - num);
  else if (unit == "d") date.setDate(date.getDate() - num);
  else if (unit == "w") date.setDate(date.getDate() - num * 7);
  else if (unit == "mo") date.setMonth(date.getMonth() - num);
  else if (unit == "y") date.setFullYear(date.getFullYear() - num);
  return date;
};

// claude came up with this list
const emailPrefixesToSkip = [
  "abuse",
  "accounts",
  "admin",
  "alerts",
  "auto",
  "automated",
  "billing",
  "bot",
  "bounce",
  "careers",
  "contact",
  "customerservice",
  "digest",
  "do-not-reply",
  "donotreply",
  "feedback",
  "founders",
  "hello",
  "help",
  "hr",
  "info",
  "inquiries",
  "jobs",
  "legal",
  "list",
  "mailer-daemon",
  "marketing",
  "media",
  "newsletter",
  "no-reply",
  "no.reply",
  "no_reply",
  "noreply",
  "notes",
  "notification",
  "notifications",
  "office",
  "orders",
  "postmaster",
  "press",
  "recruit",
  "reply",
  "returns",
  "sales",
  "security",
  "service",
  "social",
  "spam",
  "subscribe",
  "support",
  "system",
  "team",
  "unsubscribe",
  "updates",
  "webmaster",
  "hello",
];

// claude came up with this list
const domainsToSkip = [
  ".amazonses.com",
  ".atlassian.com",
  ".eloqua.com",
  ".facebook.com",
  ".freshdesk.com",
  ".github.com",
  ".google.com",
  ".guerrillamail.com",
  ".helpscout.com",
  ".hubspot.com",
  ".intercom.io",
  ".jira.com",
  ".linkedin.com",
  ".mailchimp.com",
  ".mailgun.com",
  ".mailinator.com",
  ".marketo.com",
  ".salesforce.com",
  ".sendgrid.com",
  ".shopify.com",
  ".slack.com",
  ".squarespace.com",
  ".temp-mail.org",
  ".trello.com",
  ".twitter.com",
  ".wix.com",
  ".wordpress.com",
  ".zendesk.com",
  "googlegroups.com",
  "groups.google.com",
];

const numericRegex = /^\d+$/;
export function shouldSkipEmail(email: string): boolean {
  const { local: emailFront, domain: emailDomain, address } = toEmailParts(email);
  if (!address) return true;

  // Check against emailsToSkip list
  if (
    emailPrefixesToSkip.some(
      (s) =>
        emailFront === s ||
        emailFront.startsWith(`${s}.`) ||
        emailFront.endsWith(`.${s}`) ||
        emailFront.includes(`-${s}`) ||
        emailFront.includes(`${s}-`) ||
        emailFront.includes(`.${s}.`),
    )
  ) {
    return true;
  }

  // Check against domainsToSkip list
  if (domainsToSkip.some((domain) => emailDomain.includes(domain))) {
    return true;
  }

  // Check for purely numeric
  if (numericRegex.test(emailFront)) {
    return true;
  }

  return false;
}

export function stripUnicode(input: string | null | undefined) {
  if (!input) return input;
  return input.replace(/[^\x00-\x7F]/g, "");
}
export function emailToQuery(email: string): string {
  const { local, domain, name } = toEmailParts(email);
  const queryParts = [local, removeTld(domain)];
  if (name) {
    queryParts.unshift(name);
  }
  return queryParts.join(" ").replace(/[^\w]+|\s+/g, " ");
}

function removeTld(domain: string) {
  return domain.replace(/\.[^.]+$/, "");
}

export function parseEmailDomain(input: string): string {
  const parsed = input ? (emailAddresses.parseOneAddress(input) as ParsedMailbox) : null;
  if (!parsed) return "";
  const fullDomain = parsed.domain.toLowerCase();
  const domainParts = fullDomain.split(".");
  return domainParts.slice(-2).join(".");
}

export function normalizeContact(contact: EmailContact): EmailParts | null {
  const parsed = toEmailParts(contact.address);
  if (!parsed.address) return null;
  const { name: inputName } = contact;
  const normalizedName =
    inputName && equalsIgnoreCase(inputName, parsed.address) ? undefined : inputName;
  return {
    ...parsed,
    name: normalizedName,
  };
}

export function toEmailParts(input: string): EmailParts {
  const parsed =
    input ?
      // @ts-expect-error emailAddresses returns ParsedMailbox
      (emailAddresses({
        input,
        oneResult: true,
        rfc6532: true, // Allow unicode
        simple: true,
        // Start parsing at address instead of address-list so that
        // we parse commas as part of the display name
        startAt: "address",
        commaInDisplayName: true, // Allow addresses in name
        rejectTLD: true,
      }) as ParsedMailbox)
    : null;
  if (!parsed || !parsed.address)
    return { input, address: "", domain: "", local: "", name: undefined };

  return normalizeEmailParts({
    input: input,
    address: parsed.address,
    name: parsed.name ?? undefined,
    domain: parsed.domain,
    local: parsed.local,
  });
}

function equalsIgnoreCase(a: string | null, b: string | null): boolean {
  if (a == null || b == null) {
    return a === b;
  }
  return a.toLowerCase() === b.toLowerCase();
}

const gmailDomains = ["gmail.com", "googlemail.com"];

function normalizeEmailParts(emailParts: EmailParts): EmailParts {
  const { local, domain, address, ...rest } = emailParts;

  const normalizedDomain = domain.toLowerCase();
  let normalizedLocal = local.toLowerCase();

  if (gmailDomains.includes(normalizedDomain)) {
    normalizedLocal = normalizedLocal.replace(/\./g, "");
  }

  const plusIndex = normalizedLocal.indexOf("+");
  if (plusIndex !== -1) {
    normalizedLocal = normalizedLocal.substring(0, plusIndex);
  }

  return {
    ...rest,
    address: `${normalizedLocal}@${normalizedDomain}`,
    local: normalizedLocal,
    domain: normalizedDomain,
  };
}

export function parseDollarAmount(amount: string): number | undefined {
  if (!amount) return undefined;
  const units: Record<string, number> = {
    K: 1_000,
    M: 1_000_000,
    B: 1_000_000_000,
  };

  const match = amount.replace("$", "").match(/(\d+(\.\d+)?)([KMB]?)/i);
  if (!match) return parseInt(amount.replace(/\D/g, ""), 10);

  const [, num, , unit] = match;
  const multiplier = units[unit.toUpperCase()] || 1;
  return parseFloat(num) * multiplier;
}

export function formatDollarAmount(amount: number): string {
  const format = (num: number, suffix: string) => {
    const formatted = (num / 1).toFixed(1);
    return `$${formatted.endsWith(".0") ? formatted.slice(0, -2) : formatted}${suffix}`;
  };

  if (amount >= 1_000_000_000) {
    return format(amount / 1_000_000_000, "B");
  } else if (amount >= 1_000_000) {
    return format(amount / 1_000_000, "M");
  } else if (amount >= 1_000) {
    return format(amount / 1_000, "K");
  } else {
    return `$${amount}`;
  }
}

export function sanitizeHex(s: string): string {
  // Handle hex escapes
  return s.replace(
    /\\x([0-9a-fA-F]{2})|\\x[0-9a-fA-F]?|\\u([0-9a-fA-F]{4})|\\u[0-9a-fA-F]{0,3}/g,
    (match, p1, p2) => {
      if (p1) {
        // Complete \x escape
        return Buffer.from(p1, "hex").toString("utf-8");
      } else if (p2) {
        // Complete \u escape
        return String.fromCharCode(parseInt(p2, 16));
      } else {
        // Incomplete escape, strip it
        return "";
      }
    },
  );
}

export function sanitizeObjectStrings<T>(obj: T, seen: Set<object> = new Set()): T {
  if (typeof obj === "string") {
    return sanitizeHex(obj) as T;
  }
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (seen.has(obj)) {
    return obj;
  }

  seen.add(obj);

  const objRecord = obj as Record<string, unknown>;

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "string") {
      objRecord[key] = sanitizeHex(value);
    } else if (Array.isArray(value)) {
      objRecord[key] = value.map((s) => sanitizeObjectStrings<unknown>(s, seen));
    } else if (typeof value === "object" && value !== null) {
      objRecord[key] = sanitizeObjectStrings(value as Record<string, unknown>, seen);
    }
  }
  return obj;
}

export const ageFromBirthYear = (birthYear: number | undefined) => {
  if (!birthYear) return undefined;
  const age = moment().diff(moment(birthYear, "YYYY"), "years");
  if (age < 18) return "under 18";
  if (age < 21) return "around 20";
  if (age > 100) return "100+";
  else {
    const decade = Math.floor(age / 10) * 10;
    const position = age % 10;
    const modifier =
      !position ? "about"
      : position < 4 ? "early"
      : position < 8 ? "mid"
      : "late";
    return `${modifier} ${decade}${position ? "s" : ""}`;
  }
};

const titles = ["mba", "phd", "dr", "mr", "jd", "md"];
const titlePattern = new RegExp(`\\b(${titles.join("|")})\\b`, "gi");

function stripTitles(name: string) {
  name = name.replace(/\./g, "");

  let cleanName = name.replace(titlePattern, "").trim();

  const commaPattern = /^(\w+\s+\w+),.*/;

  cleanName = cleanName.replace(commaPattern, "$1").trim();

  return cleanName;
}

export function slugify(name: string) {
  let parts = stripTitles(name)
    .replace(/\([^)]*\)/g, "")
    .trim()
    .split(" ")
    .map((part) => part.replace(/[^\p{L}\p{N}_]+/gu, "").trim())
    .filter(Boolean);

  if (parts.length == 0) {
    // this would happen if e.g. a name is mostly special characters
    parts = name.split(" ").map((part) => part.replace(/[^\p{L}\p{N}_]+/gu, "-"));
  }

  const encoded = encodeURIComponent(parts.join("_"));
  if (encoded.length == 0) {
    return Math.random().toString(36).substring(2, 15);
  }
  return encoded.substring(0, 40);
}

export function extractURLs(input: string): string[] {
  const urlPattern = /(?:(?:https?):\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z0-9.-]+(?:\/[^\s?#]*)?)/g;

  const matches = [];
  let match;

  while ((match = urlPattern.exec(input)) !== null) {
    matches.push("https://" + (match[1] || "") + (match[2] || ""));
  }

  return Array.from(new Set(matches));
}

export function extractEmails(input: string): EmailParts[] {
  const elements = input.split(",");
  const result = [];
  for (const element of elements) {
    const parts = toEmailParts(element);
    if (parts.address) result.push(parts);
  }
  return Array.from(new Set(result));
}

export function extractURLsAndEmails(
  input: string,
): { url?: string; email?: EmailParts; name: string }[] {
  const elements = input
    .split(/[,\n]/)
    .map((s) => s.trim())
    .filter(Boolean);
  const result = [];
  for (const element of elements) {
    const email = toEmailParts(element);
    if (email.address) {
      result.push({ email, name: element });
      continue;
    }
    const urls = extractURLs(element);
    if (urls.length > 0) {
      for (const url of urls) result.push({ url, name: element });
      continue;
    }
    result.push({ name: element });
  }
  return result;
}

export function formatNumber(count: number) {
  return new Intl.NumberFormat("en-US").format(count);
}

export function validateSourcePattern(sourcePattern: string) {
  // Regex from GPT (Only used in blocked sources)
  const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-_.]*\.[a-zA-Z]{2,}(\/[^\s]*)?$/;
  return domainRegex.test(sourcePattern);
}

export function extractLowerSlugFromDistillUrl(url: string) {
  const distillDomains = ["localhost:3000", "distill.fyi"];
  const isDistillDomain = distillDomains.includes(safeHostname(url));
  if (!isDistillDomain) return null;

  const match = url.match(/\/(p|c)\/([^\/]+)/);
  if (match) {
    return match[0];
  }

  return null;
}

export function unescapeHtmlCharacterEntities(s: string) {
  return decodeURIComponent(
    s.replace(/&#x\w*;/, (match: string) => {
      return `%${match.substring(3, match.length - 1)}`;
    }),
  );
}

export function normalizeUrl(url: string) {
  if (!url.includes("://")) {
    url = "https://" + url;
  }

  try {
    const parsed = new URL(url);
    const hostname = parsed.hostname.replace(/^www\./, "");
    url = `${parsed.protocol}//${hostname}${parsed.pathname}${parsed.search}`;
  } catch (e) {
    url = url.replace(/.*?:\/\//g, "");
  }
  return url.endsWith("/") ? url.slice(0, -1) : url;
}
