/* eslint-disable @next/next/no-img-element */
import CompanySubtitle from "@/components/companies/CompanySubtitle";
import ProfilePopoverMenu from "@/components/entities/ProfilePopoverMenu";
import EntityImageEditModal from "@/components/modals/EntityImageEditModal";
import PersonSubtitle from "@/components/people/PersonSubtitle";
import { SidebarVariant, useSidebar } from "@/components/providers/SidebarProvider";
import EntityHighlights from "@/components/sections/EntityHighlights";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { CompanyFact } from "@/models/facts/facts.types";
import { getMainEntityStore, useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import {
  EntityType,
  EntityUIType,
  NO_DESCRIPTION,
  PipelineRunStatus,
  ProfilePageSection,
} from "@/types";
import { entityUrl } from "@/utils/entityUtils";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";
import Link from "next/link";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

export default withErrorBoundary(function EntityV3Header({
  status,
}: {
  status: PipelineRunStatus;
}) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const updateQueue = useStore(entityStore.updateEntityQueue);
  const attributes = useStore(entityStore.attributes);
  const facts = useStore(entityStore.facts);
  const { openSidebar } = useSidebar();
  const companyBrandedBlurb = facts[CompanyFact.BrandedBlurb]?.value;
  const user = useStore(uiStore.user);

  const queueValue = updateQueue[entity.id];
  const isRegenerating = queueValue && !queueValue.resolved;

  const entityProps = { entity, attributes };

  const entityDescription =
    entity.type === EntityType.Company ? companyBrandedBlurb
    : entity.description?.startsWith(NO_DESCRIPTION) ? undefined
    : entity.description;

  const addToListModal = () =>
    openSidebar(SidebarVariant.AddEntityToList, {
      entity,
      title: `Add ${entity.name} to list`,
    });

  const isSidebar = entityStore.type === EntityUIType.Sidebar;
  const sidebar = useSidebar();

  return (
    <ProfileBubble className="flex flex-col gap-2" section={ProfilePageSection.Highlights}>
      <div className="flex">
        <div className="flex gap-4 items-center flex-1">
          <ProfileEntityImage entity={entity} />
          <div className="flex-1">
            <div className="flex">
              <h1 className="text-2xl font-semibold flex-1 mr-2">{entity.name}</h1>
              <div className="flex text-gray-400 flex-row flex-wrap sm:justify-end items-center gap-2">
                <div className="hidden sm:flex">
                  {isSidebar ?
                    <button
                      className="text-white bg-brand-600 px-2 py-1 rounded hover:bg-brand-700 flex items-center"
                      onClick={() => {
                        getMainEntityStore().loadFromSidebar();
                        sidebar.closeSidebar();
                      }}
                    >
                      <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />
                      Open Profile
                    </button>
                  : user ?
                    <button
                      className="text-white bg-brand-600 px-2 py-1 rounded hover:bg-brand-700"
                      onClick={addToListModal}
                    >
                      Add to List
                    </button>
                  : null}
                </div>
                <ProfilePopoverMenu
                  entity={entity}
                  isRegenerating={isRegenerating}
                  status={status}
                />
              </div>
            </div>
            {entity.type === EntityType.Person ?
              <PersonSubtitle skipLastUpdated {...entityProps} />
            : <CompanySubtitle skipLastUpdated {...entityProps} />}
          </div>
        </div>
      </div>

      <div className="text-sm">{entityDescription}</div>

      <EntityHighlights />
    </ProfileBubble>
  );
});

function ProfileEntityImage(props: { entity: Entity }) {
  const [open, setOpen] = useState(false);

  const isAdmin = useStore(uiStore.user)?.email?.includes("@distill.fyi");

  return (
    <>
      {isAdmin && <EntityImageEditModal open={open} setOpen={setOpen} />}
      <div
        className={twJoin("h-16 w-16 overflow-hidden c", isAdmin && "cursor-pointer")}
        onClick={isAdmin ? () => setOpen(true) : undefined}
      >
        <EntityIconWithPlaceholder className="h-16 w-16" imageClassName="h-16 w-16" {...props} />
      </div>
    </>
  );
}
