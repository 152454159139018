import { EntityWithAttributes, isEntityWithAttributes, User } from "@/types/db";
import { GenericProfile } from "@/types/snapshots";
import { Kind, TSchema, Type, TypeRegistry } from "@sinclair/typebox";
import { UUID } from "crypto";

export const Nullable = <T extends TSchema>(schema: T) => Type.Union([schema, Type.Null()]);
export const OptionalNullable = <T extends TSchema>(schema: T) => Type.Optional(Nullable(schema));

export const PagingOptions = Type.Object({
  page: Type.Number(),
  pageSize: Type.Optional(Type.Number()),
});

export const PrismaModel = Type.Object({
  id: Type.Readonly(Type.String()),
  createdAt: Type.Readonly(Type.Date()),
  updatedAt: Type.Readonly(Type.Date()),
});

export const DeleteablePrismaModel = Type.Intersect([
  PrismaModel,
  Type.Object({ deletedAt: Nullable(Type.Date()) }),
]);

TypeRegistry.Set("EntityWithAttributes", (_, value) => {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  return isEntityWithAttributes(value);
});

TypeRegistry.Set("GenericProfile", (_, value) => {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  const profile = value as Partial<GenericProfile>;
  return typeof profile.generated === "boolean";
});

TypeRegistry.Set("User", (_, value) => {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  const user = value as Partial<User>;
  return typeof user.id === "string";
});

TypeRegistry.Set("UUID", (_, value) => typeof value === "string" && value.length === 36);

export const EntityWithAttributesSchema = Type.Unsafe<EntityWithAttributes>({
  [Kind]: "EntityWithAttributes",
});
export const GenericProfileSchema = Type.Unsafe<GenericProfile>({ [Kind]: "GenericProfile" });
export const UserSchema = Type.Unsafe<User>({ [Kind]: "User" });
export const UUIDSchema = Type.Unsafe<UUID>({ [Kind]: "UUID" });
