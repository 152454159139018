import API from "@/client/api";
import Modal, { ModalBody } from "@/components/modals/Modal";
import SearchBox from "@/components/search/SearchBox";
import Button, { ButtonVariant } from "@/components/ui/Button";
import Spinner from "@/components/ui/Spinner";
import { prettyError } from "@/lib/miscUtils";
import { classNames } from "@/lib/stringUtils";
import { AutocompleteEntity, Entity, EntityType } from "@/types";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import TextField from "@/components/inputs/TextField";
import WithLabel from "@/components/inputs/WithLabel";
import Link from "next/link";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectCompany: ({ id, name, company }: { id?: string; name?: string; company?: Entity }) => void;
};

export default function ChangeCompanyModal({ open, setOpen, selectCompany }: Props) {
  const [cantFindCompany, setCantFindCompany] = useState<string | undefined>(undefined);
  const [loadingCompany, setLoadingCompany] = useState(false);

  useEffect(() => {
    if (open) {
      setCantFindCompany(undefined);
    }
  }, [open]);

  return (
    <>
      <Modal open={open} setOpen={setOpen}>
        <ModalBody
          title={"Select an organization"}
          icon={<QuestionMarkCircleIcon className={classNames("w-6 h-6", "text-blue-600")} />}
          iconBg={"bg-blue-100"}
          className="sm:min-w-[500px] sm:min-h-[400px]"
        >
          <SearchBox
            initialQuery={""}
            submitButton
            className="max-w-72 lg:max-w-96"
            magnifyClassName="left-4"
            placeholder="Start typing or paste a distill.fyi link"
            type={EntityType.Company}
            cantFindPrefix={"Cannot find"}
            onlyDistillEntities
            onSelect={async (e: AutocompleteEntity) => {
              if (e.id) {
                setLoadingCompany(true);
                try {
                  const entity = e.id ? await API.entities.get(e.id) : undefined;
                  selectCompany({ id: e.id, company: entity });
                } catch (e) {
                  toast.error(prettyError(e));
                } finally {
                  setLoadingCompany(false);
                }
              } else {
                setCantFindCompany(e.name);
              }
            }}
          />
          {loadingCompany && <Spinner className="mt-4" />}
          {cantFindCompany && (
            <div className="mt-4 text-sm">
              Can&apos;t find {cantFindCompany}?{" "}
              <Link
                className="hover:underline font-semibold"
                href={`/search?q=${cantFindCompany}`}
                target="_blank"
              >
                Search or create {cantFindCompany}
              </Link>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
