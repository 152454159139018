import {
  ButtonHTMLAttributes,
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { usePopper } from "react-popper";
import { twMerge } from "tailwind-merge";

import { classNames } from "@/lib/utils";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function PopoverMenu({
  buttonLabel,
  buttonClass,
  buttonProps,
  popoverClass,
  children,
  popoverProps,
  dismissOnMouseOut,
  onOpen,
  onClose,
  popperOptions = {},
  keepOpenOnClick,
  mutationState, // used to force re-render
  initiallyOpen,
}: PropsWithChildren<{
  buttonLabel: string | React.ReactNode;
  buttonClass?: string;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  popoverClass?: string;
  popoverProps?: HTMLAttributes<HTMLDivElement>;
  popperOptions?: Parameters<typeof usePopper>[2];
  onOpen?: () => void;
  onClose?: () => void;
  dismissOnMouseOut?: boolean;
  keepOpenOnClick?: boolean;
  mutationState?: number;
  initiallyOpen?: boolean;
}>) {
  const [open, _setOpen] = useState(initiallyOpen);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    ...popperOptions,
  });

  const setOpen = (o: boolean) => {
    if (o && onOpen) onOpen();
    if (!o && onClose) onClose();
    _setOpen(o);
  };

  useEffect(() => {
    if (!open) return;
    void update?.();
    const docClickListener = (e: Event) => {
      if (keepOpenOnClick && popperElement?.contains(e.target as Node)) return;
      setOpen(false);
      document.removeEventListener("click", docClickListener);
    };
    setTimeout(() => {
      document.addEventListener("click", docClickListener);
    }, 0);
    return () => {
      document.removeEventListener("click", docClickListener);
    };
  }, [keepOpenOnClick, open, popperElement, update]);

  return (
    <>
      <button
        ref={setReferenceElement}
        className={twMerge(buttonClass, open && "bg-gray-200/50")}
        {...buttonProps}
        onClick={(e) => {
          e.stopPropagation(); // stop parent elements onclick handlers
          e.preventDefault(); // stop parent link onclick handlers
          setOpen(!open);
        }}
      >
        {buttonLabel}
      </button>
      <div
        ref={setPopperElement}
        className={twMerge(
          `z-10 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`,
          popoverClass,
          !open && "hidden",
        )}
        style={styles.popper}
        onMouseLeave={
          dismissOnMouseOut ?
            () => {
              setOpen(false);
            }
          : undefined
        }
        {...attributes.popper}
        {...popoverProps}
      >
        {children}
      </div>
    </>
  );
}

export function PopoverSelectOption({
  selected,
  className,
  children,
  ...rest
}: { selected?: boolean } & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge(
        selected ? "bg-brand-600 text-white" : "text-gray-900 hover:bg-gray-200",
        "relative cursor-pointer select-none py-2 pl-8 pr-4",
        className,
      )}
      {...rest}
    >
      <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
        {children}
      </span>

      {selected ?
        <span
          className={classNames("text-white, absolute inset-y-0 left-0 flex items-center pl-1.5")}
        >
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      : null}
    </div>
  );
}

export function PopoverOption({
  stopPropagation,
  className,
  children,
  onClick,
  ...rest
}: HTMLAttributes<HTMLDivElement> & { stopPropagation?: boolean }) {
  return (
    <div
      className={twMerge(
        "relative cursor-pointer select-none px-4 py-2 hover:bg-brand-200",
        className,
      )}
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
          e.preventDefault();
        }
        onClick?.(e);
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
