import EditExperienceRow from "@/components/experiences/EditExperienceRow";
import { calculateDateString } from "@/components/experiences/utils";
import { prettyError } from "@/lib/miscUtils";
import { smartTruncate } from "@/lib/stringUtils";
import {
  BaseRelationshipData,
  RelationshipDataEducatedAt,
  RelationshipDataVolunteeredAt,
  RelationshipDataWorkedAt,
  RelationshipType,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { MinusCircleIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { AnchorHTMLAttributes, useEffect, useState } from "react";
import Markdown from "react-markdown";
import { toast } from "react-toastify";
import remarkBreaks from "remark-breaks";

interface Props {
  position: RelationshipWithEntity;
  showDates: boolean;
  sectionEditMode: boolean;
  blockFinishEditing: (arg: boolean) => void;
}

export default function ExperienceDetailsRow({
  position,
  showDates,
  sectionEditMode,
  blockFinishEditing,
}: Props) {
  // lack of id means that the position is new, which means we are in add mode
  const [positionEditMode, setPositionEditMode] = useState(!position?.id);
  const entityStore = useEntityStore();
  useEffect(() => {
    if (!sectionEditMode) setPositionEditMode(false);
  }, [sectionEditMode]);

  const data = position?.data || {};
  const title =
    (data as RelationshipDataEducatedAt)?.degree ||
    (data as RelationshipDataWorkedAt)?.title ||
    (data as RelationshipDataVolunteeredAt)?.role;

  const location = (data as RelationshipDataWorkedAt)?.location;
  const type = (data as RelationshipDataWorkedAt)?.type;
  const positionDates =
    position && (showDates || sectionEditMode) ? calculateDateString(position, position) : "";

  const subtitle = [positionDates, type, location].filter(Boolean).join(" · ");

  useEffect(() => {
    blockFinishEditing(positionEditMode);
    // depending on blockFinishEditing causes a loop since it's not memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionEditMode]);

  if (positionEditMode) {
    return (
      <EditExperienceRow relationship={position} onFinishEdit={() => setPositionEditMode(false)} />
    );
  }

  if (!positionEditMode) {
    if (!title && !sectionEditMode) return null;
    return (
      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          <div className="flex items-center">
            {sectionEditMode && (
              <PencilSquareIcon
                className="flex-shrink-0 cursor-pointer hover:text-brand-500 w-5 h-5 ml-0 mr-0 text-brand-600"
                onClick={() => {
                  setPositionEditMode(true);
                }}
              />
            )}
            {sectionEditMode && (
              <MinusCircleIcon
                className="flex-shrink-0 cursor-pointer hover:text-red-500 w-5 h-5 ml-1 -mr-12 text-red-600"
                onClick={() => {
                  uiStore.showConfirmModal.set({
                    type: "danger",
                    title: "Delete position " + (title || "") + "?",
                    subtitle: "Delete selected position? The action is not reversible.",
                    onClick: async () => {
                      try {
                        await entityStore.deleteRelationship(position);
                      } catch (e) {
                        toast.error("Error deleting position: " + prettyError(e));
                      }
                    },
                  });
                }}
              />
            )}
            <div className="sm:ml-14 border hover:border-gray-400 py-2 px-3 rounded-lg w-full text-sm">
              <div>
                <span className="font-medium">{title || "(no title)"}</span>
                {subtitle && <span className="ml-2 text-xs text-gray-400">{subtitle}</span>}
              </div>
              <DescriptionRow editMode={sectionEditMode} relationship={position} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

const EXPANDO_THRESHOLD = 300;

function DescriptionRow({
  relationship,
  editMode,
}: {
  relationship: Partial<RelationshipWithEntity>;
  editMode: boolean;
}) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (editMode) setExpanded(true);
  }, [editMode]);

  const data = (relationship.data as BaseRelationshipData) || {};
  if (!data?.description) return null;

  const needsExpando = !expanded && data.description?.length > EXPANDO_THRESHOLD;

  return (
    <>
      <Markdown
        className="text-sm whitespace-pre-wrap inline"
        remarkPlugins={[remarkBreaks]}
        components={{
          a(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
            const { title, ...rest } = props;
            return (
              <a
                {...rest}
                onClick={(e) => {
                  e.preventDefault();
                  setExpanded((e) => !e);
                }}
                className="text-brand-600 cursor-pointer"
              />
            );
          },
        }}
      >
        {needsExpando ?
          smartTruncate(data.description || "", EXPANDO_THRESHOLD - 30) + " [see more](#more)"
        : data.description}
      </Markdown>
    </>
  );
}
