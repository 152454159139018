import API from "@/client/api";
import { getSearchRowDescription } from "@/components/search/SearchResultsRow";
import LinkedInIcon from "@/components/ui/LinkedInIcon";
import Loader from "@/components/ui/Loader";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { useCachedAPI } from "@/hooks/swr";
import { logger } from "@/lib/logger";
import {
  isPersonOrCompanyLinkedInUrl,
  parsePersonOrCompanyLinkedInProfile,
  prettyUrl,
  smartTruncate,
} from "@/lib/stringUtils";
import { FoundEntity } from "@/stores/searchStore";
import { Entity, GenericProfile } from "@/types";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

interface Props {
  foundEntity: FoundEntity;
  actualEntity?: Entity;
  onSelect: (url: string) => Promise<void>;
}

/**
 * This component pulls very heavily from /search/SearchResultsRow.tsx
 * If you are updating the search results UI, please update there.
 * If you are updating the association UI, please update here.
 */
function AssociateSearchResultRow({ foundEntity, actualEntity, onSelect }: Props) {
  // With Tailwind 3 it's impossible to create a group-not-has combo, which we would
  // need in order to share hover state to children, but exclude some of them.
  // That's why we need to use a separate state.
  const [externalLinkHovered, setExternalLinkHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const isLinkedInURL = isPersonOrCompanyLinkedInUrl(foundEntity.pageUrl);
  const externalLinkToShow =
    isLinkedInURL ?
      `/${parsePersonOrCompanyLinkedInProfile(foundEntity.pageUrl)}`
    : prettyUrl(foundEntity.pageUrl);

  const { data: cardData } = useCachedAPI(API.getCardData, "getCardData", () => {
    return actualEntity ? ([{ entityId: actualEntity.id }] as [{ entityId: string }]) : false;
  });

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSelect(foundEntity.entityUrl);
    } catch (e) {
      logger.error("Error resolving entity", e);
    } finally {
      setLoading(false);
    }
    return;
  };

  return (
    <div
      className={twMerge(
        "flex gap-3 py-2 items-center",
        externalLinkHovered ? "" : "group hover:cursor-pointer",
      )}
      onClick={!loading ? handleClick : undefined}
    >
      <EntityIconWithPlaceholder
        className="h-12 w-12 flex-shrink-0"
        entity={{ id: foundEntity.pageUrl, url: foundEntity.entityUrl, ...foundEntity }}
      />
      <div className="flex flex-1 flex-col items-start content-start justify-start ">
        <h3
          className={twJoin(
            "text-sm font-medium group-hover:underline  mr-2 flex-grow-0 flex-shrink-0 line-clamp-1 text-ellipsis",
            actualEntity ? "text-brand-700" : "text-gray-900",
          )}
          data-tooltip-content={actualEntity ? "Has Distill Profile" : undefined}
          data-tooltip-id="tooltip"
        >
          {smartTruncate(foundEntity.name, 40)}
          {foundEntity.degree && <span className="ml-1">&bull; {foundEntity.degree}</span>}
        </h3>
        <div className="text-sm text-gray-500 whitespace-pre-wrap flex-grow-0 flex-shrink-0 max-h-full overflow-hidden line-clamp-1 text-ellipsis">
          {getSearchRowDescription(foundEntity, cardData)}
        </div>
      </div>
      <div className=" justify-center sm:justify-start pr-4 flex flex-row whitespace-nowrap  min-w-10 flex-grow-0 flex-shrink-0 overflow-hidden">
        {loading ?
          <Loader />
        : <span
            className="inline-flex items-center text-gray-700 rounded-full text-xs font-medium hover:text-brand-600 hover:underline"
            rel="noreferrer"
            onMouseOver={(e) => {
              e.stopPropagation();
              setExternalLinkHovered(true);
            }}
            onMouseOut={() => setExternalLinkHovered(false)}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.open(foundEntity.pageUrl, "_blank");
            }}
          >
            {isLinkedInURL && <LinkedInIcon className="h-4 w-4 mr-1 flex-shrink-0 self-center" />}
            <span className="hidden sm:inline">{smartTruncate(externalLinkToShow, 10)}</span>
            {!isLinkedInURL && <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 flex-shrink-0" />}
          </span>
        }
      </div>
    </div>
  );
}

export default React.memo(AssociateSearchResultRow);
