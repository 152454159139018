const oldFaviconPerSize: Record<string, string | null> = {};
let faviconIsLoader = false;

export function replaceFaviconsWithLoader() {
  if (faviconIsLoader) return;
  faviconIsLoader = true;
  const favicons = document.querySelectorAll("link[rel='icon']");
  favicons.forEach((favicon) => {
    const size = favicon.getAttribute("sizes") || "";
    oldFaviconPerSize[size] = favicon.getAttribute("href");
    favicon.setAttribute("href", "/favicon-progress.png");
  });
}

export function resetFavicons() {
  if (!faviconIsLoader) return;
  faviconIsLoader = false;
  const favicons = document.querySelectorAll("link[rel='icon']");
  favicons.forEach((favicon) => {
    const size = favicon.getAttribute("sizes") || "";
    favicon.setAttribute("href", oldFaviconPerSize[size] || "");
  });
}

export function addToSearchParams(
  searchParams: URLSearchParams,
  params: Record<string, string | number>,
) {
  const newSearchParams = new URLSearchParams(searchParams.toString());
  Object.entries(params).forEach(([key, value]) => {
    newSearchParams.set(key, value.toString());
  });
  return newSearchParams.toString();
}
