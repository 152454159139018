import API from "@/client/api";
import { transforms } from "@/entities/transforms";
import { debounce, DebounceStyle } from "@/lib/debounce";
import { logger } from "@/lib/logger";
import { FoundEntity } from "@/stores/searchStore";
import { CompanyCardData, Entity, GenericProfile, PersonCardData } from "@/types";
import { useCallback, useEffect, useState } from "react";

export type SearchEntity = {
  entity?: Entity;
  cardData?: PersonCardData | CompanyCardData;
};

export const useSearchCache = (type: string, entities: FoundEntity[]) => {
  const [resolveEntityCache, setResolveEntityCache] = useState<Record<string, SearchEntity>>({});

  useEffect(() => {
    debounce(
      "search-cache-" + type,
      () => {
        const urls = entities
          .map((entity) => (resolveEntityCache[entity.entityUrl] ? null : entity.entityUrl))
          .filter(Boolean) as string[];
        if (urls.length === 0) {
          return;
        }
        // check for the existence of these entities
        void API.resolveAllEntities({ queries: urls, skipAttributes: true }).then((resolved) => {
          const newCache = { ...resolveEntityCache };
          urls.forEach((url) => {
            newCache[url] = { entity: resolved[url] };
          });
          setResolveEntityCache(newCache);
        });
      },
      500,
      DebounceStyle.QUEUE_LAST,
    );
    // don't re-run when resolveEntityCache changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, entities]);

  const uniqueEntities = deduplicateSearchEntities(entities, resolveEntityCache);

  return { uniqueEntities, resolveEntityCache };
};

// Helper function to deduplicate entities
function deduplicateSearchEntities(
  entities: FoundEntity[],
  resolveEntityCache: Record<string, SearchEntity>,
): FoundEntity[] {
  const seenIds = new Set<string>();
  const seenPageUrls = new Set<string>();
  const seenEntityUrls = new Set<string>();
  const uniqueEntities: FoundEntity[] = [];

  entities.forEach((entity) => {
    let unique = true;

    // Check for duplicate page URLs
    if (entity.pageUrl && seenPageUrls.has(entity.pageUrl)) {
      unique = false;
    }

    // Check for duplicate entity URLs and IDs
    if (entity.entityUrl) {
      if (seenEntityUrls.has(entity.entityUrl)) {
        unique = false;
      }

      const actualEntity = resolveEntityCache[entity.entityUrl]?.entity;
      if (actualEntity?.id && seenIds.has(actualEntity.id)) {
        unique = false;
      }
    }

    if (unique) {
      // Add to tracking sets
      if (entity.pageUrl) seenPageUrls.add(entity.pageUrl);
      if (entity.entityUrl) seenEntityUrls.add(entity.entityUrl);
      const entityId = resolveEntityCache[entity.entityUrl]?.entity?.id;
      if (entityId && !seenIds.has(entityId)) {
        seenIds.add(entityId);
      }
      uniqueEntities.push(entity);
    }
  });

  return uniqueEntities;
}
