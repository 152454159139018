import { atom } from "nanostores";

import API from "@/client/api";
import { uiStore } from "@/stores/uiStore";
import { Entity, ProfileFeedback } from "@/types";
import { FeedbackCategory, ProfileProblem } from "@prisma/client";

class FeedbackStore {
  // for leaving feedback on a profile

  entity = atom<Entity | undefined>(undefined);

  dogfoodFeedback = atom<ProfileFeedback | undefined>(undefined);

  categories = atom<FeedbackCategory[]>([]);

  // --- actions

  load = async (entity: Entity) => {
    if (!uiStore.showDevTools()) return;

    this.dogfoodFeedback.set(undefined);
    this.categories.set([]);
    this.entity.set(entity);

    await Promise.all([this.loadDogfoodFeedback(entity), this.loadCategories()]);
  };

  loadDogfoodFeedback = async (entity: Entity) => {
    const feedbacks = await API.getDogfoodFeedbacks({ entityId: entity.id });
    if (feedbacks && feedbacks.length) {
      this.dogfoodFeedback.set(feedbacks[0]);
    }
  };

  loadCategories = async () => {
    const issueCategories = await API.feedbackCategories.list();
    this.categories.set(issueCategories);
  };

  saveDogfoodFeedback = async () => {
    const user = uiStore.user.get();
    const feedback = this.dogfoodFeedback.get();
    const entity = this.entity.get();
    if (!user || !feedback || !entity) return;

    await API.createDogfoodFeedback({
      entityId: entity.id,
      userId: user.id,
      response: feedback.response,
      feedbackCategoryIds: feedback.feedbackCategories?.map((issueCategory) => issueCategory.id),
    });
  };
}

export const feedbackStore = new FeedbackStore();
