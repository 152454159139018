import CompanyStats from "@/components/companies/CompanyStats";
import ShowPipelineStatus from "@/components/entities/ShowPipelineStatus";
import PastExperiences from "@/components/experiences/PastExperiences";
import AboutSection from "@/components/sections/AboutSection";
import AuthoredMedia from "@/components/sections/AuthoredMedia";
import CompanyFunding from "@/components/sections/CompanyFunding";
import CompanyPeopleSection from "@/components/sections/CompanyPeopleSection";
import EntityHeader from "@/components/sections/EntityHeader";
import MediaCoverage from "@/components/sections/MediaCoverage";
import MutualConnections from "@/components/sections/MutualConnections";
import NotableLinks from "@/components/sections/NotableLinks";
import SocialProfiles from "@/components/sections/SocialProfiles";
import Sources from "@/components/sections/Sources";
import { EntityLayoutContext, useEntityStore } from "@/stores/entityStore";
import { EntityType, EntityUIType } from "@/types";
import { useStore } from "@nanostores/react";

export default function EntityContent() {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const status = useStore(entityStore.initialStatus);
  return (
    <>
      <EntityHeader status={status} />

      <ShowPipelineStatus status={status} />
      <AboutSection />
      {entity.type === EntityType.Person && <MutualConnections />}
      {entity.type === EntityType.Company && <CompanyStats />}
      {entity.type === EntityType.Company && <CompanyPeopleSection />}
      {entity.type === EntityType.Company && <CompanyFunding />}
      {entity.type === EntityType.Person && <AuthoredMedia />}
      <MediaCoverage />
      {entity.type === EntityType.Person && <PastExperiences status={status} />}
      <SocialProfiles />
      <NotableLinks />
      <Sources />
    </>
  );
}
