// if you modify this, also modify extractContent.ets
/* @deprecated */
export enum ContentTag {
  BasicInfo = "basic_info",
  FoundersExecs = "founders_execs",
  History = "history",
  Industry = "industry",
  ProductInfo = "product_info",
  Services = "services",
  ProductUpdates = "product_updates",
  Metrics = "metrics",
  Funding = "funding",
  Competitors = "competitors",
  Customers = "customers",
  InTheNews = "in_the_news",
  Reviews = "reviews",
  CompanyNews = "company_news",
  Technology = "technology",
  SocialMedia = "social_media",
  Other = "other",
}
/* @deprecated */
export const OrderedContentSections = [
  ContentTag.BasicInfo,
  ContentTag.SocialMedia,
  ContentTag.FoundersExecs,
  ContentTag.History,
  ContentTag.Industry,
  ContentTag.ProductInfo,
  ContentTag.Services,
  ContentTag.ProductUpdates,
  ContentTag.Metrics,
  ContentTag.Funding,
  ContentTag.Competitors,
  ContentTag.Customers,
  ContentTag.InTheNews,
  ContentTag.Reviews,
  ContentTag.CompanyNews,
  ContentTag.Technology,
  ContentTag.Other,
];
