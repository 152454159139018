import ProfileBubble from "@/components/sections/ProfileBubble";
import ProfileSummary from "@/components/start/ProfileSummary";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { CompanyFact } from "@/models/facts/facts.types";
import { CompanyCompanyRelationship } from "@/models/relationship/relationshipTypes";
import { useEntityStore } from "@/stores/entityStore";
import { Entity, EntityType, EntityWithAttributes, ProfilePageSection } from "@/types";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

type Props = {};

function FundingTimeline({ fundingData }: { fundingData: { date: string; amount: number }[] }) {
  return (
    <div className="mb-2">
      {fundingData.length > 0 && (
        <>
          <h3 className="text-md font-medium mb-2">Timeline</h3>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={fundingData} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
              <XAxis
                dataKey="date"
                tickFormatter={(tick = "") => tick.toString()}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value: number) => {
                  if (value >= 1000000) {
                    return `$${(value / 1000000).toFixed(value % 1000000 !== 0 ? 1 : 0)}m`;
                  } else if (value >= 1000) {
                    return `$${(value / 1000).toFixed(value % 1000 !== 0 ? 1 : 0)}k`;
                  } else {
                    return value.toString();
                  }
                }}
              />
              <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
              <CartesianGrid vertical={false} />
              <Bar dataKey="amount" fill="#8884d8" radius={[5, 5, 0, 0]} barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
}

function FundingRounds({
  roundData,
}: {
  roundData: {
    name: string;
    date: string;
    amount: number;
    leadInvestor: string;
    numInvestors: number;
    description: string;
  }[];
}) {
  return (
    <div className="mb-2">
      {roundData.length > 0 && (
        <>
          <h3 className="text-md font-medium mb-2">Rounds</h3>
          {roundData.map((round) => (
            <div key={round.name} className="mb-4">
              <div className="flex items-baseline">
                <h4 className="text-md font-semibold mr-3">{round.name}</h4>
                <span className="text-gray-400 text-xs">
                  {new Date(round.date).toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </span>
              </div>
              <div className="text-xs text-gray-400 mb-2 flex flex-col sm:flex-row sm:flex-wrap">
                <span className="mb-1 sm:mb-0">
                  <span className="mr-1">Amount raised:</span>
                  {fundingAmountString(round.amount)}
                </span>
                <span className="hidden sm:inline mx-2">•</span>
                <span className="mb-1 sm:mb-0">
                  <span className="mr-1">Lead investor:</span>
                  {round.leadInvestor}
                </span>
                <span className="hidden sm:inline mx-2">•</span>
                <span className="mb-1 sm:mb-0">
                  <span className="mr-1">Number of investors:</span>
                  {round.numInvestors}
                </span>
              </div>
              <p className="text-sm">{round.description}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

function Investors({ investors }: { investors: EntityWithAttributes[] }) {
  if (investors.length == 0) return null;
  return (
    <div>
      <h3 className="text-md font-medium mb-2">Investors</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {investors.map((investor) => (
          <Link
            key={investor.name}
            href={investor.slug ?? ""}
            className="flex items-center p-2 bg-white hover:bg-blue-100 border  border-gray-100 rounded-lg"
          >
            <div className="relative">
              <EntityIconWithPlaceholder
                entity={investor}
                className="h-[3.5rem] w-[3.5rem] shadow-inner"
                imageClassName="mx-auto max-h-[3.5rem] max-w-[3.5rem]"
              />
            </div>

            <div className="flex-1 flex flex-col overflow-hidden pl-4 pr-2">
              <div className="overflow-hidden line-clamp-1 text-ellipsis font-semibold">
                {investor.name}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default withErrorBoundary(function CompanyFunding(props: Props) {
  const entityStore = useEntityStore();
  const relationships = useStore(entityStore.relationships);

  const investorEntities = relationships
    .filter((r) => r.to && r.type == CompanyCompanyRelationship.RaisedFrom)
    .map((r) => r.to)
    .filter((entity): entity is EntityWithAttributes => entity !== null && entity !== undefined);

  const facts = useStore(entityStore.facts);
  const totalFunding = facts[CompanyFact.TotalFunding]?.value;

  if (!totalFunding || investorEntities.length == 0) return null;

  const sectionTitle = `Funding`;

  return (
    <ProfileBubble title={sectionTitle} section={ProfilePageSection.Funding}>
      {/* <FundingTimeline fundingData={fundingData} /> */}
      {/* <FundingRounds roundData={roundData} /> */}
      <Investors investors={investorEntities} />
    </ProfileBubble>
  );
});

function fundingAmountString(amount: number) {
  return amount >= 1_000_000 ?
      `$${Math.floor(amount / 1_000_000)} million`
    : `$${amount.toLocaleString()}`;
}
