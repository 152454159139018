import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};
export default function Loader({ className }: Props) {
  return (
    <div
      className={twMerge(
        "animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full",
        className,
      )}
      aria-label="loading"
    ></div>
  );
}
