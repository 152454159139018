import CompanyPeople from "@/components/people/CompanyPeople";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { CompanyFact } from "@/models/facts/facts.types";
import { useEntityStore } from "@/stores/entityStore";
import { ProfilePageSection } from "@/types";
import { useStore } from "@nanostores/react";
import { groupBy } from "lodash";
import { aggregateHeadcount, getHeadcountFromFacts } from "@/utils/facts";
import { formatNumber } from "@/lib/stringUtils";

interface Props {}

export default function CompanyPeopleSection(props: Props) {
  const entityStore = useEntityStore();
  const facts = useStore(entityStore.facts);
  const headcount = getHeadcountFromFacts(facts);

  const peopleTitle = headcount > 0 ? `People (${formatNumber(headcount)})` : "People";

  return (
    <ProfileBubble title={peopleTitle} section={ProfilePageSection.People}>
      <CompanyPeople peopleNumberStep={4} cols={2} showMoreLink={true} showMoreButton={false} />
    </ProfileBubble>
  );
}
