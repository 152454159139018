/* eslint-disable @next/next/no-img-element */
import SourceTooltipLink, {
  getReferenceDataToShowFromURL,
} from "@/components/entities/SourceTooltipLink";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { CompanyFact, FactType } from "@/models/facts/facts.types";
import { useEntityStore } from "@/stores/entityStore";
import { FactPick, FactsPick, GenericProfile, ProfilePageSection } from "@/types";
import { formatValue, getLabel } from "@/utils/facts";
import { useStore } from "@nanostores/react";
import { startCase, toLower, uniq } from "lodash";

export function buildStatsToShow(facts: FactsPick) {
  const statsToShow: FactType[] = [];
  if (facts[CompanyFact.Employees]) {
    statsToShow.push(CompanyFact.Employees);
  } else if (facts[CompanyFact.CompanySize]) {
    // employees is more fine-grained than company size
    statsToShow.push(CompanyFact.CompanySize);
  }
  // bandage fix for crb issue
  if (facts[CompanyFact.TotalFunding]) {
    const fundingValue = String(facts[CompanyFact.TotalFunding]?.value);
    const dollarCount = (fundingValue.match(/\$/g) || []).length;
    if (dollarCount === 1) {
      statsToShow.push(CompanyFact.TotalFunding);
    }
  }

  const factTypes = [
    CompanyFact.FoundedYear,
    CompanyFact.NumExits,
    CompanyFact.NumFundsRaised,
    CompanyFact.NumInvestments,
    CompanyFact.TotalFundsRaisedAmt,
    CompanyFact.LatestFundSize,
    CompanyFact.LatestFundDate,
    CompanyFact.AssetsUnderManagement,
    CompanyFact.InvestmentRounds,
    CompanyFact.DoLeadInvestments,
    CompanyFact.InvestmentSectors,
    CompanyFact.InvestmentGeographies,
    CompanyFact.RecentInvestments,
    CompanyFact.FamousInvestments,
    CompanyFact.State,
  ];

  for (const factType of factTypes) {
    if (facts[factType]) {
      statsToShow.push(factType);
    }
  }

  return statsToShow;
}

export default withErrorBoundary(function CompanyStats() {
  const entityStore = useEntityStore();
  const snapshot = useStore(entityStore.snapshot);

  if (!snapshot.facts) return null;
  const facts = snapshot.facts;

  const statsToShow = buildStatsToShow(facts);

  if (!statsToShow.length) {
    return null;
  }

  return (
    <ProfileBubble title="Details" section={ProfilePageSection.Stats}>
      <div className="flex flex-wrap justify-between">
        {statsToShow.map((type) => (
          <StatsBubble key={type} fact={facts[type]} type={type} snapshot={snapshot} />
        ))}
      </div>
    </ProfileBubble>
  );
});

function StatsBubble<T extends FactType>({
  fact,
  type,
  snapshot,
}: {
  fact: FactPick<T> | undefined;
  type: T;
  snapshot: GenericProfile;
}) {
  if (!fact) return null;
  if (type == CompanyFact.State && fact.value == "POSSIBLY_INACTIVE") return null;

  const label = getLabel(type);
  const value = formatValue(type, fact.value);
  const sourceReference = getReferenceDataToShowFromURL(snapshot, fact.sourceUrl || undefined);
  if (!value || (Array.isArray(value) && value.length === 0)) return null;

  return (
    <div className="flex w-full lg:w-[45%] border-b-[1px] py-2 items-center">
      <div className="flex w-full flex-row items-center justify-between text-center">
        <div className="mt-1 text-md self-baseline">{label}</div>
        <div className="flex max-w-[50%] flex-wrap gap-x-1 justify-end font-semibold">
          {Array.isArray(value) ?
            uniq(
              value.map((val) =>
                typeof val === "object" ? JSON.stringify(val) : startCase(toLower(val)),
              ),
            ).map((val, index) => (
              <div
                key={index}
                className="text-sm leading-2 whitespace-nowrap overflow-hidden text-ellipsis"
              >
                {index > 0 && <span> • </span>}
                {val}
              </div>
            ))
          : <div className="text-sm">{value}</div>}
          {sourceReference && (
            <>
              <SourceTooltipLink title={sourceReference.url} href={sourceReference.citationUrl}>
                {sourceReference.idx}
              </SourceTooltipLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
