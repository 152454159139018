import defaultErrorTracker, { ErrorTracker } from "@/lib/errorTracker";
import { useRollbar } from "@rollbar/react";
import { useEffect, useState } from "react";

export const useErrorTracker = () => {
  const rollbar = useRollbar();
  const [errorTracker, setErrorTracker] = useState<ErrorTracker>(
    new ErrorTracker(defaultErrorTracker),
  );
  useEffect(() => {
    const result = new ErrorTracker(defaultErrorTracker);
    result.rollbar = rollbar;
    setErrorTracker(result);
  }, [rollbar]);
  return errorTracker;
};
