import { useRouter } from "next/router";
import { useCallback, useRef, useState } from "react";

import API from "@/client/api";
import AutocompleteBox, { AutocompleteVariant } from "@/components/inputs/AutocompleteBox";
import { debounce, DebounceStyle } from "@/lib/debounce";
import { uiStore } from "@/stores/uiStore";
import { AutocompleteEntity, EntityType, FeatureFlag } from "@/types";
import { entityUrl } from "@/utils/entityUtils";
import { useStore } from "@nanostores/react";
import { flatten, startCase, zip } from "lodash";
import { useFeatureFlagEnabled } from "posthog-js/react";

export default function SearchBox({
  initialQuery,
  className,
  inputClassName,
  placeholder = "Type a name or url",
  magnifyClassName,
  onSelect,
  type,
  cantFindPrefix,
  onlyDistillEntities,
}: {
  initialQuery?: string;
  submitButton?: boolean;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  magnifyClassName?: string;
  onSelect?: (e: AutocompleteEntity) => void;
  type?: EntityType;
  cantFindPrefix?: string;
  // If set to true, only distill entities will be shown in the autocomplete
  // Otherwise other search engines might be used
  onlyDistillEntities?: boolean;
}) {
  const [suggestedItems, setSuggestedItems] = useState<AutocompleteEntity[]>([]);
  const [suggestedItemsFromLI, setSuggestedItemsFromLI] = useState<AutocompleteEntity[]>([]);
  const betaMode = useFeatureFlagEnabled(FeatureFlag.BetaUI);
  const focusSearchBar = useStore(uiStore.autofocusSearchBar);
  const router = useRouter();
  const DEFAULT_INPUT_CLASS_NAME =
    "bg-gray-200/50 ring-0 focus:ring-brand-300 placeholder-transparent lg:placeholder-gray-500 focus:placeholder-gray-500 focus:bg-white rounded-full pl-11";

  const navigateOnSelect = (e: AutocompleteEntity) => {
    if (!e) return;
    const query = e.url || e.name;
    if (location.pathname.endsWith("/wrongPerson")) {
      const url = new URL(location.href);
      url.searchParams.set("q", e.name);
      void router.push(url.toString());
    } else if (e.id) {
      void router.push(entityUrl(e));
    } else {
      if (new RegExp(/https?:\/\//).exec(query)) {
        // try to make an entity directly out of this url
        void router.push(`/profile?url=${encodeURIComponent(query)}`);
      } else {
        void router.push(`/search?q=${encodeURIComponent(query)}`);
      }
    }
  };

  const latestQuery = useRef("");
  // leaving this one in because it's mildly different, if your implementing typeforward use "useAutocomplete"
  const suggestItems = useCallback(
    (query: string) => {
      if (query.length < 2) {
        setSuggestedItems([]);
        setSuggestedItemsFromLI([]);
        return;
      }

      latestQuery.current = query;

      debounce(
        "autocomplete",
        () => {
          void API.autocomplete({ query, type }).then((items) => {
            // need to make sure this is the latest autocomplete call
            if (latestQuery.current === query) {
              setSuggestedItems(items.map((item) => ({ ...item, source: "api" })));
            }
          });
        },
        500,
        DebounceStyle.QUEUE_LAST,
      );
      if (betaMode && !onlyDistillEntities) {
        debounce(
          "autocomplete_li",
          () => {
            void API.liAutocomplete({ q: query }).then((items) => {
              // need to make sure this is the latest autocomplete call
              if (latestQuery.current === query) {
                setSuggestedItemsFromLI(
                  items?.map((item) => ({
                    ...item,
                    name: startCase(item.name),
                    source: "linked_in",
                  })) || [],
                );
              }
            });
          },
          500,
          DebounceStyle.QUEUE_LAST,
        );
      }
    },
    [type, betaMode],
  );

  return (
    <div className={className}>
      <AutocompleteBox
        variant={AutocompleteVariant.Entities}
        initialValue={
          initialQuery ?
            { id: "", name: initialQuery, url: "", type: EntityType.Person, imageUrl: "" }
          : undefined
        }
        items={mergeAutocompleteLists(suggestedItems, suggestedItemsFromLI)}
        onSelect={onSelect || navigateOnSelect}
        placeholder={placeholder}
        inputClassName={inputClassName || DEFAULT_INPUT_CLASS_NAME}
        onQueryChange={suggestItems}
        autoFocus={focusSearchBar}
        magnifyClassName={magnifyClassName}
        cantFindPrefix={cantFindPrefix}
      />
    </div>
  );
}

const extractImageId = (imageUrl: string) => {
  const match = imageUrl.match(/\/image\/v2\/([^/]+)\//);
  return match ? match[1] : undefined;
};

const mergeAutocompleteLists = (
  list1: AutocompleteEntity[],
  list2: AutocompleteEntity[],
): AutocompleteEntity[] => {
  const list1Urls = new Set(list1.map((item) => item.url));
  const list1ImageUrls = new Set(
    list1.map((item) => {
      return extractImageId(item.imageUrl || "");
    }),
  );
  return flatten(
    zip(
      list1,
      list2.filter((item) => {
        if (item.url && list1Urls.has(item.url)) {
          return false;
        }
        if (item.imageUrl) {
          const imageId = extractImageId(item.imageUrl);
          if (imageId && list1ImageUrls.has(imageId)) {
            return false;
          }
        }
        return true;
      }),
    ),
  ).filter((x) => x !== undefined && !!x.url) as AutocompleteEntity[];
};
