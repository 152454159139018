import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

/* eslint-disable @next/next/no-img-element */
import API from "@/client/api";
import Card from "@/components/cards/Card";
import { shouldShowCompanyWebsite } from "@/components/companies/CompanySubtitle";
import Button from "@/components/ui/Button";
import Loader from "@/components/ui/Loader";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import PopoverMenu from "@/components/ui/PopoverMenu";
import Spinner from "@/components/ui/Spinner";
import { useCachedAPI } from "@/hooks/swr";
import { useEntitySidebarClick } from "@/hooks/useEntitySidebarClick";
import { logger } from "@/lib/logger";
import {
  ageFromBirthYear,
  formatNumber,
  pluralize,
  prettyUrl,
  smartTruncate,
} from "@/lib/stringUtils";
import { MutualConnectionsData } from "@/stores/entityStore";
import { CompanyCardData, Entity, EntityType, FeatureFlag, PersonCardData } from "@/types";
import { entityUrl } from "@/utils/entityUtils";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { capitalize } from "lodash";

// The degree of connection of this entity to the user is not currently
// available directly in the database, but is sometimes available through
// indirect means (e.g. when showing mutual connections). If it's available
// we might want to show it somehow.
export type EntityForCard = Partial<
  Pick<Entity, "id" | "type" | "imageUrl" | "url" | "name" | "title">
> &
  Pick<Entity, "type"> & { degree?: string };

export type EntityCardProps = {
  entity: EntityForCard;
  // Email will be shown for EntityType.Person if provided, otherwise
  // more facts will take up the space.
  email?: string;
  loading?: boolean;
  border?: boolean;
  entityIconClassName?: string;
  entityDetailsClassName?: string;
  onClick?: (e: React.MouseEvent) => void;
  contextMenu?: React.JSX.Element | boolean;
  actionButton?: React.JSX.Element;
};

export default function EntityCard({
  border = true,
  entity: originalEntity,
  email,
  contextMenu,
  actionButton,
  loading,
  entityIconClassName,
  entityDetailsClassName,
  onClick,
}: EntityCardProps) {
  const [entity, setEntity] = useState<EntityForCard>(originalEntity);

  const onCardClick = useEntitySidebarClick(entity.id, entity.url, onClick);

  const { data: cardData, isLoading: factsLoading } = useCachedAPI(
    API.getCardData,
    "getCardData",
    () => {
      return originalEntity.id ?
          ([{ entityId: originalEntity.id }] as [{ entityId: string }])
        : false;
    },
  );

  useEffect(() => {
    if (cardData) {
      setEntity({ ...originalEntity, ...cardData.entity } as EntityForCard);
    }
    if (!originalEntity.id) {
      setEntity({
        type: originalEntity.type,
        name: originalEntity.name,
      } as EntityForCard);
    }
  }, [cardData, originalEntity]);

  if (contextMenu && actionButton) {
    throw new Error("Cannot have both contextMenu and actionButton");
  }

  const personCardData =
    entity.type === EntityType.Person ? (cardData as PersonCardData) : undefined;
  const companyCardData =
    entity.type === EntityType.Company ? (cardData as CompanyCardData) : undefined;

  const buttonLabel =
    loading ?
      <Loader className="" />
    : <EllipsisVerticalIcon className="w-4 text-gray-400 ml-auto " />;

  const TwoFacts = ({ fact1, fact2 }: { fact1?: string; fact2?: string }) => {
    // In a flex context this means: starting with 0px grow this element as much as other elements,
    // but never make it larger than necessary. This means that if two facts are ellipsised, they
    // will be equal size, but if only one is, the other one will take as little space
    // as possible.
    const factClass =
      "overflow-hidden text-ellipsis text-nowrap flex-shrink-1 grow basis-0 max-w-[fit-content]";

    if (!fact1 && !fact2) return null;

    return (
      <div className="flex flex-row justify-start">
        {fact1 && <div className={factClass}>{fact1}</div>}
        {fact1 && fact2 && <div className="flex-shrink-0">&nbsp;•&nbsp;</div>}
        {fact2 && <div className={factClass}>{fact2}</div>}
      </div>
    );
  };

  return (
    <Card border={border} onClick={onCardClick} href={entity.id ? entityUrl(entity) : undefined}>
      {contextMenu && (
        <PopoverMenu
          buttonClass="absolute top-2 right-2"
          buttonLabel={buttonLabel}
          popperOptions={{ placement: "bottom-end" }}
        >
          {contextMenu}
        </PopoverMenu>
      )}
      {actionButton && <div className="absolute top-2 right-2">{actionButton}</div>}
      {!contextMenu && loading && <div className="absolute top-2 right-2">{buttonLabel}</div>}

      <div className="flex items-center gap-4 max-h-full">
        {!loading && (
          <EntityIconWithPlaceholder
            className={twMerge(
              "h-[80px] w-[80px] shadow-inner overflow-hidden",
              entityIconClassName,
            )}
            imageClassName="w-full h-full"
            entity={entity}
          />
        )}

        <div className="flex-1 flex flex-col overflow-hidden text-xs">
          <div
            className={twMerge(
              "overflow-hidden line-clamp-1 text-ellipsis mb-1 font-semibold pr-4",
              entityDetailsClassName,
            )}
          >
            {entity.name}
          </div>
          {email && email !== entity.name && (
            <div
              className={twMerge("text-gray-500 line-clamp-1 text-ellipsis overflow-hidden mb-1")}
            >
              {email}
            </div>
          )}
          {email && !entity.id && !loading && (
            <Button className="justify-center mt-2">Add profile</Button>
          )}
          {factsLoading && <Spinner />}
          {entity.type === EntityType.Person && !factsLoading && (
            <TwoFacts
              fact1={personCardData?.currentPosition || entity.title || ""}
              fact2={personCardData?.currentCompany}
            />
          )}
          {entity.type === EntityType.Person && !factsLoading && (
            <TwoFacts
              fact1={personCardData?.location}
              fact2={
                personCardData?.directlyConnected || entity.degree === "1st" ? "Connected"
                : !!personCardData?.mutuals ?
                  pluralize(personCardData?.mutuals || 0, "Mutual")
                : entity.degree === "2nd" ?
                  "2nd degree connection"
                : ""
              }
            />
          )}
          {entity.type === EntityType.Person && !email && (
            <TwoFacts
              fact1={capitalize(ageFromBirthYear(personCardData?.birthYear) || "")}
              fact2={personCardData?.pronouns}
            />
          )}

          {entity.type === EntityType.Person && !email && !personCardData && !factsLoading && (
            <TwoFacts fact1={"(click for more details)"} />
          )}
          {entity.type === EntityType.Company && (
            <TwoFacts
              fact1={
                companyCardData?.mutuals ? pluralize(companyCardData?.mutuals || 0, "Mutual") : ""
              }
              fact2={
                companyCardData?.employees ?
                  formatNumber(companyCardData?.employees || 0) + " People"
                : ""
              }
            />
          )}
          {entity.type === EntityType.Company && (
            <TwoFacts
              fact1={companyCardData?.location}
              fact2={companyCardData?.founded ? `Founded ${companyCardData?.founded}` : ""}
            />
          )}
          {entity.type === EntityType.Company &&
            companyCardData?.website &&
            shouldShowCompanyWebsite(companyCardData?.website) && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(companyCardData?.website, "_blank");
                }}
                className="flex flex-row text-gray-500 hover:underline mt-2"
              >
                <div className="overflow-hidden text-ellipsis text-nowrap">
                  {prettyUrl(companyCardData?.website)}
                </div>
                <ArrowTopRightOnSquareIcon className="h-3 w-3 self-center ml-1 flex-shrink-0" />
              </div>
            )}
        </div>
      </div>
    </Card>
  );
}

export const makeEntityCardFromMutualConnection = (
  connection: MutualConnectionsData,
): EntityForCard => {
  const makeEntityUrl = (connection: MutualConnectionsData) => {
    if (!connection) {
      return undefined;
    }
    return connection.data?.linkedinUrl;
  };

  const entity: Partial<Entity> = connection.entity || {};

  return {
    ...entity,
    type: EntityType.Person,
    name: entity.name || connection?.data?.name || "",
    url: entity.url || makeEntityUrl(connection),
    imageUrl:
      entity.imageUrl?.startsWith("http") ? entity.imageUrl
      : connection?.data?.profileImage?.startsWith("http") ? connection?.data?.profileImage
      : undefined,
    degree: connection?.data?.degree,
  } as EntityForCard;
};
