import Image from "next/image";
import { HTMLAttributes } from "react";

export default function Spinner({
  size = 24,
  light = false,
  ...rest
}: { size?: number; light?: boolean } & HTMLAttributes<HTMLDivElement>) {
  // Use light style for dark backgrounds like brand-600 etc.
  const image = light ? "/images/spinner-light.svg" : "/images/spinner.svg";

  return (
    <div {...rest}>
      <Image
        src={image}
        width={size}
        height={size}
        alt="Progress spinner"
        priority
        aria-hidden="true"
        className="animate-spin"
      />
    </div>
  );
}
