Object.defineProperty(Error.prototype, "toJSON", {
  value: function () {
    const self = this as Error;
    // This allows for errors to be formatted nicely in JSON from the logger
    // or JSON.stringify(error).
    return {
      name: self.constructor?.name || self.name,
      message: self.message?.substring(0, 500),
      cause: self.cause,
      stack: self.stack
        ?.replace(
          // remove redundant first line in stacktrace
          new RegExp(
            String.raw`^${self.name}: ${self.message?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}\n\s*at\s*`,
          ),
          "",
        )
        .split(/\n\s*/)
        .map((line: string) => line.replace(/^at /, "")),
    };
  },
  configurable: true,
  writable: true,
});

// Claude says we should explicitly set up the base error class
// Uses Object.setPrototypeOf to ensure proper inheritance from Error,
// fixing the prototype chain for correct instanceof behavior.
export class CrawlError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class CookieError extends CrawlError {}
export class LinkedinCrawlError extends CrawlError {}
export class RetriesExhaustedCrawlError extends CrawlError {}
export class FallbackCrawlError extends CrawlError {}
export class LambdaCrawlError extends CrawlError {}
export class LambdaRetriesExhaustedCrawlError extends LambdaCrawlError {}

export class UserVisibleError extends Error {}
