import EntityCard, {
  EntityForCard,
  makeEntityCardFromMutualConnection,
} from "@/components/cards/EntityCard";
import FakeEntityCardSearchForMore from "@/components/cards/FakeEntityCardSearchForMore";
import Button, { ButtonLink, ButtonVariant } from "@/components/ui/Button";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import TabBar from "@/components/ui/TabBar";
import { pluralize } from "@/lib/stringUtils";
import {
  PersonCompanyRelationship,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { MutualConnectionsData, useEntityStore } from "@/stores/entityStore";
import { Entity } from "@/types";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { JsonObject } from "@prisma/client/runtime/library";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import Spinner from "@/components/ui/Spinner";
import { getHeadcountFromFacts } from "@/utils/facts";

const relationshipToEntity = (
  relationship: RelationshipWithEntity,
  resolveCache: Record<string, Entity>,
) => {
  return {
    ...resolveCache[relationship.fromId],
    title: (relationship.data as JsonObject)?.title ?? resolveCache[relationship.fromId].title,
  } as EntityForCard;
};

const CURRENT_TAB = "current";
const MUTUALS_TAB = "mutuals";
const FORMER_TAB = "former";

interface Props {
  // If true will show a link that takes you to /people page
  showMoreLink?: boolean;
  // If true will show a button that lets you show more connections
  showMoreButton?: boolean;
  peopleNumberStep: number;
  // Sadly tailwind does not support dynamic grid-cols
  cols: 2 | 3;
}

export default withErrorBoundary(function CompanyPeople({
  showMoreLink,
  showMoreButton,
  peopleNumberStep,
  cols,
}: Props) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const relationships = useStore(entityStore.relationships);
  const linkedEntities = useStore(entityStore.linkedEntities);
  const facts = useStore(entityStore.facts);
  const mutualConnections = useStore(entityStore.mutualConnections);
  const entityLoading = useStore(entityStore.entityLoading);
  const [selectedTab, setSelectedTab] = useState(0);
  // How many times "Load more" has been clicked
  const [loadMoreClicked, setLoadMoreClicked] = useState(0);
  const headcount = getHeadcountFromFacts(facts);

  if (!relationships.length && !mutualConnections.length) {
    if (entityLoading) {
      return <Spinner />;
    } else {
      return null;
    }
  }

  const currentlyWorkingRelationships = relationships.filter(
    (relationship) =>
      (!relationship.endedDate || relationship.endedDate === "Present") &&
      relationship.type === PersonCompanyRelationship.WorkedAt &&
      relationship.toId === entity.id &&
      !!relationship.fromId,
  );
  const currentPeopleIds = new Set(currentlyWorkingRelationships.map((r) => r.fromId));
  const formerWorkingRelationships = relationships.filter(
    (relationship) =>
      !!relationship.endedDate &&
      relationship.endedDate !== "Present" &&
      relationship.type === PersonCompanyRelationship.WorkedAt &&
      relationship.toId === entity.id &&
      !!relationship.fromId &&
      !currentPeopleIds.has(relationship.fromId),
  );
  const formerPeopleIds = new Set(formerWorkingRelationships.map((r) => r.fromId));
  const importantPeople: EntityForCard[] = currentlyWorkingRelationships
    .filter((relationship) => (relationship.data as JsonObject)?.isImportant)
    .map((relationship) => relationshipToEntity(relationship, linkedEntities));
  const unimportantPeople: EntityForCard[] = currentlyWorkingRelationships
    .filter((relationship) => !(relationship.data as JsonObject)?.isImportant)
    .map((relationship) => relationshipToEntity(relationship, linkedEntities));
  const connectedPeople = Object.values(mutualConnections)
    .filter(
      (connection) =>
        (connection?.data?.isCurrentEmployee ||
          connection?.data?.isCurrentEmployee === undefined) &&
        !(connection?.entity?.id && formerPeopleIds.has(connection.entity.id)),
    )
    .toSorted(compareConnection);
  const directlyConnectedPeople = connectedPeople.filter((c) => c.data?.degree === "1st");
  const indirectlyConnectedPeople = connectedPeople.filter((c) => c.data?.degree !== "1st");
  const directlyConnectedPeopleCards = directlyConnectedPeople.map((connection) => {
    return makeEntityCardFromMutualConnection(connection);
  });
  const indirectlyConnectedPeopleCards = indirectlyConnectedPeople.map((connection) => {
    return makeEntityCardFromMutualConnection(connection);
  });
  const formerConnectedPeople = Object.values(mutualConnections)
    .filter(
      (connection) =>
        connection?.data?.isCurrentEmployee === false &&
        !(connection?.entity?.id && currentPeopleIds.has(connection.entity.id)),
    )
    .toSorted(compareConnection);
  const formerDirectlyConnectedPeople = formerConnectedPeople.filter(
    (c) => c.data?.degree === "1st",
  );
  const formerIndirectlyConnectedPeople = formerConnectedPeople.filter(
    (c) => c.data?.degree !== "1st",
  );
  const formerDirectlyConnectedPeopleCards = formerDirectlyConnectedPeople.map((connection) => {
    return makeEntityCardFromMutualConnection(connection);
  });
  const formerIndirectlyConnectedPeopleCards = formerIndirectlyConnectedPeople.map((connection) => {
    return makeEntityCardFromMutualConnection(connection);
  });
  const formerPeople = formerWorkingRelationships.map((relationship) =>
    relationshipToEntity(relationship, linkedEntities),
  );

  const currentTabName =
    connectedPeople.length > 0 ? `Current (${connectedPeople.length} Mutuals)` : "Current";
  const formerTabName =
    formerConnectedPeople.length > 0 ?
      `Former (${formerConnectedPeople.length} Mutuals)`
    : "Former";
  const tabs = [
    {
      id: CURRENT_TAB,
      name: currentTabName,
      tooltip:
        pluralize(directlyConnectedPeople.length, "direct connection") +
        (connectedPeople.length > 0 ?
          ", and " +
          pluralize(indirectlyConnectedPeople.length, "person", "people") +
          " you have mutual connections with"
        : ""),
    },
    {
      id: FORMER_TAB,
      name: formerTabName,
      tooltip:
        pluralize(formerDirectlyConnectedPeople.length, "direct connection") +
        (formerConnectedPeople.length > 0 ?
          ", and " +
          pluralize(formerIndirectlyConnectedPeople.length, "person", "people") +
          " you have mutual connections with"
        : ""),
    },
  ];

  let peopleToShow: EntityForCard[] = [];
  if (tabs[selectedTab].id === CURRENT_TAB) {
    peopleToShow = importantPeople
      .concat(directlyConnectedPeopleCards)
      .concat(indirectlyConnectedPeopleCards)
      .concat(unimportantPeople);
  } else if (tabs[selectedTab].id === FORMER_TAB) {
    peopleToShow = formerDirectlyConnectedPeopleCards
      .concat(formerIndirectlyConnectedPeopleCards)
      .concat(formerPeople);
  }

  const seenIds = new Set<string>();
  const seenNames = new Set<string>();
  // undefined is used for fake cards - they should take up space and behave
  // as real cards, but their logic is different. Currently only used to
  // show "show more people" card.
  const uniquePeopleEntities: (EntityForCard | undefined)[] = [];
  for (const entity of peopleToShow) {
    if (entity.id) {
      if (!seenIds.has(entity.id)) {
        seenIds.add(entity.id);
        uniquePeopleEntities.push(entity);
      }
    } else if (entity.name) {
      // This might be a false positive, but if we have two people with
      // the same name and no id, it will be very rare for them to be two
      // different people.
      if (!seenNames.has(entity.name)) {
        seenNames.add(entity.name);
        uniquePeopleEntities.push(entity);
      }
    }
  }

  // Will be set if to the difference between current headcount and people
  // we have on Distill.
  const notFoundPeople = headcount - uniquePeopleEntities.length;
  if (notFoundPeople > 0) {
    // Add a fake card to show the user that there are more people to be found
    uniquePeopleEntities.push(undefined);
  }

  if (peopleToShow.length === 0 && formerPeople.length === 0 && headcount === 0) {
    if (entityLoading) {
      return <Spinner />;
    } else {
      return null;
    }
  }

  const peopleEntities = uniquePeopleEntities.slice(0, peopleNumberStep * (loadMoreClicked + 1));
  const isThereMore = uniquePeopleEntities.length > peopleEntities.length;

  return (
    <>
      <TabBar
        tabs={tabs}
        selected={selectedTab}
        className="mb-2"
        onSelectTab={(tab) => {
          setSelectedTab(tab);
          setLoadMoreClicked(0);
        }}
      />
      <div
        className={twMerge(
          "grid grid-cols-1 gap-4",
          cols === 2 ? "md:grid-cols-2"
          : cols === 3 ? "md:grid-cols-3"
          : "",
        )}
      >
        {peopleEntities.map((person) => {
          if (!person) return null;
          return <EntityCard entity={person} key={person.id || person.name} />;
        })}
        {notFoundPeople > 0 && !isThereMore && (
          <FakeEntityCardSearchForMore notFoundNumber={notFoundPeople} companyName={entity.name} />
        )}
      </div>
      {isThereMore && showMoreLink && (
        <ButtonLink
          className="mt-2 w-full text-brand-800 font-semibold text-center"
          variant={ButtonVariant.Clear}
          href={`${entity.slug}/people`}
        >
          View more people
          <ArrowRightIcon className="w-4 h-4 ml-2 inline" />
        </ButtonLink>
      )}
      {isThereMore && showMoreButton && (
        <Button
          className="my-2 w-full text-brand-800 font-semibold"
          variant={ButtonVariant.Clear}
          onClick={() => setLoadMoreClicked(loadMoreClicked + 1)}
        >
          View more people
        </Button>
      )}
    </>
  );
});

function compareConnection(a: MutualConnectionsData, b: MutualConnectionsData) {
  const degreeA = parseInt(a.data?.degree || "") || 5;
  const degreeB = parseInt(b.data?.degree || "") || 5;
  return degreeA - degreeB;
}
