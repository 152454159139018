import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import ErrorBoundary from "@/components/ui/ErrorBoundary";

export default function ProfileBubble({
  title,
  className,
  children,
}: PropsWithChildren<{ title?: string; className?: string }>) {
  return (
    <ErrorBoundary>
      <div
        className={twMerge(
          `bg-white rounded-md border border-gray-200 p-4 text-sm mb-4 text-gray-800`,
          className,
        )}
      >
        {title && <div className="text-2xl font-semibold mb-4">{title}</div>}
        {children}
      </div>
    </ErrorBoundary>
  );
}
