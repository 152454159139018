import { Entity, Relationship } from "@prisma/client";
import {
  AcademicCapIcon,
  BeakerIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  HandRaisedIcon,
} from "@heroicons/react/20/solid";
import { CompanyType } from "@/components/experiences/utils";

type CompanyConfig = {
  name: string;
  icon: React.JSX.Element | undefined;
  type: CompanyType;
  description?: string;
  link?: string;
};
export const getCompanyConfig = ({
  workExperience,
  companyType,
  companyEntity,
}: {
  workExperience: Partial<Relationship>;
  companyType: CompanyType;
  companyEntity: Entity | undefined | null;
}): CompanyConfig => {
  let name = companyEntity?.name || workExperience.toName;
  let icon = undefined;

  switch (companyType) {
    case CompanyType.Stealth:
      if (!name) name = "Stealth Company";
      icon = <BeakerIcon className="h-4 w-4" />;
      break;
    case CompanyType.SelfEmployed:
      if (!name) name = "Self-Employed";
      icon = <BriefcaseIcon className="h-4 w-4" />;
      break;
    case CompanyType.Company:
      if (!name) name = "Company";
      break;
    case CompanyType.CareerBreak:
      if (!name) name = "Career Break";
      icon = <CalendarIcon className="h-4 w-4" />;
      break;
    case CompanyType.Education:
      if (!name) name = "Education";
      icon = <AcademicCapIcon className="h-4 w-4" />;
      break;
    case CompanyType.Investments:
      if (!name) name = "Investments";
      icon = <CurrencyDollarIcon className="h-4 w-4" />;
      break;
    case CompanyType.Volunteering:
      if (!name) name = "Volunteering";
      icon = <HandRaisedIcon className="h-4 w-4" />;
      break;
    case CompanyType.OtherExperience:
      if (!name) name = "Other Experience";
      icon = <CalendarDaysIcon className="h-4 w-4" />;
      break;
    default:
      const _exhaustiveCheck: never = companyType;
      throw new Error("Unhandled company type");
  }

  let description = companyEntity?.description ?? undefined;
  if (description == "(no description)") description = undefined;
  return { name, icon, type: companyType, description };
};
