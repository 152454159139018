import { OptionalNullable, UUIDSchema } from "@/types/typebox";
import { Type } from "@sinclair/typebox";

export const AnalyticsFilter = Type.Object({
  interval: OptionalNullable(
    Type.Union([
      Type.Literal("Day"),
      Type.Literal("Week"),
      Type.Literal("Month"),
      Type.Literal("Quarter"),
      Type.Literal("Year"),
    ]),
  ),
  startDate: OptionalNullable(Type.String()),
  endDate: OptionalNullable(Type.String()),
});

export const AnalyticsEvent = Type.Object({
  error: Type.Optional(
    Type.Object({
      message: Type.String(),
      code: Type.Optional(Type.String()),
    }),
  ),
  timestamp: Type.String(),
  uuid: UUIDSchema,
});
