import errorTracker from "@/lib/errorTracker";

import "@/styles/globals.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/700.css";

// While nextJS provides some polyfills, it does not do it for features
// introduced past a certain cut-off. This is why we need to import them.
import "core-js/features/array/to-reversed";
import "core-js/features/array/to-sorted";
import "core-js/features/array/to-spliced";

import { useEffect, useMemo } from "react";

import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";

import { Provider as RollbarProvider } from "@rollbar/react";
import posthog from "posthog-js";
import Rollbar from "rollbar";

import eventTracker from "@/client/eventTracker";
import PostHogWrapper from "@/components/analytics/PostHogWrapper";
import { SidebarProvider } from "@/components/providers/SidebarProvider";
import ErrorBoundary from "@/components/ui/ErrorBoundary";
import RouteTransition from "@/components/ui/RouteTransition";
import { createHyperDXBrowser } from "@/lib/trackers/hyperdx";
import { getPostHogApiKey, posthogClientConfig } from "@/lib/trackers/posthog";
import { clientConfig, registerLogListener } from "@/lib/trackers/rollbar";
import { GlobalSWRConfig } from "@/hooks/swr";

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  const rollbar = useMemo(() => new Rollbar(clientConfig), []);
  const hyperDX = useMemo(() => createHyperDXBrowser(), []);

  useEffect(() => {
    registerLogListener(rollbar);
    errorTracker.rollbar = rollbar;
    errorTracker.hyperDX = hyperDX;

    const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
      errorTracker.sendError(event.reason, {
        unhandledPromiseRejection: true,
      });
    };

    const errorHandler = (event: ErrorEvent) => {
      errorTracker.sendError(event.error, {
        windowError: true,
      });
    };

    window.addEventListener("unhandledrejection", unhandledRejectionHandler);
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("unhandledrejection", unhandledRejectionHandler);
      window.removeEventListener("error", errorHandler);
    };
  }, [rollbar, hyperDX]);

  const postHogApiKey = useMemo(() => getPostHogApiKey(), []);
  useEffect(() => {
    if (postHogApiKey) {
      posthog.init(postHogApiKey, posthogClientConfig);
      eventTracker.posthog = posthog;
    }
  }, [postHogApiKey]);

  return (
    <RollbarProvider config={clientConfig}>
      <PostHogWrapper posthog={posthog}>
        <ErrorBoundary>
          <RouteTransition>
            <SessionProvider session={session}>
              <GlobalSWRConfig>
                <SidebarProvider>
                  <Component {...pageProps} />
                </SidebarProvider>
              </GlobalSWRConfig>
            </SessionProvider>
          </RouteTransition>
        </ErrorBoundary>
      </PostHogWrapper>
    </RollbarProvider>
  );
}

if (process.env.NODE_ENV === "development") {
  // debugging shortcut
  global.window?.window.addEventListener("keydown", function (event) {
    if (event.key == "F6") {
      debugger;
    }
  });
}
