import { EmailIdentity, EmailResolutionStatus } from "@/types";

export enum DomainStatus {
  Resolving = "resolving",
  Resolved = "resolved",
  Invalidated = "invalidated",
}

export function isSkipped(identity: EmailIdentity) {
  return identity.status === EmailResolutionStatus.SKIPPED;
}

export function isHidden(identity: EmailIdentity, hiddenEmails: string[]) {
  return hiddenEmails.includes(identity.contact.address);
}

export interface GroupedEntry {
  domain: string;
  contacts: EmailIdentity[];
}

export const personalEmailDomain = "Personal emails";
