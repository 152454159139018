import { Router } from "next/router";
import { PostHog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";

// Set up posthog per this guide:
// https://posthog.com/docs/libraries/next-js?tab=Pages+router
export default function PostHogWrapper({
  posthog,
  children,
}: {
  posthog?: PostHog;
  children: React.ReactNode;
}) {
  useEffect(() => {
    const handleRouteChange = () => posthog?.capture("$pageview");

    Router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [posthog]);

  return posthog ? <PostHogProvider client={posthog}>{children}</PostHogProvider> : <>{children}</>;
}
