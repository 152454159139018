import { FormEvent, useEffect, useState } from "react";

import useSubmitButton from "@/components/hooks/useSubmitButton";
import TextArea from "@/components/inputs/TextArea";
import Modal, { ModalBody, ModalButton } from "@/components/modals/Modal";
import { classNames, prettyError } from "@/lib/utils";
import { useEntityStore } from "@/stores/entityStore";
import { ChevronDownIcon, ChevronRightIcon, PencilIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";

type Props = {};

export default function HighlightsFeedbackModal(props: Props) {
  const entityStore = useEntityStore();
  const inputProps = useStore(entityStore.showHighlightsFeedbackModal);
  const open = !!inputProps;
  const [error, setError] = useState<string | undefined>();
  const [isExpanded, setIsExpanded] = useState(false);

  const { SubmitButton, setSubmitting } = useSubmitButton();

  useEffect(() => {
    setError(undefined);
    setSubmitting(false);
  }, [open, setSubmitting]);
  if (!open) return null;

  const setOpen = (open: boolean) => {
    if (!open) entityStore.showHighlightsFeedbackModal.set(undefined);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    try {
      setSubmitting(true);
      await inputProps?.onSubmit(
        (form.elements.namedItem(`input0`) as HTMLInputElement).value ?? "",
      );
      setIsExpanded(false);
      setOpen(false);
    } catch (e) {
      setError(prettyError(e));
    } finally {
      setSubmitting(false);
    }
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const buttons = (
    <>
      <SubmitButton
        className={"py-2 text-sm w-full sm:w-auto justify-center bg-blue-600 hover:bg-blue-800"}
      >
        Submit
      </SubmitButton>
      <ModalButton
        onClick={() => {
          setIsExpanded(false);
          setOpen(false);
        }}
        className="bg-gray-600 hover:bg-gray-800"
      >
        Cancel
      </ModalButton>
    </>
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit}>
        <ModalBody
          title={"Edit Highlights"}
          icon={<PencilIcon className={classNames("w-6 h-6 text-blue-600")} />}
          iconBg={"bg-blue-100"}
          buttons={buttons}
          className="sm:min-w-[500px]"
        >
          <div className="mt-2 text-gray-600">
            {
              "Please list any instructions to the AI on what to not include, what to emphasise generally, specific highlights to include, and any preferences on ordering of highlights."
            }
          </div>
          <TextArea
            key={0}
            name={`input0`}
            className="w-full h-48 mt-4"
            placeholder={`Write instructions in open form, here are some examples:

• Remove the fact that I played football in high school.
• Speak more about my entrepreneurship fellowship.
• The revenue in 2024 was actually $24M.`}
          />

          {inputProps.previousFeedback && inputProps.previousFeedback.length > 0 && (
            <div className="text-sm pt-1">
              <button
                type="button"
                onClick={toggleExpand}
                className="flex items-center text-gray-900 hover:text-blue-600 pb-2"
              >
                {isExpanded ?
                  <ChevronDownIcon className="h-5 w-5 mr-2 text-blue-600" />
                : <ChevronRightIcon className="h-5 w-5 mr-2 text-blue-600" />}
                View Previous Edits
              </button>
              {isExpanded && (
                <ul className="text-gray-600 list-disc ml-6">
                  {inputProps.previousFeedback.map((feedback, index) => (
                    <li key={index}>{feedback}</li>
                  ))}
                </ul>
              )}
            </div>
          )}
          {error && <p className="text-red-600 mt-2 text-sm">{error}</p>}
        </ModalBody>
      </form>
    </Modal>
  );
}
