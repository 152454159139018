import { useEntityStore } from "@/stores/entityStore";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useStore } from "@nanostores/react";
import { Tooltip } from "react-tooltip";

export const USER_FEEDBACK_TOOLTIP_TITLE = "User-Submitted Information";

export default function SourceTooltip() {
  return (
    <Tooltip
      id="source-tooltip"
      className="bg-white border rounded z-10"
      opacity={1}
      variant="light"
      place="top"
      clickable
      render={({ content, activeAnchor }) => {
        if (!content || !activeAnchor) return null;

        return content.includes(USER_FEEDBACK_TOOLTIP_TITLE) ?
            <SourceTooltipContent href={content} noLink />
          : <SourceTooltipContent href={content} />;
      }}
    />
  );
}

export function SourceTooltipContent({ href, noLink }: { href: string; noLink?: boolean }) {
  const entityStore = useEntityStore();
  const sources = useStore(entityStore.sources);
  const source = sources.find((s) => s.url === href);

  return (
    <div className="w-72">
      <b>Source: </b>
      {noLink ?
        <span className="text-gray-500">
          {source?.title || (href.includes("linkedin.com") ? "Linkedin" : href)}
        </span>
      : <a href={href} target="_blank" className="text-blue-500" rel="noreferrer">
          {source?.title || (href.includes("linkedin.com") ? "Linkedin" : href)}
          <ArrowTopRightOnSquareIcon className="w-3 h-3 -mt-1 ml-1 inline" />
        </a>
      }
    </div>
  );
}
