import Card from "@/components/cards/Card";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { formatNumber } from "@/lib/stringUtils";

interface Props {
  notFoundNumber: number;
  companyName: string;
}

export default function FakeEntityCardSearchForMore({ notFoundNumber, companyName }: Props) {
  return (
    <Card href={`/search?q=${companyName}&type=people`} border={true}>
      <div className="flex flex-row items-center gap-4 max-h-full">
        <div
          className="h-[80px] w-[80px] text-white items-center justify-center flex overflow-hidden shrink-0 rounded"
          style={{
            background: "linear-gradient(135deg, #B7B7F6 2.81%, #4F4FE7 96.56%)",
          }}
        >
          <UserPlusIcon className="h-[50px] w-[50px]" />
        </div>

        <div className="flex flex-col">
          <div className="font-bold text-xs">
            +{formatNumber(notFoundNumber)} People Without Distill Profiles
          </div>
          <div className="text-xs mt-2">
            Use Distill&apos;s search to add people at this company to this page
          </div>
        </div>
      </div>
    </Card>
  );
}
