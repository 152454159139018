import Loader from "@/components/ui/Loader";
import Spinner from "@/components/ui/Spinner";
import errorTracker from "@/lib/errorTracker";
import { prettyError } from "@/lib/miscUtils";
import listStore from "@/stores/listStore";
import { Entity, ListEntryDetails, ListOverview } from "@/types";
import { CheckIcon, MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export type AddEntityToListProps = {
  entity: Entity;
};

export const AddEntityToList = ({ entity }: AddEntityToListProps) => {
  const listOverviews = useStore(listStore.listOverviews);
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [createdListsIds, setCreatedListsIds] = useState<string[]>([]);

  useEffect(() => {
    if (!listOverviews) {
      void listStore.loadOverviews();
    }
  }, [listOverviews]);

  const filteredLists = listOverviews?.filter((list) =>
    list.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const isCustomListName = !filteredLists?.length && searchQuery.length > 3;

  const listName = isCustomListName ? searchQuery : undefined;

  const handleCreateNewList = async () => {
    setIsCreatingList(true);
    try {
      const newList = await listStore.createList({
        name: listName,
      });
      setSearchQuery("");
      if (newList) {
        setCreatedListsIds([...createdListsIds, newList.id]);
        await listStore.addEntries(
          [
            {
              entityId: entity.id,
              type: "entity",
              data: { name: entity.name },
            },
          ],
          { listId: newList.id },
        );
      }
      await listStore.loadOverviews();
    } catch (e) {
      errorTracker.sendError(prettyError(e));
    }
    setIsCreatingList(false);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="relative my-2">
        <MagnifyingGlassIcon className="absolute w-5 h-5 text-gray-400 left-2 top-2" />
        <input
          type="text"
          className="w-full rounded-md border-0 bg-white py-1.5 pl-8 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
          placeholder="Search for or create a new list"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="flex flex-col divide-y divide-gray-200 min-h-[200px]">
        {filteredLists?.map((listOverview) => (
          <MemoizedListOption
            listOverview={listOverview}
            entity={entity}
            key={listOverview.id}
            className="animate-fadeIn"
            isCreatedList={createdListsIds.includes(listOverview.id)}
          />
        ))}
        <button
          className="flex items-center gap-2 p-2 text-brand-500 font-semibold hover:bg-brand-100 w-full"
          disabled={isCreatingList || !listOverviews}
          onClick={handleCreateNewList}
        >
          <div className="flex items-center justify-center w-10 h-10 rounded bg-brand-100">
            {isCreatingList ?
              <Spinner />
            : <PlusIcon className="w-8 h-8 text-brand-500" />}
          </div>
          New List {!!isCustomListName && `named "${listName}"`}
        </button>
      </div>
    </div>
  );
};

const ListOption = ({
  listOverview,
  entity,
  className,
  isCreatedList,
}: {
  listOverview: ListOverview;
  entity: Entity;
  className?: string;
  isCreatedList: boolean;
}) => {
  const [hasAddedEntity, setHasAddedEntity] = useState(isCreatedList);
  const [isLoading, setIsLoading] = useState(true);
  const [matchingEntries, setMatchingEntries] = useState<ListEntryDetails[]>([]);

  const checkIsInList = useCallback(async () => {
    const entries = await listStore.getEntriesByEntityId(listOverview.id, entity.id);
    if (entries && entries.length) {
      setMatchingEntries(entries);
      setHasAddedEntity(true);
    }
    setIsLoading(false);
  }, [listOverview, entity.id]);

  useEffect(() => {
    void checkIsInList();
  }, [checkIsInList]);

  const handleAddEntityToList = async () => {
    setIsLoading(true);
    try {
      const newEntries = await listStore.addEntries(
        [
          {
            entityId: entity.id,
            type: "entity",
            data: {
              name: entity.name,
            },
          },
        ],
        { listId: listOverview.id },
      );
      if (newEntries && newEntries.length) {
        setMatchingEntries(newEntries);
      }
      setHasAddedEntity(true);
    } catch (e) {
      errorTracker.sendError(prettyError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveEntityFromList = async () => {
    setIsLoading(true);
    try {
      await listStore.deleteEntries(
        matchingEntries.map((entry) => entry.id),
        listOverview.id,
      );
      setHasAddedEntity(false);
    } catch (e) {
      errorTracker.sendError(prettyError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const IconWrapper = ({ children }: { children: React.ReactNode }) => (
    <div className="w-8 h-8 animate-fadeIn duration-100">{children}</div>
  );

  const Icon = useMemo(() => {
    if (isLoading) {
      return (
        <IconWrapper>
          <Loader className="w-8 h-8" />
        </IconWrapper>
      );
    }
    if (hasAddedEntity) {
      return (
        <IconWrapper>
          <CheckIcon className="w-8 h-8 text-green-600" />
        </IconWrapper>
      );
    }
    return (
      <IconWrapper>
        <PlusIcon className="w-8 h-8 text-gray-500" />
      </IconWrapper>
    );
  }, [isLoading, hasAddedEntity]);

  return (
    <button
      onClick={hasAddedEntity ? handleRemoveEntityFromList : handleAddEntityToList}
      className={`flex flex-row p-2 items-center hover:bg-brand-100 w-full justify-between ${className}`}
      disabled={isLoading}
    >
      <div className="flex flex-col items-start justify-start content-start">
        <p className="font-semibold text-lg">{listOverview.name}</p>
        <p className="text-sm text-gray-500">
          Updated {formatDistanceToNow(listOverview.updatedAt, { addSuffix: true })}
        </p>
      </div>
      <div>{Icon}</div>
    </button>
  );
};

const MemoizedListOption = React.memo(ListOption);
