import { Attribute } from "@/types";
import { EntityWithAttributes, Meeting, UserWithMeta } from "@/types/db";
import Prisma, { EntityVisitCount } from "@prisma/client";
import { Static, Type } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";
import { GetServerSidePropsContext, Redirect } from "next";
import { Session } from "next-auth";

export const WAITLIST_URL = "https://forms.gle/1X69TMSqvoSWSCDx5";

export type PartialEntity = {
  id: string;
  name: string;
  type: string;
  url: string;
  slug?: string | null;
  subtitle?: string;
};

export type ServerProps = {
  userId?: string;
  user?: UserWithMeta;
  team?: Prisma.Team;
};

export type WorkspaceData = {
  session: Session;
  redirect: { redirect: Redirect } | null;
  cookieFragments?: CookieFragments;
  entityVisitCount?: EntityVisitCount | null;
} & ServerProps;

export type CookieFragments = { [domain: string]: string[] };

export type CookieStatus = "valid" | "invalid" | "missing";

export interface WorkspaceContext extends GetServerSidePropsContext {
  workspaceData: WorkspaceData;
}

export type SuccessResponse = {
  success: boolean;
};

export type ErrorResponse = {
  error: string;
};

export type InterruptiblePromise = { promise: Promise<void>; interrupt: () => void };

export type MeetingResponse = {
  id: string;
  meeting: Meeting;
  profile: Prisma.MeetingProfile | null;
  sections: Prisma.MeetingSection[];
  entities: Prisma.Entity[];
  attributes: Attribute[];
};

export function isMeeting(l: unknown): l is Meeting {
  const meetingCheck =
    (l as Meeting)?.id !== undefined &&
    (l as Meeting)?.title !== undefined &&
    (l as Meeting)?.start !== undefined &&
    (l as Meeting)?.calendarId !== undefined;

  return meetingCheck;
}

export type WorkplaceResolveRequest = { domain: string; personId: string };

export type WorkplaceResolveResponse = {
  results: (WorkplaceResolveRequest & { entityId?: string; needsResolving?: boolean })[];
  entities: Record<string, EntityWithAttributes>;
};

export type CompanyCheckResponse = {
  linkedinUrl?: string;
  aiCompanyClassification?: boolean;
};

const PagingOptions = Type.Object({
  page: Type.Number(),
  pageSize: Type.Optional(Type.Number()),
});

export type PagingOptions = Static<typeof PagingOptions>;

export function isPagingOptions(u: unknown): u is PagingOptions {
  return Value.Check(PagingOptions, u);
}

export function parsePagingOptions(u: unknown, options?: { strict: boolean }): PagingOptions {
  const { strict = true } = options || {};

  const result = Value.Convert(PagingOptions, u);
  if (strict) {
    Value.Assert(PagingOptions, result);
  } else if (!isPagingOptions(result)) {
    return { page: 0 };
  }
  return result;
}

export type PagingResult<T> = {
  result: T;
  total: number;
  page: number;
};

// --- smart search types

export type FieldCriteria = {
  field: QueryField | ""; //used for new filters
  include?: string[];
  exclude?: string[];
  min?: number;
  max?: number;
  exact?: boolean;
};

export type CompoundCriteria = {
  OR: FieldCriteria[];
};

export type Criteria = CompoundCriteria | FieldCriteria;
export type FilterQuery = Criteria[];

export const isCompoundCriteria = (c: Criteria): c is CompoundCriteria => {
  return "OR" in c;
};

export const isCriteria = (c: Criteria): c is FieldCriteria => {
  return "field" in c;
};

export enum PersonQueryField {
  name = "name",
  location = "location",
  age = "age",
  pronoun = "pronoun",
  currentCompanyName = "currentCompanyName",
  currentCompanyFulltext = "currentCompanyFulltext",
  currentTitle = "currentTitle",
  currentOrPreviousTitle = "currentOrPreviousTitle",
  currentOrPreviousCompanyName = "currentOrPreviousCompanyName",
  currentOrPreviousCompanyFulltext = "currentOrPreviousCompanyFulltext",
  schoolName = "schoolName",
  schoolFulltext = "schoolFulltext",
  investmentName = "investmentName",
  investmentFulltext = "investmentFulltext",
  degree = "degree",
  highlights = "highlights",
  fulltext = "fulltext",
}

export enum CompanyQueryField {
  industry = "industry",
  companyType = "companyType",
  employees = "employees",
  funding = "funding",
  founded = "founded",
  investor = "investor",
  headquarters = "headquarters",
}

export type QueryField = keyof typeof PersonQueryField | keyof typeof CompanyQueryField;

export const QueryFieldLabels: Record<QueryField, string> = {
  [PersonQueryField.name]: "Name",
  [PersonQueryField.location]: "Location",
  [PersonQueryField.age]: "Age",
  [PersonQueryField.pronoun]: "Preferred Pronouns",
  [PersonQueryField.currentCompanyName]: "Current Company Name",
  [PersonQueryField.currentCompanyFulltext]: "Current Company (full-profile)",
  [PersonQueryField.currentTitle]: "Current Title",
  [PersonQueryField.currentOrPreviousTitle]: "Current or Previous Title",
  [PersonQueryField.currentOrPreviousCompanyName]: "Current or Previous Company Name",
  [PersonQueryField.currentOrPreviousCompanyFulltext]: "Current or Previous Company (full-profile)",
  [PersonQueryField.schoolName]: "School Name",
  [PersonQueryField.schoolFulltext]: "School (full-profile)",
  [PersonQueryField.degree]: "Degree",
  [PersonQueryField.investmentName]: "Invested in (name)",
  [PersonQueryField.investmentFulltext]: "Invested in (full-profile)",
  [PersonQueryField.highlights]: "Highlights",
  [PersonQueryField.fulltext]: "Anywhere (full-profile)",
  [CompanyQueryField.industry]: "Industry",
  [CompanyQueryField.companyType]: "Company Type",
  [CompanyQueryField.employees]: "Employees",
  [CompanyQueryField.funding]: "Funding",
  [CompanyQueryField.founded]: "Founded",
  [CompanyQueryField.investor]: "Investor",
  [CompanyQueryField.headquarters]: "Headquarters",
};

export type SmartSearchResponse = {
  peopleQuery?: FilterQuery;
  companiesQuery?: FilterQuery;
  message?: string;
};

export enum BuiltinStartingSet {
  Linkedin = "linkedin",
  Everything = "everything",
  Network = "network",
}

export const SmartSearchStartingSets = [
  { id: BuiltinStartingSet.Everything, name: "All of Distill" },
  { id: BuiltinStartingSet.Linkedin, name: "My Linkedin Connections (coming soon)" },
  { id: BuiltinStartingSet.Network, name: "My Network's Connections (coming soon)" },
];
