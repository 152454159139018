import {
  DeleteablePrismaModel,
  EntityWithAttributesSchema,
  GenericProfileSchema,
  Nullable,
  PrismaModel,
} from "@/types/typebox";
import { Type } from "@sinclair/typebox";

const NullableString = Nullable(Type.String());
const OptionalNullableString = Type.Optional(NullableString);
const NullableDate = Nullable(Type.Date());

export const ListCustomFieldDefinition = Type.Union([
  Type.Object({
    id: Type.String(),
    name: Type.String(),
    type: Type.Literal("text"),
  }),
  Type.Object({ type: Type.Literal("email") }),
]);

export const ListType = Type.Union([Type.Literal("linkedin"), Type.Undefined()]);
export enum ListTypes {
  LinkedIn = "linkedin",
}

export const ListOverview = Type.Intersect([
  DeleteablePrismaModel,
  Type.Object({
    name: Type.String(),
    type: Type.Optional(ListType),
    description: NullableString,
    lastPublishedAt: NullableDate,
    customFields: Type.Array(ListCustomFieldDefinition),
    length: Type.Number(),
  }),
]);

export const ListViewFieldSelection = Type.Union([
  Type.Object({ type: Type.Literal("entity"), property: Type.String() }),
  Type.Object({ type: Type.Literal("email") }),
  Type.Object({ type: Type.Literal("custom"), id: Type.String() }),
]);

export const ListViewDisplayMode = Type.Literal("cards");

export const ListView = Type.Intersect([
  DeleteablePrismaModel,
  Type.Object({
    code: Type.String(),
    displayMode: ListViewDisplayMode,
    fields: Type.Array(ListViewFieldSelection),
    list: ListOverview,
    publishedAt: NullableDate,
    publishedByUserId: NullableString,
  }),
]);

export const ListUser = Type.Intersect([
  DeleteablePrismaModel,
  Type.Object({
    listId: Type.String(),
    role: Type.Union([Type.Literal("owner"), Type.Literal("member")]),
    userId: Type.String(),
  }),
]);

export const ListUserRole = Type.Index(ListUser, ["role"]);

export const ListDetails = Type.Intersect([
  ListOverview,
  Type.Object({
    users: Type.Array(ListUser),
    views: Type.Array(ListView),
  }),
]);

export const ListCustomFieldValue = Type.Intersect([
  PrismaModel,
  Type.Object({
    fieldId: Type.String(),
    entryId: Type.String(),
    editedByUserId: NullableString,
    data: Type.Object({
      type: Type.Literal("text"),
      value: Type.String(),
    }),
  }),
]);

export const ListEntryCustomFieldSpec = Type.Object({
  fieldId: Type.String(),
  data: Type.Union([Type.Index(ListCustomFieldValue, ["data"]), Type.Literal("delete")]),
});

export const ListEntryBaseSpec = Type.Object({
  customFields: Type.Optional(Type.Array(ListEntryCustomFieldSpec)),
  entityId: OptionalNullableString,
  order: Type.Optional(Type.Number()),
});

export const ListEntryEntitySpec = Type.Object({
  type: Type.Literal("entity"),
  data: Type.Object({
    url: OptionalNullableString,
    email: OptionalNullableString,
    name: Type.String(),
  }),
});

export const ListEntryComponentSpec = Type.Object({
  type: Type.Union([Type.Literal("section"), Type.Literal("divider")]),
  data: Type.Optional(
    Type.Object({
      title: OptionalNullableString,
      description: OptionalNullableString,
    }),
  ),
});

export const ListEntrySpec = Type.Union([
  Type.Intersect([ListEntryBaseSpec, ListEntryEntitySpec]),
  Type.Intersect([ListEntryBaseSpec, ListEntryComponentSpec]),
]);

export const ListEntryData = Type.Index(ListEntrySpec, ["data"]);

export const ListEntry = Type.Intersect([
  DeleteablePrismaModel,
  Type.Intersect([
    ListEntrySpec,
    Type.Object({
      listId: Type.String(),
      entityId: NullableString,
      addedByUserId: Type.String(),
      order: Type.Number(),
    }),
  ]),
]);

export const ListEntryDetails = Type.Intersect([
  ListEntry,
  Type.Object({
    entity: Type.Optional(EntityWithAttributesSchema),
    customFields: Type.Array(ListCustomFieldValue),
  }),
]);

export const ListEntrySnapshotCustomField = Type.Object({
  fieldId: Type.String(),
  data: Type.Object({
    type: Type.Index(ListCustomFieldDefinition, ["type"]),
    name: Type.String(),
    value: Type.String(),
  }),
});

export const ListEntrySnapshot = Type.Intersect([
  Type.Omit(ListEntry, ["customFields", "data"]),
  Type.Object({
    snapshot: Type.Optional(GenericProfileSchema),
    customFields: Type.Array(ListEntrySnapshotCustomField),
  }),
]);
