import { SidebarVariant, useSidebar } from "@/components/providers/SidebarProvider";
import { assertUnreachable } from "@/lib/miscUtils";
import { FeatureFlag } from "@/types";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useCallback } from "react";

// open the entity sidebar when clicking on an entity card
export function useEntitySidebarClick(
  entityId: string | undefined,
  entityUrl: string | undefined,
  onClickDefault?: (e: React.MouseEvent) => void,
) {
  const sidebar = useSidebar();
  const openInSidebar =
    useFeatureFlagEnabled(FeatureFlag.OpenEntitiesInSidebar) ||
    process.env.NODE_ENV === "development";

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (onClickDefault) onClickDefault(e);
      if (e.defaultPrevented) return;

      if (openInSidebar && (entityId || entityUrl)) {
        sidebar.openSidebar(
          SidebarVariant.Entity,
          entityId ? { entityId }
          : entityUrl ? { entityUrl }
          : assertUnreachable(),
        );
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [entityId, entityUrl, onClickDefault, sidebar, openInSidebar],
  );

  return onClick;
}
