/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { forwardRef, useImperativeHandle, useState } from "react";
import { twMerge } from "tailwind-merge";

import { classNames } from "@/lib/utils";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export type ComboBoxItemType = { id: string; name: string };

export function makeComboBoxItemsFromEnum(enumObject: Record<string, string>): ComboBoxItemType[] {
  return Object.entries(enumObject).map(([id, name]) => ({ id, name }));
}

export type Props<T extends ComboBoxItemType> = {
  name?: string;
  items: T[];
  value?: T | T[];
  placeholder?: string;
  className?: string;
  allowCustom?: boolean;
  inputClassName?: string;
  readOnly?: boolean;
  multiSelect?: boolean;
  onSelect: (item: T | T[]) => void;
  wrapOptionText?: boolean;
  disabled?: boolean;
};

const ComboBox = forwardRef(function ComboBox<T extends ComboBoxItemType>(
  {
    name,
    items,
    className,
    value,
    inputClassName,
    placeholder,
    allowCustom,
    readOnly,
    multiSelect,
    onSelect,
    disabled,
    wrapOptionText,
  }: Props<T>,
  ref: React.Ref<{ resetQuery?: () => void }>,
) {
  const [query, setQuery] = useState("");
  const [customItems, setCustomItems] = useState<T[]>([]);

  useImperativeHandle(
    ref,
    () => ({
      resetQuery: () => setQuery(""),
    }),
    [],
  );

  const filteredItems =
    query === "" ? items : (
      items.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
    );

  const handleSelect = (item: T, newlyEnteredCustomOption?: boolean) => {
    if (multiSelect) {
      const newValue = Array.isArray(value) ? [...value] : [];

      // Always add when a new custom option is entered.
      // This is a hack - all custom options have the same id
      const index = newlyEnteredCustomOption ? -1 : newValue.findIndex((i) => i.id === item.id);
      if (index > -1) {
        newValue.splice(index, 1);
      } else {
        newValue.push(item);
      }
      onSelect(newValue);
    } else {
      onSelect(item);
    }
  };

  const isSelected = (item: T) => {
    if (multiSelect && Array.isArray(value)) {
      return value.some((i) => i.id === item.id);
    } else {
      return (value as T)?.id === item.id;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && allowCustom && query.length > 0) {
      const newItem = { id: "custom", name: query } as T;
      setCustomItems([...customItems, newItem]);
      handleSelect(newItem, true);
      setQuery("");
    }
  };

  return (
    <Combobox as="div" onChange={handleSelect} value={value} disabled={disabled}>
      <div className={classNames("relative", className)}>
        <ComboboxButton as="div">
          <ComboboxInput
            name={name}
            className={twMerge(
              "cursor-pointer w-full rounded-md border-0 bg-white py-2 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
              inputClassName,
            )}
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            // @ts-expect-error - Combobox. type is not correct
            displayValue={(item: T | T[]) =>
              Array.isArray(item) ? item.map((i) => i.name).join(", ") : item?.name
            }
            defaultValue={Array.isArray(value) ? value.map((i) => i.name).join(", ") : value?.name}
            autoComplete="off"
            readOnly={readOnly}
          />
        </ComboboxButton>
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {allowCustom && query.length > 0 && (
            <ComboboxOption
              className={({ active }) =>
                classNames(
                  "relative cursor-default select-none py-2 pl-3 pr-9",
                  active ? "bg-indigo-600 text-white" : "text-gray-900",
                )
              }
              value={{ id: "custom", name: query }}
            >
              {query}
            </ComboboxOption>
          )}
          {[...filteredItems, ...customItems].map((item) => (
            <ComboboxOption
              key={item.id}
              value={item}
              className={({ active }) =>
                classNames(
                  "relative select-none py-2 pl-3 pr-9 cursor-pointer",
                  active ? "bg-indigo-600 text-white" : "text-gray-900",
                )
              }
            >
              {({ active }) => (
                <>
                  <span
                    className={classNames(
                      !wrapOptionText && "truncate",
                      isSelected(item) && "font-semibold",
                      "block",
                    )}
                  >
                    {item.name}
                  </span>
                  {isSelected(item) && (
                    <span
                      className={classNames(
                        "absolute inset-y-0 right-0 flex items-center pr-4",
                        active ? "text-white" : "text-indigo-600",
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
});

export default ComboBox;
