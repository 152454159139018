/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Attribute,
  AttributeType,
  DbRobustEntity,
  Entity,
  EntityType,
  EntityWithAttributes,
  isLegacyPersonProfile,
  isLinkedinCompanyProfile,
  isLinkedinPersonProfile,
} from "@/types";
import { Attributes as DbAttributes, Entity as DbEntity, Prisma } from "@prisma/client";

export const transforms = {
  entity: (entity: DbEntity): Entity => {
    return {
      ...entity,
      type: entity.type as EntityType,
    };
  },
  entityWithAttributes: (entity: DbRobustEntity): EntityWithAttributes => {
    const withValidAttributes = entity.attributes.filter((a) => !a.deletedAt);

    return {
      ...entity,
      type: entity.type as EntityType,
      attributes: transforms.dbAttributesToAttributes(withValidAttributes),
    };
  },

  dbAttributesToAttributes: (attributes: DbAttributes[]): Attribute[] => {
    return attributes.map((a) => {
      for (const type in attributeAssertionRecord) {
        if (attributeAssertionRecord[type as AttributeType](a.values)) {
          return {
            id: a.id,
            value: a.values,
            source: a.type,
            attributeType: type as AttributeType,
            updatedAt: a.updatedAt,
          } as Attribute;
        }
      }
      throw new Error(`No matching attribute type found for attribute with id ${a.id}`);
    });
  },
};

const attributeAssertionRecord: Record<AttributeType, (a: Prisma.JsonValue) => boolean> = {
  [AttributeType.LinkedinProfile]: isLinkedinPersonProfile,
  [AttributeType.LinkedinCompanyProfile]: isLinkedinCompanyProfile,
  [AttributeType.LegacyPersonProfile]: isLegacyPersonProfile,
  [AttributeType.Unknown]: (a) => true,
};
