import { loggerWithPrefix } from "@/lib/logger";
import { CaptureOptions, CaptureResult, PostHog, Properties } from "posthog-js";

const logger = loggerWithPrefix("[EventTracker]");

/**
 * This wraps the Posthog JavaScript SDK.
 *
 * This API is different from Posthog's Node SDK, so this is why the eventTracker
 * lives in the client module.
 */
class EventTracker {
  posthog?: PostHog;

  capture(
    event_name: string,
    properties?: Properties | null,
    options?: CaptureOptions,
  ): CaptureResult | undefined {
    if (!this.posthog) {
      logger.warn("EventTracker.capture called but posthog is not initialized", {
        event_name,
        properties,
        options,
      });
      return;
    }
    return this.posthog.capture(event_name, properties, options);
  }
}

const eventTracker = new EventTracker();

export default eventTracker;
