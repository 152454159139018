import { AIInvocationEvent } from "@/types/ai.typebox";
import { Static } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";
import { JSONSchema, JSONSchemaObject } from "openai/lib/jsonschema";

export type OpenAIModel = "4" | "4om" | "4-turbo" | "3.5";

export const ChatRole = {
  User: "user",
  Tool: "tool",
  Assistant: "assistant",
  System: "system",
  Function: "function",
  Error: "error",
} as const;
export type ChatRole = (typeof ChatRole)[keyof typeof ChatRole];

export type FunctionCall = { name: string; arguments: string };

export type ToolCall = { id: string; type: "function"; function: FunctionCall };

export type OpenAIChatMessage = {
  content: string | null;
  role: "user" | "assistant" | "system" | "function" | "tool";
  function_call?: FunctionCall;
  tool_calls?: { type: "function"; function: FunctionCall }[];
  tool_call_id?: string;
  refusal?: string;
  /**
   * The name of the author of this message. `name` is required if role is
   * `function`, and it should be the name of the function whose response is in the
   * `content`. May contain a-z, A-Z, 0-9, and underscores, with a maximum length of
   * 64 characters.
   */
  name?: string;
};

export type AIFunction = {
  name: string;
  description: string;

  // the top-level return is always an object.
  parameters: JSONSchemaObject;
};

export type StrictAIFunction = Omit<AIFunction, "parameters"> & {
  // NOTE: if you set this, you must also handle refusals
  strict: true;
  parameters: {
    type: "object";
    properties?: Record<string, JSONSchema>;
    additionalProperties: { type: string } | false;
    required?: string[];
    title?: string;
  };
};

export type MessageAttachments = {
  function_call?: FunctionCall;
  error?: string;
};

export type UIMessage = {
  id?: string;
  userId?: string;
  content?: string | null;
  role: string;
  function_call?: FunctionCall;
  attachments?: MessageAttachments;
  createdAt?: Date;
};

export type ChatRequestPayload = {
  entityId: string;
  message: string;
  conversationId: string;
  history: OpenAIChatMessage[];
  model?: OpenAIModel;
};

export type AIInvocationMetadata = {
  entityId?: string;
} & Record<string, string>;

export type AIInvocationEvent = Static<typeof AIInvocationEvent>;

export function isAIInvocationEvent(u: unknown): u is AIInvocationEvent {
  return Value.Check(AIInvocationEvent, u);
}
