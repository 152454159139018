import { Level, LevelConfig, LogConfig, logger, toLevelConfig } from "@/lib/logger";
import { assertUnreachable } from "@/lib/utils";
import Rollbar, { Locals, LogArgument } from "rollbar";

const baseConfig = {
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === "production", // Disable Rollbar in development
  environment: process.env.NODE_ENV || "development",
  logLevel: "error" as Rollbar.Level,
  reportLevel: "error" as Rollbar.Level,
};

export const clientConfig = {
  ...baseConfig,
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  client: {
    javascript: {
      source_map_enabled: true,
      code_version: process.env.GIT_HASH,
      // Optionally have Rollbar guess which frames the error was
      // thrown from when the browser does not provide line
      // and column numbers.
      guess_uncaught_frames: true,
    },
  },
} as const;

/**
 * Get arguments in the format that rollbar likes.
 *
 * Note: Order does not matter, except that message and err arguments must come first if present.
 * The first string will be interpreted as message, and the first Error type argument will be interpreted as err.
 * At least one of message or err must be present.
 *
 * @param message - String: The message to send to Rollbar.
 * @param err - Exception: The exception object to send.
 * @param custom - Object: The custom payload data to send to Rollbar.
 * @param callback - Function: The function to call once the message has been sent to Rollbar.
 *
 * @see https://docs.rollbar.com/docs/rollbarjs-configuration-reference#rollbarlog
 */
export function normalizeArgs({ prefix }: LevelConfig, args: unknown[]): LogArgument[] {
  const strings: string[] = [];
  let firstError: Error | undefined;
  let customData: Record<string, unknown> | undefined;
  let firstFunction: (() => void) | undefined;
  const otherArgs: unknown[] = [];

  for (const arg of args) {
    if (typeof arg === "string" && !firstError && !customData && !firstFunction) {
      strings.push(arg);
    } else if (arg instanceof Error && !firstError) {
      firstError = arg;
    } else if (typeof arg === "object" && arg !== null && !(arg instanceof Error) && !customData) {
      customData = { ...arg };
    } else if (typeof arg === "function" && !firstFunction) {
      firstFunction = arg as () => void;
    } else {
      otherArgs.push(arg);
    }
  }

  if (!firstError && typeof customData?.error === "object") {
    firstError = customData.error as Error;
    delete customData.error;
  }

  if (otherArgs.length > 0) {
    customData = {
      ...customData,
      otherArgs,
    };
  }

  // Ensure either a message or error is provided.
  let firstString: string | undefined;
  if (strings.length > 0) {
    firstString = strings.join(" ").trim();
  } else if (!firstError) {
    if (customData && Object.keys(customData).length > 0) {
      firstString = JSON.stringify(customData, getCircularReplacer());
    } else {
      firstString = "No message or error provided";
    }
  }

  if (prefix?.length > 0) {
    customData = { ...customData, prefix: prefix.join(" ") };
  }

  return [firstString, firstError, customData, firstFunction].filter(Boolean) as LogArgument[];
}

// from https://stackoverflow.com/questions/11616630/how-can-i-print-a-circular-structure-in-a-json-like-format
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: unknown) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      if (typeof HTMLElement !== "undefined" && value instanceof HTMLElement) {
        return "<HTML element>";
      }
      seen.add(value);
    }
    return value;
  };
};

class RollbarLogListener {
  private readonly rollbar: Rollbar;
  constructor(rollbar: Rollbar) {
    this.rollbar = rollbar;
  }
  log(config: LogConfig, ...args: unknown[]) {
    const levelConfig = toLevelConfig(config);
    switch (levelConfig.level) {
      case Level.INFO:
        this.rollbar.info(...normalizeArgs(levelConfig, args));
        break;
      case Level.WARN:
        this.rollbar.warn(...normalizeArgs(levelConfig, args));
        break;
      case Level.ERROR:
        this.rollbar.error(...normalizeArgs(levelConfig, args));
        break;
      case Level.OFF:
      case Level.TRACE:
      case Level.DEBUG:
        break;
      default:
        assertUnreachable(levelConfig.level);
    }
  }
}

function registerLogListener(rollbar: Rollbar): boolean {
  const existing = logger.allLogListeners.some((l) => l instanceof RollbarLogListener);
  if (existing) return false;
  const listener = new RollbarLogListener(rollbar);
  logger.allLogListeners.push(listener.log.bind(listener));
  return true;
}

let serverInstance: Rollbar | undefined;
if (process.env.ROLLBAR_SERVER_TOKEN) {
  serverInstance = new Rollbar({
    ...baseConfig,
    accessToken: process.env.ROLLBAR_SERVER_TOKEN,
    itemsPerMinute: 50000,
    locals: Locals,
  });
  registerLogListener(serverInstance);
}
export { registerLogListener, serverInstance };
