import Ably from "ably";
import { atom, computed } from "nanostores";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { ReactNode } from "react";

import API from "@/client/api";
import eventTracker from "@/client/eventTracker";
import { extractExtensionCookie } from "@/client/extensionCookie";
import errorTracker from "@/lib/errorTracker";
import { logger as globalLogger, Level, loggerWithPrefix } from "@/lib/logger";
import { prettyError } from "@/lib/utils";
import { exportStores } from "@/stores";
import {
  ChromeCookieUpdate,
  CookieFragments,
  CookieList,
  CookieStatus,
  ExtractedEntity,
  ServerProps,
  UserMeta,
  UserWithMeta,
  WorkspaceData,
} from "@/types";
import { EntityVisitCount, UserProfile } from "@prisma/client";
import moment from "moment";

const logger = loggerWithPrefix("[uiStore]");

export interface InputModalField {
  placeholder?: string;
  currentValue?: string;
  multiline?: boolean;
}

const VERSION_CHECK_INTERVAL = 1000 * 60 * 5; // 5 minutes
const GITHASH_CHANGED_REFRESH_HOURS = 24;

const checkIsDev = (user: UserWithMeta | null) => {
  if (!user) return false;
  if (user.email?.endsWith("@distill.fyi") || user.meta?.dev) {
    return true;
  }
  return false;
};

class UIStore {
  realtime: Ably.Realtime | undefined;

  sidebarVisible = atom<boolean>(false);

  user = atom<UserWithMeta | null>(null);

  entityVisitCount = atom<EntityVisitCount | null>(null);

  isDev = computed(this.user, (user) => checkIsDev(user));

  router: ReturnType<typeof useRouter> | undefined;

  loadedAt = atom<number | null>(null);
  loadedWithGitHash = atom<string | null>(null);
  loadedWithVersion = atom<number | null>(null);

  showConfirmModal = atom<
    | {
        type: "info" | "warning" | "danger";
        title: string;
        subtitle?: string;
        body?: ReactNode;
        onClick?: () => void | Promise<void>;
      }
    | undefined
  >();

  showInputModal = atom<
    | {
        type: "add" | "edit" | "info" | "warning" | "danger";
        title: string;
        subtitle?: string;
        instructions?: React.ReactElement;
        fields: InputModalField[];
        onSubmit: (values: string[]) => void | Promise<void>;
      }
    | undefined
  >();

  showInvitesModal = atom<boolean>(false);

  liCookieStatus = atom<CookieStatus | "no-extension" | "unknown" | "logged-out">("unknown");

  userProfile = atom<UserProfile | null>(null);

  autofocusSearchBar = atom<boolean>(false);

  // even though we can use media classes, this prevents rendering of unnecessary components
  smallScreen = atom<boolean>(false);

  recentConversations = atom<{ id: string; type: string; context: string }[]>([]);

  currentHash = atom<string | null>(null);

  onboarding = atom<boolean>(false);

  init = (props: ServerProps) => {
    if (typeof window === "undefined") {
      return;
    }
    if (!this.loadedAt.get()) {
      this.loadedAt.set(Date.now());
      void this.checkVersionAndMaybeRefresh();
    }
    this.smallScreen.set(document.body.clientWidth < 800);

    if (isWorkspaceData(props) && props.cookieFragments) {
      if (!this.user.get() && props.user) this.user.set(props.user);
      this.checkCookieFragments(props.cookieFragments);
      this.entityVisitCount.set(props.entityVisitCount || null);
    }

    const hashChange = () => {
      const hash = window.location.hash;
      const id = hash.startsWith("#") ? hash.substring(1) : hash;
      if (id) this.currentHash.set(id);
      else if (this.currentHash.get()) this.currentHash.set(null);
    };
    window.addEventListener("hashchange", hashChange);
    hashChange();
  };

  checkVersionAndMaybeRefresh = async () => {
    const versionData = await API.getVersion().catch((e) => {
      // In case getting the version failed, we need to redo the check.
      setTimeout(this.checkVersionAndMaybeRefresh, VERSION_CHECK_INTERVAL);
    });
    if (!versionData) return;
    if (!this.loadedWithVersion.get()) {
      this.loadedWithVersion.set(versionData.frontendRefreshVersion);
    }
    if (!this.loadedWithGitHash.get()) {
      this.loadedWithGitHash.set(versionData.gitHash);
    }
    if (
      this.loadedWithVersion.get() !== versionData.frontendRefreshVersion ||
      (this.loadedWithGitHash.get() !== versionData.gitHash &&
        moment().diff(this.loadedAt.get(), "hours") > GITHASH_CHANGED_REFRESH_HOURS)
    ) {
      eventTracker.capture("version-changed", {
        oldVersion: this.loadedWithVersion.get(),
        newVersion: versionData.frontendRefreshVersion,
        oldHash: this.loadedWithGitHash.get(),
        newHash: versionData.gitHash,
      });
      if (document.visibilityState === "hidden") {
        eventTracker.capture("version-changed-refresh-now");
        location.reload();
      } else {
        eventTracker.capture("version-changed-refresh-later");
        document.addEventListener(
          "visibilitychange",
          () => {
            if (document.visibilityState === "hidden") {
              eventTracker.capture("version-changed-refresh-later-happened");
              location.reload();
            }
          },
          { once: true },
        );
      }
      return;
    }
    setTimeout(this.checkVersionAndMaybeRefresh, VERSION_CHECK_INTERVAL);
  };

  hasSet = false;
  setUser = (user: UserWithMeta) => {
    this.user.set(user);
    const isDev = this.isDev.get(); // computed property
    if (isDev && globalLogger.level === Level.WARN) {
      globalLogger.setLevel(Level.INFO);
    }
    void exportStores();

    if (!this.hasSet) {
      eventTracker.posthog?.identify(user.id, {
        email: user.email,
        name: user.name,
        userId: user.id,
      });
      errorTracker.setUser(user);
      this.hasSet = true;
    }

    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!user.timezone || user.timezone !== localTimezone) {
      this.updateUser({
        timezone: localTimezone,
      }).catch((e: unknown) => {
        // Failing to set the timezone is not relevant to the user so we do
        // not show it in the UI.
        errorTracker.sendError(e, { msg: "Failed to set timezone" });
      });
    }

    void this.startUserListener(user);
  };

  showDevTools = () => this.isDev.get();

  getConnectedRealtime = async (): Promise<Ably.Realtime> => {
    if (!this.realtime) {
      this.realtime = new Ably.Realtime(
        !uiStore.user.get() ? {} : { authUrl: location.origin + "/api/ably/token" },
      );
    }
    if (this.realtime.connection.state === "connected") {
      return this.realtime;
    }

    return new Promise<Ably.Realtime>((res) => {
      const realtime = this.realtime;
      if (!realtime) return;
      realtime.connection.once("connected", () => {
        res(realtime);
      });
    });
  };

  toggleSidebar = () => {
    this.sidebarVisible.set(!this.sidebarVisible.get());
  };

  isSmallScreen = () => typeof document != "undefined" && document.body.clientWidth < 640;

  updateUser = async (updates: { name?: string; image?: string | null; timezone?: string }) => {
    const user = this.user.get();
    if (!user) return;
    const updated = await API.user.update(updates);
    this.user.set({ ...user, ...updated });
  };

  updateUserMeta = async (updates: Partial<UserMeta>): Promise<UserMeta | undefined> => {
    const user = this.user.get();
    if (!user) return;
    const updated = await API.updateUserMeta({ meta: updates });
    this.user.set({ ...user, meta: updated });
    return updated;
  };

  routeTo = (path: string) => {
    if (this.router) this.router.push(path);
    else location.href = path;
  };

  reloadPage = () => {
    if (this.router) this.router.refresh();
    else location.reload();
  };

  loadUserProfile = async () => {
    if (this.userProfile.get()) return this.userProfile.get();
    const profile = (await API.userProfile.get()) || ({} as UserProfile);
    this.userProfile.set(profile);
    return profile;
  };

  focusSearchBar = () => {
    this.autofocusSearchBar.set(true);
    setTimeout(() => {
      this.autofocusSearchBar.set(false);
    }, 500);
  };

  logOut = () => {
    eventTracker.posthog?.reset();
    localStorage.removeItem("$set");
    void signOut({ callbackUrl: "/" });
  };

  checkedCookies = false;
  checkCookieFragments = (fragments: CookieFragments, recheck = false) => {
    if (this.checkedCookies && !recheck) return;
    this.checkedCookies = true;
    logger.info("checkCookieFragments", fragments, uiStore.liCookieStatus.get());

    const domains = Object.keys(fragments);
    if (!domains.some((d) => d.includes("linkedin.com"))) domains.push("www.linkedin.com");
    domains.forEach(async (domain) => {
      const cookieTails = fragments[domain] || [];
      const result = await this.checkCookies(cookieTails, domain);
      if (domain.includes("linkedin.com")) this.liCookieStatus.set(result);
    });
  };

  isImpersonating = () => {
    const origUser = localStorage.getItem("orig_user");
    if (!origUser) return false;
    const parsed = JSON.parse(origUser) as UserWithMeta;
    return parsed.id && this.user.get()?.id != parsed.id;
  };

  checkCookies = async (
    cookieFragments: string[],
    hostname: string,
  ): Promise<CookieStatus | "no-extension" | "logged-out"> => {
    if (this.isImpersonating()) {
      // when we are impersonating other users, skip cookie check
      return cookieFragments.length > 0 ? "valid" : "missing";
    }

    const user = this.user.get();
    // we don't know how to request non-linkedin cookies yet
    logger.info("checking for cookies", user, hostname);
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    if (!user || !hostname.includes("linkedin.com") || typeof chrome == "undefined")
      return cookieFragments.length > 0 ? "valid" : "missing";
    // give the chrome extension some time to complete
    const foundChromeCookie = await extractExtensionCookie(user.id);
    logger.info("foundChromeCookie", foundChromeCookie);

    const foundValue = foundChromeCookie?.cookie;
    const defaultSuccessValue = cookieFragments.length > 0 ? "valid" : undefined;

    if (foundValue) {
      if (cookieFragments.find((f) => foundValue.endsWith(f))) {
        eventTracker.capture("chrome-cookie-matched");
        logger.info("[li] chrome cookie matched.");
        return "valid";
      }
      const trySaveCookie = localStorage.getItem("try-save-cookie") == foundValue;
      eventTracker.capture("chrome-cookie-no-match", { trySaveCookie });
      logger.info("[li] chrome cookie no match", foundChromeCookie.cookie, trySaveCookie);
      if (trySaveCookie) {
        // we already tried to save this cookie, so we know it's invalid
        return defaultSuccessValue || "logged-out";
      }

      // we got a new cookie, save this bad boy
      try {
        const li_rm = foundChromeCookie.li_rm;
        const { updatedAuthCookie } = await this.updateCookies({ li_at: foundValue, li_rm });
        if (!updatedAuthCookie) return "valid";
        logger.info("auth cookie replaced with updated value", updatedAuthCookie);
        void this.updateBrowserCookies([updatedAuthCookie]);
      } catch (e) {
        logger.warn("saving cookie failed", e);
        eventTracker.capture("chrome-cookie-set-error", { error: prettyError(e) });
      }
      localStorage.setItem("try-save-cookie", foundValue);
      return defaultSuccessValue || "logged-out";
    } else if (foundChromeCookie) {
      eventTracker.capture("chrome-cookie-empty", { cookies: cookieFragments.length });
      return defaultSuccessValue || "logged-out";
    } else {
      eventTracker.capture("no-chrome-extension", { cookies: cookieFragments.length });
      return defaultSuccessValue || "no-extension";
    }
  };

  updateCookies = async ({
    li_at,
    li_rm,
  }: {
    li_at: string;
    li_rm?: string;
  }): Promise<{
    entity?: ExtractedEntity;
    updatedAuthCookie?: ChromeCookieUpdate;
  }> => {
    const cookies: CookieList = [
      {
        domain: "www.linkedin.com",
        name: "li_at",
        value: li_at,
        meta: { ua: navigator.userAgent },
      },
    ];
    if (li_rm) {
      cookies.push({ domain: "www.linkedin.com", name: "li_rm", value: li_rm });
    }
    const { meta, entity, updatedAuthCookie } = await API.updateCookies({ cookies });
    const user = this.user.get();
    if (user && meta) this.user.set({ ...user, meta });
    return { updatedAuthCookie, entity };
  };

  ingestUtmSource = (
    type: string,
    details: Record<string, string | number | boolean | null | undefined>,
  ) => {
    const utmSource = this.getAndDeleteUrlParam("utm_source");
    if (utmSource) {
      eventTracker.capture(`${type}-${utmSource}`, details);
    }
  };

  getAndDeleteUrlParam = (urlParam: string) => {
    if (window) {
      const urlParams = new URLSearchParams(window.location.search);
      const value = urlParams.get(urlParam);
      if (value) {
        urlParams.delete(urlParam);
        const newUrl =
          urlParams.toString().length ?
            `${window.location.pathname}?${urlParams.toString()}`
          : window.location.pathname;
        window.history.replaceState({}, "", newUrl);
      }
      return value;
    }
    return undefined;
  };

  startUserListener = async (user: UserWithMeta) => {
    const realtime = await this.getConnectedRealtime();
    const channel = realtime.channels.get(`user:${user.id}`);
    if (channel) {
      channel.subscribe("cookie-update", (msg) => {
        const { cookies } = msg.data as {
          cookies: ChromeCookieUpdate[];
        };
        logger.info(
          "received cookie updates",
          cookies.map((c) => c.name),
        );
        void this.updateBrowserCookies(cookies);
      });
    }
  };

  updateBrowserCookies = async (updates: ChromeCookieUpdate[]): Promise<void> => {
    await Promise.allSettled(
      CHROME_EXTENSION_IDS.map(async (extensionId) => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-deprecated
          await chrome.runtime.sendMessage(extensionId, { cookieSet: updates });
        } catch (e) {
          // Extension doesn't exist, ignore
        }
      }),
    );
  };
}

export const uiStore = new UIStore();

export const PROD_CHROME_EXTENSION = "kidifhledoijjifmngjkaclhdoffdneg";

const DEV_CHROME_EXTENSION_IDS = [
  // add your development extension id
  "fhomjjmbamoccgioeocggddaefiejgnl",
  "fcabjmklleinfceeobnlgdeakoepgfeo",
  "gmpjoopcdmklmgbipkhgkaemlgeolimg",
  "clffdpjifkbfhcnahbgponoeljliklkh",
  "jojhghkacgcgohcgmemekaafjpfjjkkp",
  "lefhlbmndglddnheckgmohgkkifdlppi",
  "ahcaclkgbnklbhimgbpmllfphadbbgpd",
  "gbkfjhilcgphfffclnabfoiaoallmiko",
  "laegdoeciejjdkleiojkaccagaifhiga",
  "pjnkgaijmgkalonbhgnmbfijdhheifpb",
];

export const CHROME_EXTENSION_IDS =
  process.env.NODE_ENV == "development" ?
    [...DEV_CHROME_EXTENSION_IDS, PROD_CHROME_EXTENSION]
  : [PROD_CHROME_EXTENSION];

function isWorkspaceData(props: ServerProps): props is WorkspaceData {
  return "cookieFragments" in props;
}
