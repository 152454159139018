import Link, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, HTMLAttributes, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

export default function ConditionalLink({
  children,
  href,
  className,
  ...rest
}: PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement> & Omit<LinkProps, "href">>) {
  if (!href) {
    return (
      <div className={twMerge("inline", className)} {...(rest as HTMLAttributes<HTMLDivElement>)}>
        {children}
      </div>
    );
  }

  return (
    <Link href={href} className={className} {...rest}>
      {children}
    </Link>
  );
}
