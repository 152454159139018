import "@/lib/error";
import { Level, logger } from "@/lib/logger";
import { HyperDX } from "@/lib/trackers/hyperdx";
import { serverInstance as serverRollbar } from "@/lib/trackers/rollbar";
import { Attributes } from "@opentelemetry/api";

import Rollbar, { LogArgument } from "rollbar";

export class ErrorTracker {
  rollbar?: Rollbar;
  hyperDX?: HyperDX;

  constructor(parent?: ErrorTracker) {
    if (parent) {
      this.rollbar = parent.rollbar;
      this.hyperDX = parent.hyperDX;
    }
  }

  testConfig() {
    logger.info("hyperdx", process.env.HYPERDX_API_KEY, this.hyperDX);
  }

  /** this only works on the frontend */
  addBreadcrumb(breadcrumb: {
    category: string;
    action: string;
    message?: string;
    metadata?: Attributes;
  }) {
    logger.debug(breadcrumb);
    this.hyperDX?.addAction(breadcrumb.action, {
      category: breadcrumb.category,
      message: breadcrumb.message,
      ...breadcrumb.metadata,
    });
  }

  sendError(error: unknown, metadata?: Attributes) {
    // Don't use logger.error because that will call the rollbar log listener which is
    // registered in logger.allLogListeners. We want to use this.rollbar below since it could
    // be a different instance than the global rollbar.
    logger.logListeners.forEach((l) => {
      l(Level.ERROR, "[ET]", error, metadata);
    });
    if (process.env.NODE_ENV != "production") return;

    this.hyperDX?.recordException(error, metadata);
    this.rollbar?.error(error as LogArgument, metadata);
  }

  /** this only works on the frontend */
  setUser(user: { id: string; email?: string | null; name?: string | null }) {
    this.hyperDX?.setGlobalAttributes({
      userId: user.id,
      userEmail: user.email || "",
      userName: user.name || "",
    });
    this.rollbar?.global({
      payload: {
        person: { id: user.id, email: user.email ?? undefined, name: user.name ?? undefined },
      },
    });
  }
}

function createErrorTracker(): ErrorTracker {
  const result = new ErrorTracker();
  if (process.env.CI) return result;
  if (typeof window === "undefined") {
    result.rollbar = serverRollbar;
    // This file must be dynamically imported because HyperDXNode is not available
    // in the browser environment.
    void import("@/lib/trackers/hyperdxNode").then(async (hyperDXNode) => {
      result.hyperDX = await hyperDXNode.createHyperDXNode();
    });
  }
  return result;
}

const errorTracker = createErrorTracker();
export default errorTracker;
