import Link, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, ButtonHTMLAttributes, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

export enum ButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
  Clear = "clear",
  Small = "small",
  SmallSecondary = "smallSecondary",
}

const BASE_BUTTON_CLASS =
  "disabled:cursor-auto py-2 px-4 rounded-md cursor-pointer flex items-center";

const variantToClass = {
  [ButtonVariant.Primary]: "text-white bg-brand-600 disabled:bg-gray-400 hover:bg-brand-800",
  [ButtonVariant.Secondary]: "bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300",
  [ButtonVariant.Clear]: "inline-block p-2 rounded-md hover:bg-brand-100",
  [ButtonVariant.Small]:
    "text-white bg-brand-600 disabled:bg-gray-400 hover:bg-brand-800 py-1 px-2 text-sm rounded-md",
  [ButtonVariant.SmallSecondary]:
    "text-gray-800 bg-gray-200 disabled:bg-gray-400 hover:bg-gray-300 py-1 px-2 text-sm rounded-md",
};

export type ButtonProps = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: ButtonVariant;
  }
>;

function getClassNames(variant?: ButtonVariant) {
  return variantToClass[variant ?? ButtonVariant.Primary];
}

export default function Button({ children, variant, ...rest }: ButtonProps) {
  const className = twMerge(BASE_BUTTON_CLASS, getClassNames(variant), rest.className);

  return (
    <button {...rest} className={className} data-tooltip-id="tooltip">
      {children}
    </button>
  );
}

export function SmallButton({ children, ...rest }: ButtonProps) {
  return (
    <Button variant={ButtonVariant.Small} {...rest}>
      {children}
    </Button>
  );
}

export function SmallSecondaryButton({ children, ...rest }: ButtonProps) {
  return (
    <Button variant={ButtonVariant.SmallSecondary} {...rest}>
      {children}
    </Button>
  );
}

export function ClearButton({ children, ...rest }: ButtonProps) {
  return (
    <Button variant={ButtonVariant.Clear} {...rest}>
      {children}
    </Button>
  );
}

export function SecondaryButton({ children, ...rest }: ButtonProps) {
  return (
    <Button variant={ButtonVariant.Secondary} {...rest}>
      {children}
    </Button>
  );
}

export function ButtonLink({
  children,
  variant,
  ...rest
}: PropsWithChildren<
  AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps & { variant?: ButtonVariant }
>) {
  const className = twMerge(BASE_BUTTON_CLASS, getClassNames(variant), rest.className);

  return (
    <Link {...rest} className={className} data-tooltip-id="tooltip">
      {children}
    </Link>
  );
}
