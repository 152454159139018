import API from "@/client/api";
import EntityContent from "@/components/entities/EntityContent";
import { useSidebar } from "@/components/providers/SidebarProvider";
import Loader from "@/components/ui/Loader";
import { transforms } from "@/entities/transforms";
import { useCachedAPI } from "@/hooks/swr";
import { prettyError } from "@/lib/miscUtils";
import { EntityLayoutContext, getSidebarEntityStore } from "@/stores/entityStore";
import { EntityUIType } from "@/types";
import { useStore } from "@nanostores/react";
import { useEffect } from "react";
import { toast } from "react-toastify";

export type EntitySidebarProps =
  | {
      entityId: string;
    }
  | {
      entityUrl: string;
    };

const isEntityId = (props: EntitySidebarProps): props is { entityId: string } =>
  "entityId" in props;
const isEntityUrl = (props: EntitySidebarProps): props is { entityUrl: string } =>
  "entityUrl" in props;

export default function EntitySidebar(props: EntitySidebarProps) {
  const entityStore = getSidebarEntityStore();
  const entity = useStore(entityStore.entity);
  const sidebar = useSidebar();

  const {
    data: entityData,
    error,
    isLoading,
  } = useCachedAPI(API.resolveWithSnapshot, "resolveWithSnapshot", () => {
    return (
      isEntityId(props) ? ([{ entityId: props.entityId }] as [{ entityId: string }])
      : isEntityUrl(props) ?
        ([{ query: props.entityUrl, fetchMissing: true }] as [
          { query: string; fetchMissing: boolean },
        ])
      : false
    );
  });

  useEffect(() => {
    if (entityData?.entity) {
      void entityStore.load(transforms.entity(entityData.entity), entityData.snapshot, {
        attributes: entityData.entity.attributes,
      });
    } else if (error) {
      toast.error(prettyError(error));
      sidebar.closeSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, entityData, error]);

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-full p-4">
        <Loader />
      </div>
    );

  return (
    <EntityLayoutContext.Provider value={EntityUIType.Sidebar}>
      <EntityContent />
    </EntityLayoutContext.Provider>
  );
}
