import AuthoredMediaRow from "@/components/media/AuthoredMediaRow";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import ExpandoList from "@/components/ui/ExpandoList";
import { useEntityStore } from "@/stores/entityStore";
import { ProfilePageSection } from "@/types";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";

export default withErrorBoundary(function AuthoredMediaV2() {
  const entityStore = useEntityStore();
  const media = useStore(entityStore.authoredMedia);
  const crawlResults = useStore(entityStore.crawlResults);
  const canEdit = useStore(entityStore.canEdit);

  const addMedia = () => {
    entityStore.addAuthoredMediaModal();
  };

  if (!media.length) return null;

  return (
    <ProfileBubble
      title="Authored Media"
      section={ProfilePageSection.AuthoredMedia}
      className="flex flex-col group"
      titleButtons={
        <>
          {canEdit && (
            <DocumentPlusIcon
              className="-mt-1 w-4 h-4 text-gray-400 hover:text-brand-600 hidden group-hover:block cursor-pointer"
              onClick={addMedia}
            />
          )}
        </>
      }
    >
      {media.length == 0 && <div className="text-gray-500">No authored media</div>}
      <ExpandoList
        items={media}
        limit={3}
        seeMoreClassName="pt-4 text-center"
        className="list-inside text-gray-700 gap-4"
        divideExpander
        renderItem={(m, idx) => (
          <AuthoredMediaRow
            media={m}
            idx={idx}
            key={m.id}
            crawlResult={crawlResults[m.url || ""]}
          />
        )}
      />
    </ProfileBubble>
  );
});
