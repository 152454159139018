import { GenericProfile, References } from "@/types";
import { AnchorHTMLAttributes } from "react";

// To be used with SourceTooltip. <SourceTooltip /> needs to be placed somewhere
// in the component tree for this to work.

export default function SourceTooltipLink({
  title,
  ...rest
}: AnchorHTMLAttributes<HTMLAnchorElement> & { title: string | undefined }) {
  return (
    <span
      className="text-xs border-0 inline-flex justify-center items-center min-w-6 h-auto aspect-square text-blue-700 rounded-full p-1 bg-gray-100 ml-1 font-semibold"
      data-tooltip-id="source-tooltip"
      data-tooltip-content={title}
    >
      <a {...rest}></a>
    </span>
  );
}

function getIndex(snapshot: GenericProfile, id: string): number {
  const refs: References[] = snapshot.references || [];
  return refs.findIndex((r: References) => r.id == id || r.url == id) + 1;
}

export function getReferenceDataToShowFromCitationId(snapshot: GenericProfile, citationId: string) {
  const mref = snapshot?.references?.find((ref) => ref.id === citationId || ref.url === citationId);
  if (!mref) return undefined;

  const idx = getIndex(snapshot, citationId);
  if (idx < 1) return undefined;

  const url = mref?.url.replace(/'/g, "\\'");
  return {
    idx,
    citationId,
    url,
    citationUrl: `#source${citationId}`,
  };
}
export function getReferenceDataToShowFromURL(snapshot: GenericProfile, url?: string) {
  if (!url) return undefined;
  const mref = snapshot?.references?.find((ref) => ref.url === url);

  // TODO: We should still show the reference somehow even if it's not included in the list of links.
  if (!mref) return undefined;

  const idx = getIndex(snapshot, mref.id);
  if (idx < 1) return undefined;
  return {
    idx,
    citationId: mref.id,
    url: url.replace(/'/g, "\\'"),
    citationUrl: `#source${mref.id}`,
  };
}
