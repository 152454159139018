import { LinkedinCompanyProfile, LinkedinProfile } from "@/types";
// add all possible structured scrape shapes here
export enum StructuredScrapeType {
  Github = "github",
  CrunchbasePerson = "crunchbase-person",
  CrunchbaseCompany = "crunchbase-company",
  CrunchbaseCompanyEmployee = "crunchbase-company-employee",
  CrunchbaseCompanyAdvisor = "crunchbase-company-advisor",
  CrunchbaseCompanyFunding = "crunchbase-company-funding",
  CrunchbaseCompanyFundingRound = "crunchbase-company-funding-round",
  CrunchbasePersonInvestments = "crunchbase-person-investments",
  CrunchbasePersonPartnerInvestments = "crunchbase-person-partner-investments",
  LinkedinPerson = "linkedin-person",
  LinkedinCompany = "linkedin-company",
  VCSheetCompany = "vcsheet-company",
  NFXFirm = "nfx-firm",
  NFXInvestor = "nfx-investor",
  PitchBookCompany = "pitchbook-company",
}

export interface StructuredScrapeTypeMap {
  [StructuredScrapeType.Github]: GithubStructuredData;
  [StructuredScrapeType.CrunchbasePerson]: CrunchbaseProfile;
  [StructuredScrapeType.CrunchbaseCompany]: CrunchbaseCompanyProfile;
  [StructuredScrapeType.CrunchbaseCompanyEmployee]: CrunchbaseCompanyEmployeeProfile[];
  [StructuredScrapeType.CrunchbaseCompanyAdvisor]: CrunchbaseCompanyAdvisorProfile[];
  [StructuredScrapeType.CrunchbaseCompanyFunding]: CrunchbaseCompanyFundingProfile;
  [StructuredScrapeType.CrunchbaseCompanyFundingRound]: CrunchbaseCompanyFundingRoundProfile;
  [StructuredScrapeType.CrunchbasePersonInvestments]: CrunchbasePersonInvestmentsProfile;
  [StructuredScrapeType.CrunchbasePersonPartnerInvestments]: CrunchbasePersonInvestmentsProfile;
  [StructuredScrapeType.LinkedinPerson]: LinkedinProfile;
  [StructuredScrapeType.LinkedinCompany]: LinkedinCompanyProfile;
  [StructuredScrapeType.VCSheetCompany]: VCSheetCompanyProfile;
  [StructuredScrapeType.NFXFirm]: NFXFirmProfile;
  [StructuredScrapeType.NFXInvestor]: NFXInvestorProfile;
  [StructuredScrapeType.PitchBookCompany]: PitchBookCompanyProfile;
}

export type GithubStructuredData = {
  userDetails: GithubUserDetails | null;
  repoDetails: GithubRepoDetails[] | null;
  eventDetails: GithubEventDetails[] | null;
};

export interface GithubUserDetails {
  login: string;
  name?: string;
  company?: string;
  blog?: string;
  location?: string;
  email?: string | null;
  bio?: string;
  public_repos: number;
  followers: number;
  following: number;
  created_at: string;
  updated_at: string;
  readme?: string;
}

export interface GithubRepoDetails {
  name: string;
  updated_at: string;
  language?: string | null;
  fork: boolean;
  stargazers_count: number;
  lastUpdate: string;
  description?: string | null;
  html_url: string;
  created_at: string;
  open_issues_count: number;
}

export interface GithubEventDetails {
  type: string;
  created_at: string;
}

export interface CrunchbaseProfile {
  name?: string;
  num_partner_investments?: string;
  num_investments?: string;
  linkedin?: string;
  twitter?: string;
  facebook?: string;
  investments?: {
    date: string;
    company: string;
    fundingRound: string;
    amount: string;
  }[];
  partner_investments?: {
    date: string;
    company: string;
    isLead: string;
    fundingRound: string;
    amount: string;
  }[];
}

export interface CrunchbaseCompanyProfile {
  name: string;
  description?: string;
  numExits?: string;
  numInvestments?: string;
  numFundsRaised?: string;
  totalFundsRaisedAmt?: string;
  fundsRaised?: {
    [key: string]: string;
  }[];
  website?: string;
  founders?: string[];
  headquartersAddress?: string;
  investments?: {
    [key: string]: string;
  }[];

  // these are not extracted yet

  // numEmployeesMin?: number;
  // numEmployeesMax?: number;
  // phone?: string;
  // email?: string;
  // linkedin?: string;
  // twitter?: string;
  // facebook?: string;
  // news?: NewsArticle[];

  // acquisitions?: Acquisition[];
  // ipo?: IPOInfo;
  // fundingRounds?: FundingRound[];
  // subsidiaries?: string[];
  // stockSymbol?: string;
}

export interface CrunchbasePersonInvestmentsProfile {
  name: string;
  investments: {
    date: string;
    company: string;
    companyUrl: string;
    investor?: string;
    investorUrl?: string;
    leadInvestor: boolean;
    fundingRound: string;
    fundingRoundUrl: string;
    moneyRaised: string;
  }[];
}

export interface CrunchbaseCompanyFundingProfile {
  companyName: string;
  numberOfFundingRounds: number;
  totalFundingAmount: string;
  fundingRoundUrls: string[];
}

export interface CrunchbaseCompanyFundingRoundProfile {
  companyName: string;
  announcedDate: string;
  closedOnDate: string;
  fundingType: string;
  fundingStage: string;
  moneyRaised: string;
  preMoneyValuation: string;
  leadInvestors?: { investorName: string; url: string }[];
  investors?: { investorName?: string; investorUrl?: string; partners?: string }[]; // TODO(rhwang) array partners?
}

export interface CrunchbaseCompanyEmployeeProfile {
  name: string;
  title: string;
  url: string;
}
export interface CrunchbaseCompanyAdvisorProfile extends CrunchbaseCompanyEmployeeProfile {
  since?: string; // mm-yyyy
}

export interface VCSheetCompanyProfile {
  name: string;
  website?: string;
  description?: string;
  location?: string;
  checkSizeRanges?: { min?: number; max?: number }[];
  investmentRounds?: string[];
  leadRounds?: string[];
  sectors?: string[];
  geographies?: string[];
  unicornInvestments?: string[];
  topVerticals?: { percent: number; vertical: string }[];
}

export interface NFXFirmProfile {
  name: string;
  website?: string;
  description?: string;
  locations?: string[];
  coinvestors?: string[];
  team?: NFXFirmTeamMember[];
}
export interface NFXFirmTeamMember {
  name: string;
  url: string;
  title: string;
  checkSizeAverage?: number;
  checkSizeRange: { min?: number; max?: number };
  locations: string[];
  investmentCategories: string[];
}

export interface NFXInvestorProfile {
  name: string;
  website?: string;
  linkedin?: string;
  twitter?: string;
  angelList?: string;
  crunchbase?: string;
  title?: string;
}

export interface PitchBookCompanyProfile {
  name: string;
  description?: string;
  website?: string;
  headquarters?: string;
  founded?: string;
  employees?: string;
  revenue?: string;
  totalFunding?: string;
  fundingRounds?: {
    date: string;
    round: string;
    amount: string;
  }[];
  investors?: {
    name: string;
    url: string;
  }[];
  twitter?: string;
  linkedin?: string;
  facebook?: string;
  formerlyKnownAs?: string;
}
