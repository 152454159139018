import { usePathname } from "next/navigation";
import { twMerge } from "tailwind-merge";

import { Tab } from "@/types";

type Props = {
  tabs: Tab[];
  selected: number;
  className?: string;
  tabClassName?: string;
  fullWidth?: boolean;
  onSelectTab?: (index: number) => void;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export default function TabBar({
  tabs,
  selected,
  className,
  tabClassName,
  fullWidth,
  onSelectTab,
  leftContent,
  rightContent,
}: Props) {
  const path = usePathname();
  return (
    <nav
      className={twMerge("border-b border-gray-200 flex items-center -mb-px", className)}
      aria-label="Tabs"
    >
      {leftContent}
      {tabs.map((tab, i) => (
        <a
          key={tab.name}
          href={tab.id ? path + "?tab=" + tab.id : undefined}
          className={twMerge(
            i == selected ?
              "border-blue-500 text-blue-600"
            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "whitespace-nowrap border-b-2 p-2 text-sm font-medium cursor-pointer select-none",
            fullWidth ? "flex-1 text-center" : "",
            tabClassName,
            tab.hidden && "hidden",
          )}
          data-tooltip-id="tooltip"
          data-tooltip-content={tab.tooltip}
          onClick={(e) => {
            e.preventDefault();
            tab.onClick?.();
            onSelectTab?.(i);
          }}
        >
          {tab.name}
          {tab.count != undefined && ` (${tab.count})`}
        </a>
      ))}
      {!fullWidth && <div className="hidden sm:block flex-1" />}
      {rightContent}
    </nav>
  );
}
